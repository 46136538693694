<template>
  <BaseAccountLayout>
    <main class="fade-in">
      <div id="field-container">
        <h3>Forgot Password</h3>
        <div>
          <input type="text" placeholder="Email" className="form-control" v-model="email" />
          <button type="button" className="rightColumn btn-link" @click="sendEmail()">
            Send Email
          </button>
          <a to="/" className="a-link" @click="$router.go(-1)">Back to Home Page</a>
        </div>
      </div>
      <div id="logo-container">
        <img src="./../../assets/company-logo.png" />
      </div>
    </main>
  </BaseAccountLayout>
</template>

<script>
import BaseAccountLayout from "./../../Layouts/BaseAccountLayout.vue";
import { recoverPassword } from "../../Services/authService";
import utilities from "../../Services/utilities";

export default {
  components: { BaseAccountLayout },
  data() {
    return {
      email: "",
      phoneNumber: "",
    };
  },
  methods: {
    async sendEmail() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await recoverPassword(this.email);

          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!res) {
            utilities.showFailureAlert(this, "Failed to send the email. Please try again later.");
          }

          if (res && !res.success) {
            utilities.showFailureAlert(this, res.message, "Warning!");
          }

          if (res && res.success) {
            utilities.showSuccessAlert(this, "Email sent successfully");
            this.$router.push({ path: "/login" });
          }

        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
main {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#field-container {
  width: 20%;
  margin-left: 25%;
  margin-right: 5%;
  text-align: center;
}

#logo-container {
  margin-top: 50px;
}

input {
  height: 40px;
}

button {
  height: 40px;
  width: 150px;
}

h3 {
  font-size: 35px;
  margin-bottom: 20px;
}

a,
a:link,
a:visited,
a:active {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 16px;
}
</style>