<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 class="fade-in" v-if="!loading">File A Claim</h2>
        </div>
        <div class="content">
          <div class="fade-in" v-if="!loading">
            <div class="section-head">
            <h3>Accident/Injury Details</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field third">
                  <label
                    :class="form.claimType.error ? 'error-text' : ''"
                    ><span class="red">*</span>Claim Type</label
                  >
                  <select
                    v-model="form.claimType.value"
                    v-on:change="handleValueChange(form.claimType)"
                    :class="form.claimType.error ? 'error-element' : ''"
                  >
                    <option value=null disabled selected hidden>
                      Select Claim Type
                    </option>
                    <option
                      v-for="type in data.claimTypes"
                      :key="type.ID"
                      :value="type.ID"
                    >
                      {{ type.typeDescription }}
                    </option>
                  </select>
                </div>
              <div class="two-thirds removeMe">
                <div class="check-box">
                  <input
                    type="checkbox"
                    v-model="notifyPrimaryContact"
                    class="chkBox"
                  />
                  <label
                    ><strong
                      >Please check here to send confirmation email to the
                      Primary Claim Contact on this policy.</strong
                    ></label
                  >
                </div>
              </div>
              <div class="data-field third" />
            </div>
            <div class="row">
              <div class="data-field fourth">
                <label :class="form.dateOfInjury.error ? 'error-text' : ''"
                  ><span class="red">*</span>Date and Time of Injury</label
                >
                <input
                  type="datetime-local"
                  placeholder="Enter date of loss"
                  v-model="form.dateOfInjury.value"
                  v-on:change="handleValueChange(form.dateOfInjury)"
                  :class="form.dateOfInjury.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field fourth">
                <label :class="form.lastDateWorked.error ? 'error-text' : ''"
                  ><span class="red">*</span>Last Day Worked</label
                >
                <input
                  type="date"
                  placeholder=""
                  v-model="form.lastDateWorked.value"
                  v-on:change="handleValueChange(form.lastDateWorked)"
                  :class="form.lastDateWorked.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field fourth">
                <label
                  :class="form.dateInjuryNotified.error ? 'error-text' : ''"
                  ><span class="red">*</span>Date Employer notified</label
                >
                <input
                  type="date"
                  placeholder=""
                  v-model="form.dateInjuryNotified.value"
                  v-on:change="handleValueChange(form.dateInjuryNotified)"
                  :class="form.dateInjuryNotified.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field fourth">
                <label>Date Returned to Work (or Estimated)</label>
                <input type="date" placeholder="" v-model="form.dateWorkReturn.value" />
              </div>
            </div>
            <div class="row">
              <div class="radio-group half">
                <label
                  class="bold"
                  :class="form.isLossTimeOrInjury.error ? 'error-text' : ''"
                  ><span class="red">*</span>Was This a Lost Time or Other
                  Compensable Injury?</label
                >
                <div class="radio-buttons">
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="isLossTimeOrInjury"
                      v-model="form.isLossTimeOrInjury.value"
                      v-on:change="handleValueChange(form.isLossTimeOrInjury)"
                      :value="true"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="isLossTimeOrInjury"
                      v-model="form.isLossTimeOrInjury.value"
                      v-on:change="handleValueChange(form.isLossTimeOrInjury)"
                      :value="false"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
              <div class="radio-group fourth">
                <label
                  class="bold"
                  :class="form.injCauseDeath.error ? 'error-text' : ''"
                  ><span class="red">*</span>Did Injury Cause Death?</label
                >
                <div class="radio-buttons">
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="injCauseDeath"
                      v-model="form.injCauseDeath.value"
                      v-on:change="() => {
                        handleCausedDeathChange();
                        handleValueChange(form.injCauseDeath);
                      }"
                      :value="true"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="injCauseDeath"
                      v-model="form.injCauseDeath.value"
                      v-on:change="() => {
                        handleCausedDeathChange();
                        handleValueChange(form.injCauseDeath);
                      }"
                      :value="false"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
              <div class="data-field fourth" :class="!form.injCauseDeath.value ? 'invisible' : ''">
                <label :class="form.dateOfDeath.error ? 'error-text' : ''"
                  >Date of Death</label
                >
                <input
                  type="date"
                  placeholder=""
                  v-model="form.dateOfDeath.value"
                  v-on:change="handleValueChange(form.dateOfDeath)"
                  :class="form.dateOfDeath.error ? 'error-element' : ''"
                />
              </div>
            </div>
            <div class="row">
              <div class="check-box sixth">
                <label class="bold">Did Injury Occur Because of:</label>
              </div>
              <div class="check-box third">
                <div>
                  <input type="checkbox" v-model="form.substanceAbuse.value"/>
                  <label>Substance Abuse</label>
                </div>
              </div>
              <div class="radio-group half">
                <label
                  class="bold"
                  :class="form.treatedEmergency.error ? 'error-text' : ''"
                  ><span class="red">*</span>Was Employee Treated in an
                  Emergency Room?</label
                >
                <div class="radio-buttons">
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="treatedEmergency"
                      v-model="form.treatedEmergency.value"
                      v-on:change="handleValueChange(form.treatedEmergency)"
                      :value="true"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="treatedEmergency"
                      v-model="form.treatedEmergency.value"
                      v-on:change="handleValueChange(form.treatedEmergency)"
                      :value="false"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="check-box sixth"/>
                <div class="check-box third">
                  <input type="checkbox" v-model="form.rulesFailure.value"/>
                  <label>Failure to Obey Rules</label>
                </div>
              <div class="radio-group half">
                <label
                  class="bold"
                  :class="form.treatedHospital.error ? 'error-text' : ''"
                  ><span class="red">*</span>Was Employee Hospitalized Overnight
                  as an In-Patient?</label
                >
                <div class="radio-buttons">
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="treatedHospital"
                      v-model="form.treatedHospital.value"
                      v-on:change="handleValueChange(form.treatedHospital)"
                      :value="true"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="treatedHospital"
                      v-model="form.treatedHospital.value"
                      v-on:change="handleValueChange(form.treatedHospital)"
                      :value="false"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="sixth"/>
              <div class="check-box third">
                  <input type="checkbox" v-model="form.safetyFailure.value"/>
                  <label>Failure to Use Safety Devices</label>
                </div>
              <div class="data-field fourth">
                <label>OSHA Log Case Number</label>
                <input
                  type="text"
                  placeholder="Enter Case Number"
                  v-model="form.oshaLogCaseNum.value"
                  :maxlength="form.oshaLogCaseNum.maxlength"
                />
              </div>
              <div class="fourth" />
            </div>
            <div class="row">
              <div class="data-field half">
                <label :class="form.treatingPhysician.error ? 'error-text' : ''"
                  ><span class="red">*</span>Name of Treating Practitioner and
                  Hospital</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Name(s)"
                  v-model="form.treatingPhysician.value"
                  v-on:change="handleValueChange(form.treatingPhysician)"
                  :class="form.treatingPhysician.error ? 'error-element' : ''"
                  :maxlength="form.treatingPhysician.maxlength"
                />
              </div>
              <div class="data-field half">
                <label :class="form.hospitalOrClinic.error ? 'error-text' : ''"
                  ><span class="red">*</span>Full Address of Treating
                  Practitioner/Hospital</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Full Address"
                  v-model="form.hospitalOrClinic.value"
                  v-on:change="handleValueChange(form.hospitalOrClinic)"
                  :class="form.hospitalOrClinic.error ? 'error-element' : ''"
                  :maxlength="form.hospitalOrClinic.maxlength"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field half">
                <label :class="form.lossWhere.error ? 'error-text' : ''"
                  ><span class="red">*</span>Describe activities of employee
                  when injury or illness occurred:</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.lossWhere.value"
                  v-on:change="handleValueChange(form.lossWhere)"
                  :class="form.lossWhere.error ? 'error-element' : ''"
                  :maxlength="form.lossWhere.maxlength"
                />
              </div>
              <div class="data-field half">
                <label
                  :class="form.injuryObjectsInvolved.error ? 'error-text' : ''"
                  ><span class="red">*</span>What tools, machinery, objects,
                  chemicals, etc. were involved?</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.injuryObjectsInvolved.value"
                  v-on:change="handleValueChange(form.injuryObjectsInvolved)"
                  :class="
                    form.injuryObjectsInvolved.error ? 'error-element' : ''
                  "
                  :maxlength="form.injuryObjectsInvolved.maxlength"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field half">
                <label :class="form.lossHow.error ? 'error-text' : ''"
                  ><span class="red">*</span>What happened to cause injury or
                  illness? (Describe how the injury occurred)</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.lossHow.value"
                  v-on:change="handleValueChange(form.lossHow)"
                  :class="form.lossHow.error ? 'error-element' : ''"
                  :maxlength="form.lossHow.maxlength"
                />
              </div>
              <div class="data-field half">
                <label :class="form.injuryDetails.error ? 'error-text' : ''"
                  ><span class="red">*</span>Describe the injury or illness?
                  (State the part of body and how it was affected)</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.injuryDetails.value"
                  v-on:change="handleValueChange(form.injuryDetails)"
                  :class="form.injuryDetails.error ? 'error-element' : ''"
                  :maxlength="form.injuryDetails.maxlength"
                />
              </div>
            </div>
            <div class="row">
                <h3 style="margin-left: 1%; margin-bottom: 0.2%;">Employee Assigned Address</h3>  
            </div>
            <div class="row">
              <div class="data-field fifth">
                <label :class="form.assignedAddress1.error ? 'error-text' : ''"><span class="red">*</span>Address 1</label>
                <input type="text" placeholder="Enter Address Line 1" v-model="form.assignedAddress1.value" v-on:change="handleValueChange(form.assignedAddress1)"
                    :class="form.assignedAddress1.error ? 'error-element' : ''" :maxlength="form.assignedAddress1.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label>Address 2</label>
                <input type="text" placeholder="Enter address 2" v-model="form.assignedAddress2.value" :maxlength="form.assignedAddress2.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label :class="form.assignedCity.error ? 'error-text' : ''"><span class="red">*</span>City</label>
                <input type="text" placeholder="Enter City" v-model="form.assignedCity.value" v-on:change="handleValueChange(form.assignedCity)"
                    :class="form.assignedCity.error ? 'error-element' : ''" :maxlength="form.assignedCity.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label :class="form.assignedState.error ? 'error-text' : ''"><span class="red">*</span>State</label>
                <select v-model="form.assignedState.value" v-on:change="handleValueChange(form.assignedState)"
                    :class="form.assignedState.error ? 'error-element' : ''">
                  <option value=null disabled selected hidden>Select State</option>
                  <option
                    v-for="state in data.states"
                    :key="state.ID"
                    :value="state.stateCode"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
              </div>
              <div class="data-field fifth">
                <label :class="form.assignedZip.error ? 'error-text' : ''"><span class="red">*</span>ZIP Code</label>
                <input type="text" placeholder="Enter Zip Code" :value="form.assignedZip.display" v-on:change="handleValueChange(form.assignedZip)"
                    :class="form.assignedZip.error ? 'error-element' : ''" v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.assignedZip)
                    }"/>
              </div>
            </div>
            <div class="ldBtns">
              <button class="primary back" v-on:click="handlePrevious()">Back</button>
            <button v-on:click="continueClaim()" class="primary">              
              Continue
            </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import {
  getLossDetailsData,
  saveLossDetailsFROI,
  getDecodedToken,
  saveToken
} from "../../Services/claimsService";
import utilities from "../../Services/utilities"
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";

export default {
  data() {
    return {
      form: {
        claimType: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        notifyPrimaryContact: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        dateOfInjury: {
          required: true,
          type: "datetime-local",
          value: null,
          error: false,
        },
        lastDateWorked: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        dateInjuryNotified: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        dateWorkReturn: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        isLossTimeOrInjury: {
          required: true,
          type: "boolean",
          value: null,
          error: false,
        },
        injCauseDeath: {
          required: true,
          type: "boolean",
          value: null,
          error: false,
        },
        dateOfDeath: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        treatedEmergency: {
          required: true,
          type: "boolean",
          value: null,
          error: false,
        },
        treatedHospital: {
          required: true,
          type: "boolean",
          value: null,
          error: false,
        },
        safetyFailure: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        rulesFailure: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        oshaLogCaseNum: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "25"
        },
        treatingPhysician: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "500"
        },
        hospitalOrClinic: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "500"
        },
        lossWhere: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        injuryObjectsInvolved: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        lossHow: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        injuryDetails: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        assignedAddress1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        assignedAddress2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        assignedCity: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        assignedState: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        assignedZip: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
        substanceAbuse: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
      },
      data: {
        states: [],
        claimTypes: []
      },
      loading: true
    };
  },
  components: { BaseClaimLayout },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getLossDetailsData(this);
          const data = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!data || !data.success) {
            utilities.showFailureAlert(this, "Could not fetch the loss details.");
      } else {

        const decoded = await getDecodedToken();
        const screenInfo = decoded.WIWCLossDetailsScreenInfo;
      if (screenInfo && screenInfo !== "null") {
        this.form = screenInfo;
      } else {
        if (data.lossDetailsData && data.fnolData) {
          utilities.handleFormData(data.lossDetailsData, this.form);
          utilities.handleFormData(data.fnolData, this.form);
        }
      }

        this.data = data;
        this.loading = false;
        this.$swal.close();
      }
        },
      });

      
      
    },
    handleValueChange: utilities.handleValueChange,
    numeric: utilities.numeric,
    handleCausedDeathChange() {
      if (this.form.injCauseDeath.value === true) {
        this.form.dateOfDeath.required = true;
      } else {
        this.form.dateOfDeath.required = false;
        this.form.dateOfDeath.error = false;
      }
    },
    async saveInformation() {
      await saveToken({WIWCLossDetailsScreenInfo: this.form}, this);
    },
     async handlePrevious() {
      await this.saveInformation();
      this.$router.push({
            name: "MakeClaim",
            params: {},
          });
    },
    async continueClaim() {
      const valid = utilities.validateFields(this.form);
      if (valid) {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await saveLossDetailsFROI(this.form, "WI", this);
        const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not save the loss details.");
        } else {
          await saveToken({WIWCLossDetailsScreenInfo: null}, this);

          await utilities.showSuccessAlert(this, "Loss details saved successsfully.");
        this.$router.push({
          name: "WisconsinWorkersCompClaimantInformation",
          params: {},
        });
        }
        },
      });        
      } else {
        utilities.showFailureAlert(this, "Check the errors and try again.", "Missing Required Information!");
      }
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
.removeMe {
  display: flex;
  align-items: center;
}

.ldBtns {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}

.ldBtns button {
  width: 175px;
}

.back {
  background: gray;
}
</style>