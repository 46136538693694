import { api, saveDefaultClaimState, getToken } from "./apiService";
import { showFailureAlert } from "./utilities"
import mime from "mime"
import store from "./Store/store";

async function handleInvalidToken(app) {
  await showFailureAlert(app, "Your session has expired.")
  await saveDefaultClaimState();
  app.$router.push({
    name: "WelcomePage",
    params: {},
  });
}

export const cancelCurrentClaim = async () => {
  try {
    saveDefaultClaimState();
  } catch (error) {
    return {
      success: false,
      message: "Error while getting locations."
    };
  }
};

export const getLossDetailsData = async (app) => {
  try {
    const token = getToken();
    const res = await api.post("claims/?action=getLossDetailsData", { token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      return { data: srvData };
    }
  } catch (error) {
    return {
      success: false,
      message: "Error while getting locations."
    };
  }
};

export const getFileAClaimData = async (entityCode, app) => {
  try {
    const lobCodes = entityCode === "NPIG" ? ["NP"] : ["PL", "WC"];
    const token = getToken() || null;
    const fnolData = await api.post(`claims?action=getFileAClaimData`, { lobCodes, token });
    const srvData = fnolData?.data;

    if (!srvData || !srvData.success) {
      if (srvData?.invalidToken) {
        await handleInvalidToken(app);
      } else {
        return srvData;
      }
    }

    let states = [];
    if (entityCode === "NPIG") {
      const statesRes = await api.post(`claims?action=getNPIGStates`, {});
      if (!statesRes.data || !statesRes.data.success) {
        return statesRes.data;
      }
      states = statesRes.data.states
    }

    return { success: true, coverages: srvData.coverages, states, fnolData: srvData.fnolData };
  } catch (error) {
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const createNewReport = async (data, entityCode, app) => {
  try {
    const token = getToken();
    const res = await api.post(`claims?action=createNewReport`, { data, entityCode, token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      localStorage.setItem("token", srvData?.token);
      store.commit("auth/saveToken", srvData?.token);

      return { data: srvData };
    }
  } catch (error) {
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}
export const getDecodedToken = async () => {
  try {
    const token = getToken();
    if (token) {
      const res = await api.post(`claims?action=getDecodedToken`, { token });
      const srvData = res?.data;
      return srvData;
    } else {
      return null;
    }
  } catch (error) {
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const saveToken = async (data, app) => {
  try {
    const oldToken = getToken()
    const res = await api.post(`claims?action=saveToken`, { data, token: oldToken });
    const srvData = res?.data;

    if (srvData.invalidToken) {
      await handleInvalidToken(app);
    } else {
      const token = srvData?.token;
      localStorage.setItem("token", token);
      store.commit("auth/saveToken", token);
      return { data: res.data };
    }
  } catch (error) {
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const getFNOLAttachments = async (app) => {
  try {
    const token = getToken();
    const res = await api.post(`claims?action=getFNOLAttachments`, { token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app)
    } else {
      return { data: srvData };
    }
  } catch (error) {
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const saveLossDetails = async (data, app) => {
  try {
    const token = getToken();
    const res = await api.post(`claims?action=saveLossDetails`, { data, token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      return { data: res.data };
    }
  } catch (error) {
    console.log("error: ", error)
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}
export const saveLossDetailsFROI = async (data, stateCode = null, app) => {
  try {
    const token = getToken();
    const res = await api.post(`claims?action=saveLossDetailsFROI`, { data, stateCode, token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      return { data: srvData };
    }
  } catch (error) {
    console.log("error: ", error)
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const saveDescription = async (imagingID, description) => {
  try {
    const res = await api.post(`claims?action=saveDescription`, { imagingID, description });
    return { data: res.data };
  } catch (error) {
    console.log("error: ", error)
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const deleteAttachment = async (imagingID) => {
  try {
    const res = await api.post(`claims?action=deleteAttachment`, { imagingID });
    return { data: res.data };
  } catch (error) {
    console.log("error: ", error)
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const saveFROIClaimant = async (data, app) => {
  try {
    const token = getToken();
    const res = await api.post(`claims?action=saveFROIClaimant`, { data, token });
    const srvData = res?.data;

    if (srvData.invalidToken) {
      await handleInvalidToken(app);
    } else {
      return { data: res.data };
    }    
  } catch (error) {
    console.log("error: ", error)
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const getFROIClaimantData = async () => {
  try {
    const res = await api.post(`claims?action=getFROIClaimantData`, {});
    return { data: res.data };
  } catch (error) {
    return res.send({
      success: false,
      message: "Error getting claimant data."
    });
  }
}

export const getStates = async () => {
  try {
    const res = await api.post(`claims?action=getStates`, {});
    return { data: res.data };
  } catch (error) {
    console.log("error: ", error)
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const createWorkflowItem = async (fnolID) => {
  try {
    const res = await api.post(`claims?action=createWorkflowItem`, { fnolID });
    return { data: res.data };
  } catch (error) {
    console.log("error: ", error)
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const downloadAttachment = async (fileName) => {
  try {
    const contentType = mime.getType(fileName);
    const extension = mime.getExtension(contentType);
    const res = await api.post(`claims?action=downloadAttachment`, { fileName, contentType }, "blob", true);
    const path = window.URL.createObjectURL(res.data);
    const link = document.createElement('a');
    link.href = path;
    let fName = res.headers.filename ? res.headers.filename : `file.${extension}`;
    link.setAttribute('download', fName);
    document.body.appendChild(link);
    link.click();
    return { data: res.data };
  } catch (error) {
    console.log("error: ", error)
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}


