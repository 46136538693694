<template>
  <div class="wrapper" :class="collapsed ? 'collapsed' : ''">
    <nav id="SideNave">
      <ul>
        <li class="nav-item" v-on:click="redirect('/policySelect')">
          <span class="icon"
            ><font-awesome-icon icon="magnifying-glass"
          /></span>
          <span class="tab" v-if="!collapsed">Policy Select</span>
        </li>
        <li class="nav-item" v-on:click="redirect('/summary')" v-if="policyID">
          <span class="icon"><font-awesome-icon icon="wallet" /></span>
          <span class="tab" v-if="!collapsed">Policy Summary</span>
        </li>
        <li
          v-if="rights.includes('allowBillingAccess') && policyID"
          class="nav-item"
          v-on:click="redirect('/billing')"
        >
          <span class="icon"><font-awesome-icon icon="envelope-open" /></span>
          <span class="tab" v-if="!collapsed">Billing Details</span>
        </li>
        <li
          v-if="rights.includes('allowDocumentsAccess') && policyID"
          class="nav-item"
          v-on:click="toggleSubList()"
        >
          <span class="icon"><font-awesome-icon icon="folder-open" /></span>
          <span class="tab" v-if="!collapsed">Documents</span>
        </li>
        <ul v-if="showSublist && policyID" :class="[collapsed ? 'collapsed-sublist' : 'sublist', showSublist ? '' : 'slideDown']">
          <li v-on:click="redirect('/policyDocuments')" v-if="!collapsed">Policy Documents</li>
          <li v-on:click="redirect('/policyDocuments')" v-if="collapsed">Policy</li>
          <li v-on:click="redirect('/endorsementDocuments')" class="removeMe" v-if="!collapsed">
            Endorsement Documents
          </li>
          <li v-on:click="redirect('/endorsementDocuments')" class="removeMe" v-if="collapsed">
            Endorsement
          </li>
          <li v-on:click="redirect('/certificates')" class="removeMe">Certificates</li>
          <li v-on:click="comingSoon()" class="removeMe">Audits</li>
          <!-- <li v-on:click="redirect('/audits')">Audits</li> -->
        </ul>
        <li class="nav-item" v-on:click="redirect('/history')" v-if="policyID">
          <span class="icon"
            ><font-awesome-icon icon="clock-rotate-left"
          /></span>
          <span class="tab" v-if="!collapsed">History</span>
        </li>
        <li
          v-if="rights.includes('allowClaimsAccess') && policyID"
          class="nav-item"
          v-on:click="redirect('/claimList')"
        >
          <span class="icon"><font-awesome-icon icon="comment" /></span><span class="tab" v-if="!collapsed">Claims</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {
  getShowSublist
} from "../Services/apiService";
import { getDecodedAuthToken } from "../Services/authService"

export default {
  data() {
    return {
      rights: [],
      showSublist: getShowSublist(),
      policyID: null
    };
  },
  props: ["collapsed"],
  methods: {
    async initPage() {
      const decoded = await getDecodedAuthToken();
      this.rights = decoded?.rights || [];
      this.policyID = decoded?.policyID || null;
    },
    async redirect(path) {
      this.$router.push({ path });
      // const decoded = await getDecodedAuthToken();
      // const policyID = decoded?.policyID;
      // if (!policyID || policyID === "") {
      //   this.$swal(`Please select a policy term`);
      // } else {
      //   this.$router.push({ path });
      // }
    },
    async toggleSubList() {
      localStorage.setItem("showSublist", !this.showSublist);
      await this.$store.commit("auth/saveShowSublist", !this.showSublist);
      this.showSublist = !this.showSublist;
    },
    comingSoon() {
      this.$swal.fire({
        icon: "info",
        title: "Coming Soon!",
        text: "This screen is currently under construction",
        confirmButtonColor: "#2b8cbe",
      });
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  background-color: #2b8cbe;
  color: #fff;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 12vh;
  width: 15%;
  font-size: 20px;
  h3 {
    margin-bottom: 40px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 10px 0 0 15px;
    a {
      text-decoration: none;
      color: #fff;
    }
    li {
      display: flex;
      padding: 5px 10px;
      width: 250px;
      border-radius: 5px;      
      align-items: center;
    }

    .removeMe {
    margin-top: 20px;
  }

    li:hover {
      background-color: black;
      cursor: pointer;
    }
  }
}

.icon {
  font-size: 30px;
}

.collapsed {
  width: 5%;
}

.collapsed ul {
  padding: 0;
  width: 100%;
}

.collapsed ul li {
  padding: 0;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 16px;
}

.tab {
  margin-left: 10px;
  height: 30px;
}

.sublist {
  margin-top: 0;
  padding-top: 0;
    margin-left: 10px;
    padding: 0px 0;
  }

  .collapsed-sublist {
    margin: 0
  }
</style>