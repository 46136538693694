import { api, getAuthToken, saveDefaultState } from "./apiService";
import { saveToken } from "./authService"
import { showFailureAlert } from "./utilities"

import mime from "mime"

async function handleInvalidToken(app) {
  await showFailureAlert(app, "Your session has expired.")
  await saveDefaultState();
  app.$router.push({
    name: "WelcomePage",
    params: {},
  });
}

const qsxgetUrlVars = function () {
  const vars = {};
  let hash;
  const hashes = window.location.href
    .slice(window.location.href.indexOf("?") + 1)
    .split("&");
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    vars[hash[0]] = hash[1];
  }
  return vars;
};
const qsxgetUrlVar = function (name) {
  return qsxgetUrlVars()[name];
};

export const getTerms = async (app) => {
  try {
    const token = getAuthToken();
    const res = await api.post("customer?action=getTerms", { token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (error) {
    return {
      success: false,
      message: "Error getting user's policies."
    }
  }
}

export const getTermsByMember = async (app) => {
  try {
    const token = getAuthToken();
    const lobCode = localStorage.getItem('lobCode');
    const organizationNumber = localStorage.getItem('organizationNumber');
    const res = await api.post("customer?action=getTermsByMember", { token, lobCode, organizationNumber });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (error) {
    return {
      success: false,
      message: "Error getting member's policies."
    }
  }
}

export const getEmployeeInfo = async () => {
  try {
    const res = await api.post("customer?action=getEmployeeInfo", {});
    return { data: res.data };
  } catch (error) {
    return {
      success: false,
      message: ""
    }
  }
}

export const getStates = async () => {
  try {
    const res = await api.post("customer?action=getStates", {});
    return { data: res.data };
  } catch (error) {
    return {
      success: false,
      message: "Error while getting states."
    }
  }
}

export const getSummary = async (app) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=summary`, { token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (error) {
    return {
      success: false,
      message: "Error getting summary data."
    }
  }
}

export const getClaimsList = async (app) => {
  try {
    const token = getAuthToken();
    const organizationNumber = localStorage.getItem('organizationNumber');
    const res = await api.post(`customer?action=getClaimsList`, { token , organizationNumber });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }    
  } catch (error) {
    return {
      success: false,
      message: "Error getting summary data."
    }
  }
}

export const getNotes = async (claimID, app) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=getNotes`, { claimID, token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (error) {
    return {
      success: false,
      message: "Error getting summary data."
    }
  }
}

export const policyDocuments = async (app) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=policyDocuments`, { token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (error) {
    return {
      success: false,
      message: "Error getting user's documents."
    }
  }
}

export const endorsementDocuments = async (app) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=endorsementDocuments`, { token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (error) {
    return {
      success: false,
      message: "Error getting the certifictes."
    }
  }
}

export const certificates = async (app) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=certificates`, {token});
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (error) {
    return {
      success: false,
      message: "Error getting the certifictes."
    }
  }
}

export const createCertificate = async (data, app) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=createCertificate&token=${token}&data=${data}`, {});
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      const link = await download("policybill", srvData?.reportPath, true);
      return link;
    }      
  } catch (error) {
    return {
      success: false,
      message: "Error creating the certificate."
    }
  }
}

export const getBilling = async (app) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=billing`, { token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (err) {
    return {
      success: false,
      message: "Error getting billing data."
    }
  }
}

export const getHistory = async (app) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=history`, { token });
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      await saveToken({});
      return { data: srvData };
    }  
  } catch (error) {
    return {
      success: false,
      message: "Error getting policy history"
    }
  }
}

export const printCertificates = async (partyList, app) => {
  try {
    const token = getAuthToken();
    let sid = qsxgetUrlVar("sid");
    const res = await api.post(`customer?sid=${sid || ''}&action=printCertificates&partyList=${JSON.stringify(partyList)}&token=${token}`, {});
    const srvData = res?.data;

    if (srvData?.invalidToken) {
      await handleInvalidToken(app);
    } else {
      if (srvData?.success) {
        await saveToken({});
        const link = (await download("policybill", srvData.filePath, false));
  
        return link; 
      } else {
        return {
          success: false
        }
      }
    }
  } catch (error) {
    return {
      success: false,
      message: "Error printing certificates."
    }
  }
}

export const deleteCertificate = async (certID) => {
  try {
    const token = getAuthToken();
    const res = await api.post(`customer?action=deleteCertificate&certID=${certID}&token=${token}`);
    return { data: res.data };
  } catch (error) {
    return {
      success: false,
      message: "Error deleting certificate."
    }
  }
}

export const download = async (action, fileBatch, print = false) => {
  try {
    const token = getAuthToken();
    var printed = false;
    let fID = "";
    let sid = qsxgetUrlVar("sid");
    if (fileBatch.length === 1) {
      fID = fileBatch[0];
    }
    let url;
    if (action === "policydoc" || action === "certificate" || action === "policybill") {
      url = `customer/utilities/downloadcompany?sid=${sid || ''}&action=${action}&fileID=${fID || ''}&fileBatch=${fileBatch || ''}&token=${token || false}`;
    }
    else {
      return 'Missing Parameters';
      //throw new Error('Missing Parameters');
    }
    const res = await api.post(url, undefined, "blob");
    const text = await res.data?.text();
    if (text === "false") {
      return { success: false }
    } else {
      const path = window.URL.createObjectURL(res.data);
    if (print) {
      const link = document.createElement('a');
      link.href = path;
      let fName = res.headers.filename ? res.headers.filename : "file.pdf";
      link.setAttribute('download', fName);
      document.body.appendChild(link);
      // link.click();
      return {
        success: true,
        link
      }

    } else {
      return {
        success: true,
        message: "",
        path
      }
    }
    }    
  } catch (error) {;
    return {
      success: false,
      message: "Error downloading the form."
    }
  }
}

export const downloadReport = async (report, clickLink = true) => {
  try {
    // var printed = false;
    // let fID = "";
    const contentType = mime.getType(report);
    const extension = mime.getExtension(contentType);
    let sid = qsxgetUrlVar("sid");
    const token = getAuthToken();
    let url = `customer/utilities/downloadcompany?sid=${sid || ''}&action=report&report=${report}&token=${token}`;
    const res = await api.post(url, undefined, "blob", true);
    const text = await res.data?.text();
    if (text === "false") {
      return false;
    } 
    const path = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = path;
    let fName = res.headers.fileName ? res.headers.fileName : `file.${extension}`;
    link.setAttribute('download', fName);
    document.body.appendChild(link);
    if (!clickLink) {
      return link;
    } else {
      link.click();
    }    
    return true
  } catch (error) {;
    return false
  }
}

export const handleLossRunReport = async () => {
  try {
    const token = await getAuthToken();
    const link = await downloadReport("LOSSRUN.PDF", false);
    const res = await api.post(`customer?action=createLossRunWorkflow`, { token });
    const srvData = res?.data;

    if(srvData.success) {
      link.click();
    }

    return srvData.success;

    
  } catch (error) {;
    return false
  }
}

export const logError = async (error) => {
  const res = await api.post(`auth?action=logError`, { error });
  return res.data;
}