<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 class="fade-in">File A Claim</h2>
        </div>
        <div class="content">
          <div class="fade-in">
            <div class="section-head">
            <h3>Attachment Entry</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field whole">
                <label><span class="red">*</span>Attachment Description</label>
                <textarea
                  type="text"
                  placeholder="Enter Attachment Description"
                  v-model="description"
                />
              </div>
            </div>
            <div class="row">
              <button class="primary sixth" v-on:click="updateDescription()">
                Save Changes
              </button>
              <button
                class="primary sixth"
                v-on:click="redirect()"
              >
                Cancel
              </button>
              <div class="data-field two-thirds" />
            </div>
          </div>
          </div>
        </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";
import { saveDescription } from "../../Services/claimsService";
import utilities from "../../Services/utilities"

export default {
  data() {
    return {
      // description: null,
      // imagingID: null,
    };
  },
  components: { BaseClaimLayout },
  props: ["imagingID"],
  methods: {
    redirect() {
      this.$router.push({ path: this.$router.options.history.state.back });
    },
    async updateDescription() {
      if (this.description && this.description !== "") {        

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await saveDescription(this.imagingID, this.description);
          const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "There was a problem saving the description. Please try again later.");         
        } else {
          await utilities.showSuccessAlert(this, "Description saved successfully.");
          this.$router.push({ path: this.$router.options.history.state.back });
        }
        
        },
      });        
      }
    },
  },
};
</script>