<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 v-if="!loading" class="fade-in">File A Claim</h2>
        </div>
        <div class="content">
          <div v-if="!loading" class="fade-in">
            <div class="section-head">
            <h3>Claimant Personal Information</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field sixth">
                <label :class="form.firstName.error ? 'error-text' : ''"
                  ><span class="red">*</span>Employee First Name</label
                >
                <input
                  type="text"
                  placeholder="Enter First Name"
                  v-model="form.firstName.value"
                  :class="form.firstName.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.firstName)"
                  :maxlength="form.firstName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label>Employee Middle Name</label>
                <input
                  type="text"
                  placeholder="Enter Middle Name"
                  v-model="form.middleName.value"
                  :maxlength="form.middleName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.lastName.error ? 'error-text' : ''"
                  ><span class="red">*</span>Employee Last Name</label
                >
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  v-model="form.lastName.value"
                  :class="form.lastName.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.lastName)"
                  :maxlength="form.lastName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.dateOfBirth.error ? 'error-text' : ''"
                  ><span class="red">*</span>Date of Birth</label
                >
                <input
                  type="date"
                  placeholder=""
                  v-model="form.dateOfBirth.value"
                  v-on:change="handleValueChange(form.dateOfBirth)"
                />
              </div>
              <div class="radio-group sixth">
                <label
                  class="bold"
                  :class="form.gender.error ? 'error-text' : ''"
                  ><span class="red">*</span>Gender:</label
                >
                <div class="row half">
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.gender.value"
                      name="gender"
                      :value="'M'"
                      v-on:change="handleValueChange(form.gender)"
                    />
                    <label>Male</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.gender.value"
                      name="gender"
                      :value="'F'"
                      v-on:change="handleValueChange(form.gender)"
                    />
                    <label>Female</label>
                  </div>
                </div>
              </div>
              <div class="data-field sixth">
                <label>Marital Status</label>
                <select
                  v-model="form.maritalStatus.value"
                  v-on:change="handleValueChange(form.maritalStatus)"
                >
                  <option value=null disabled selected hidden>
                    Select Status
                  </option>
                  <option
                    v-for="status in data.maritalStatuses"
                    :key="status.id"
                    :value="status.dccode"
                  >
                    {{ status.display }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="data-field third">
                <label :class="form.address1.error ? 'error-text' : ''"
                  ><span class="red">*</span>Address Line 1</label
                >
                <input
                  type="text"
                  placeholder="Enter Address Line 1"
                  v-model="form.address1.value"
                  :class="form.address1.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.address1)"
                  :maxlength="form.address1.maxlength"
                />
              </div>
              <div class="data-field third">
                <label>Address Line 2</label>
                <input
                  type="text"
                  placeholder="Apartment, suite, building, unit, floor, etc."
                  v-model="form.address2.value"
                  :maxlength="form.address2.maxlength"
                />
              </div>
              <div class="data-field ninth">
                <label :class="form.city.error ? 'error-text' : ''"
                  ><span class="red">*</span>City</label
                >
                <input
                  type="text"
                  placeholder="Enter City"
                  v-model="form.city.value"
                  :class="form.city.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.city)"
                  :maxlength="form.city.maxlength"
                />
              </div>
              <div class="data-field ninth">
                <label :class="form.state.error ? 'error-text' : ''"
                  ><span class="red">*</span>State</label
                >
                <select
                  v-model="form.state.value"
                  :class="form.state.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.state)"
                >
                  <option value=null disabled selected hidden>
                    Select State
                  </option>
                  <option
                    v-for="state in data.states"
                    :key="state.ID"
                    :value="state.stateCode"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
              </div>
              <div class="data-field ninth">
                <label :class="form.zip.error ? 'error-text' : ''"
                  ><span class="red">*</span>ZIP</label
                >
                <input
                  type="number"
                  placeholder="Enter ZIP"
                  :class="form.zip.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.zip)"
                  :value="form.zip.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.zip)
                    }"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field fourth">
                <label :class="form.phoneNumber.error ? 'error-text' : ''"
                  ><span class="red">*</span>Home Phone</label
                >
                <input
                  type="text"
                  placeholder=""
                  :value="form.phoneNumber.display"
                  maxlength="14"
                  v-on:input="
                    (e) => {
                      phoneMaskFormat(
                        e.target.value,
                        form.phoneNumber
                      );
                      handleValueChange(form.phoneNumber)
                    }
                  "
                  :class="form.phoneNumber.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field fourth">
                <label :class="form.cellNumber.error ? 'error-text' : ''"
                  >Cell Phone</label
                >
                <input
                  type="text"
                  placeholder=""
                  :value="form.cellNumber.display"
                  maxlength="14"
                  v-on:input="
                    (e) => {
                      phoneMaskFormat(
                        e.target.value,
                        form.cellNumber
                      );
                      handleValueChange(form.cellNumber);
                    }
                  "
                  :class="form.cellNumber.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field fourth">
                <label>Email Address</label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  v-model="form.email.value"
                  :maxlength="form.email.maxlength"
                />
              </div>
              <div class="data-field fourth">
                <label :class="form.ssn.error ? 'error-text' : ''"
                  ><span class="red">*</span>SSN</label
                >
                <input
                  type="text"
                  placeholder=""
                  :class="form.ssn.error ? 'error-element' : ''"
                  :value="form.ssn.display"
                  v-on:input="
                    (e) => {
                      form.ssn.display = ssnMaskFormat(e.target.value, form.ssn);
                      e.target.value = ssnMaskFormat(e.target.value, form.ssn);
                      handleValueChange(form.ssn);
                    }
                  "
                />
              </div>
              <div class="data-field fourth">
                <label>OSHA Case/Loc #</label>
                <input
                  type="text"
                  placeholder="Enter Case Number"
                  v-model="form.oshaLocCaseNum.value"
                  :maxlength="form.oshaLocCaseNum.maxlength"
                />
              </div>
              <div class="data-field fourth">
                <label>Number of Dependents</label>
                <input
                  type="number"
                  placeholder="Enter Number"
                  :value="form.dependentsNum.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.dependentsNum)
                    }"
                />
              </div>
            </div>
          </div>
          <div class="section-head">
            <h3>Claimant Employment Information</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field sixth">
                <label :class="form.occupation.error ? 'error-text' : ''"
                  ><span class="red">*</span>Occupation</label
                >
                <input
                  type="text"
                  placeholder="Enter Occupation"
                  v-model="form.occupation.value"
                  :class="form.occupation.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.occupation)"
                  :maxlength="form.occupation.maxlength"
                />
              </div>
              <div class="data-field third">
                <label :class="form.jobTitle.error ? 'error-text' : ''"
                  ><span class="red">*</span>Job Title</label
                >
                <input
                  type="text"
                  placeholder="Enter Job Title"
                  v-model="form.jobTitle.value"
                  :class="form.jobTitle.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.jobTitle)"
                  :maxlength="form.jobTitle.maxlength"
                />
              </div>
              <div class="data-field third">
                <label :class="form.regularDept.error ? 'error-text' : ''"
                  ><span class="red">*</span>Regular Department</label
                >
                <input
                  type="text"
                  placeholder="Enter Department Name"
                  v-model="form.regularDept.value"
                  :class="form.regularDept.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.regularDept)"
                  :maxlength="form.regularDept.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.dateHired.error ? 'error-text' : ''"
                  ><span class="red">*</span>Date Hired</label
                >
                <input
                  type="date"
                  placeholder="Enter Date Hired"
                  v-model="form.dateHired.value"
                  :class="form.dateHired.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.dateHired)"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field sixth">
                <label :class="form.dateHired.error ? 'error-text' : ''"
                  ><span class="red">*</span>Employment Status</label
                >
                <select
                  v-model="form.employmentStatus.value"
                  :class="form.employmentStatus.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.employmentStatus)"
                >
                  <option value=null disabled selected hidden>
                    Select Employment Status
                  </option>
                  <option
                    v-for="status in data.employmentStatuses"
                    :key="status.ID"
                    :value="status.dccode"
                  >
                    {{ status.display }}
                  </option>
                </select>
              </div>
              <div class="data-field sixth">
                <label :class="form.ratePerHour.error ? 'error-text' : ''"
                  ><span class="red">*</span>Rate per Hour</label
                >
                <input
                  type="text"
                  placeholder="Enter Rate"
                  :class="form.ratePerHour.error ? 'error-element' : ''"
                  :value="form.ratePerHour.display"
                  v-on:input="
                    (e) => {
                      e.target.value = moneyMaskFormat(e.target.value, form.ratePerHour);
                      form.ratePerHour.display = moneyMaskFormat(e.target.value, form.ratePerHour);
                      handleValueChange(form.ratePerHour);
                    }
                  "
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.hoursPerDay.error ? 'error-text' : ''"
                  ><span class="red">*</span>Hours per Day</label
                >
                <input
                  type="number"
                  placeholder="Enter Hours"
                  :class="form.hoursPerDay.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.hoursPerDay)"
                  :value="form.hoursPerDay.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.hoursPerDay)
                    }"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.daysPerWeek.error ? 'error-text' : ''"
                  ><span class="red">*</span>Days per Week</label
                >
                <input
                  type="number"
                  placeholder="Enter Days"
                  :class="form.daysPerWeek.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.daysPerWeek)"
                  :value="form.daysPerWeek.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.daysPerWeek)
                    }"
                />
              </div>
              <div class="data-field sixth">
                <label>Average Weekly Wage</label>
                <input
                  type="text"
                  placeholder="Enter Wage"
                  :value="form.weeklyWage.display"
                  v-on:input="
                    (e) => {
                      e.target.value = moneyMaskFormat(e.target.value, form.weeklyWage);
                      form.weeklyWage.display = moneyMaskFormat(e.target.value, form.weeklyWage);
                      handleValueChange(form.weeklyWage);
                    }
                  "
                />
              </div>
              <div class="radio-group sixth">
                <label class="bold">Apprentice:</label>
                <div class="row half">
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="isApprentice"
                      :value="true"
                      v-model="form.isApprentice.value"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      name="isApprentice"
                      :value="false"
                      v-model="form.isApprentice.value"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="data-field sixth">
                <label>Weekly Meals Value</label>
                <input
                  type="text"
                  placeholder="Enter Value"
                  :value="form.weeklyMealsValue.display"
                  v-on:input="
                    (e) => {
                      e.target.value = moneyMaskFormat(e.target.value, form.weeklyMealsValue);
                      form.weeklyMealsValue.display = moneyMaskFormat(e.target.value, form.weeklyMealsValue);
                      handleValueChange(form.weeklyMealsValue);
                    }
                  "
                />
              </div>
              <div class="data-field sixth">
                <label>Weekly Lodging Value</label>
                <input
                  type="text"
                  placeholder="Enter Value"
                  :value="form.weeklyLodgingValue.display"
                  v-on:input="
                    (e) => {
                      form.weeklyLodgingValue.display = moneyMaskFormat(e.target.value, form.weeklyLodgingValue);
                      e.target.value = moneyMaskFormat(e.target.value, form.weeklyLodgingValue);
                      handleValueChange(form.weeklyLodgingValue);
                    }
                  "
                />
              </div>
              <div class="data-field sixth">
                <label>Weekly 2nd Income</label>
                <input
                  type="text"
                  placeholder="Enter Income"
                  :value="form.weekly2ndIncome.display"
                  v-on:input="
                    (e) => {
                      form.weekly2ndIncome.display = moneyMaskFormat(e.target.value, form.weekly2ndIncome);
                      e.target.value = moneyMaskFormat(e.target.value, form.weekly2ndIncome);
                      handleValueChange(form.weekly2ndIncome);
                    }
                  "
                />
              </div>
              <div class="checkbox-group half">
                <label :class="form.weekDaysAnswered.error ? 'error-text' : ''"
                  ><span class="red">*</span>Normal Work Schedule</label
                >
                <div class="checkboxes">
                  <div class="">
                    <input
                      type="checkbox"
                      name="ws_Sunday"
                      v-model="form.ws_Sunday.value"
                      v-on:change="handleChecks()"
                    />
                    <label>Sunday</label>
                  </div>
                  <div class="">
                    <input
                      type="checkbox"
                      name="ws_Monday"
                      v-model="form.ws_Monday.value"
                      v-on:change="handleChecks()"
                    />
                    <label>Monday</label>
                  </div>
                  <div class="">
                    <input
                      type="checkbox"
                      name="ws_Tuesday"
                      v-model="form.ws_Tuesday.value"
                      v-on:change="handleChecks()"
                    />
                    <label>Tuesday</label>
                  </div>
                  <div class="">
                    <input
                      type="checkbox"
                      name="ws_Wednesday"
                      v-model="form.ws_Wednesday.value"
                      v-on:change="handleChecks()"
                    />
                    <label>Wednesday</label>
                  </div>
                  <div class="">
                    <input
                      type="checkbox"
                      name="ws_Thursday"
                      v-model="form.ws_Thursday.value"
                      v-on:change="handleChecks()"
                    />
                    <label>Thursday</label>
                  </div>
                  <div class="">
                    <input
                      type="checkbox"
                      name="ws_Friday"
                      v-model="form.ws_Friday.value"
                      v-on:change="handleChecks()"
                    />
                    <label>Friday</label>
                  </div>
                  <div class="">
                    <input
                      type="checkbox"
                      name="ws_Saturday"
                      v-model="form.ws_Saturday.value"
                      v-on:change="handleChecks()"
                    />
                    <label>Saturday</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Attachments @save-information="saveInformation"/>
          <div class="ldBtns">
              <button class="primary back" v-on:click="handlePrevious()">Back</button>
          <button class="primary" v-on:click="continueClaim()">SUBMIT CLAIM</button>
          </div>
          </div>
        </div>
        <dashboard-modal
          :uppy="uppy"
          :open="open"
          :props="{
            onRequestCloseModal: handleUploader,
          }"
        />
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import {
  saveFROIClaimant,
  getFROIClaimantData,
  getDecodedToken,
  saveToken
} from "../../Services/claimsService";
import { saveDefaultClaimState } from "../../Services/apiService";
import utilities, { showSuccessAlert } from "../../Services/utilities";
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";
import Attachments from "../Partials/Attachments.vue"

export default {
  data() {
    return {
      form: {
        firstName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        middleName: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        lastName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        dateOfBirth: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        gender: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        maritalStatus: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        address1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        address2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        city: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        state: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        zip: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
        phoneNumber: {
          required: true,
          type: "phone",
          display: "",
          value: null,
          error: false,
        },
        cellNumber: {
          required: false,
          type: "phone",
          value: null,
          error: false,
        },
        ssn: {
          required: true,
          type: "text",
          value: null,
          display: "",
          error: false,
        },
        email: {
          required: false,
          type: "email",
          value: null,
          error: false,
          maxlength: "75"
        },
        oshaLocCaseNum: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "25"
        },
        dependentsNum: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999999
        },
        occupation: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "120"
        },
        jobTitle: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "150"
        },
        regularDept: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "150"
        },
        dateHired: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        employmentStatus: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        ratePerHour: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxvalue: 9999.99
        },
        hoursPerDay: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 24
        },
        isApprentice: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        daysPerWeek: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 7
        },
        weeklyWage: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxvalue: 9999.99
        },
        weekly2ndIncome: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxvalue: 9999.99
        },
        workSchedule: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        ws_Sunday: {
          required: false,
          type: "boolean",
          name: "Sunday",
          value: null,
          error: false,
        },
        ws_Monday: {
          required: false,
          type: "boolean",
          name: "Monday",
          value: null,
          error: false,
        },
        ws_Tuesday: {
          required: false,
          type: "boolean",
          name: "Tuesday",
          value: null,
          error: false,
        },
        ws_Wednesday: {
          required: false,
          type: "boolean",
          name: "Wednesday",
          value: null,
          error: false,
        },
        ws_Thursday: {
          required: false,
          type: "boolean",
          value: null,
          name: "Thursday",
          error: false,
        },
        ws_Friday: {
          required: false,
          type: "boolean",
          name: "Friday",
          value: null,
          error: false,
        },
        ws_Saturday: {
          required: false,
          type: "boolean",
          name: "Saturday",
          value: null,
          error: false,
        },
        weeklyMealsValue: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxvalue: 9999.99
        },
        weeklyLodgingValue: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxvalue: 9999.99
        },
        weekDaysAnswered: {
          required: true,
          type: "group",
          value: false,
          error: false,
        },
      },
      data: {
        states: [],
        employmentStatuses: [],
        maritalStatuses: [],
      },
      loading: true
    };
  },
  components: { BaseClaimLayout, Attachments },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getFROIClaimantData();
          const data = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!data || !data.success) {
        utilities.showFailureAlert(this, "Could not fetch the claimant info.");
      } else {
        this.data = data;
        const decoded = await getDecodedToken();
        const screenInfo = decoded.MNWCClaimantInfoScreenInfo; 

      if (screenInfo && screenInfo !== "null" && screenInfo !== "undefined") {
        this.form = screenInfo;
      }

      this.loading = false;
      this.$swal.close();
      }

        },
      });      
    },
    handleValueChange: utilities.handleValueChange,
    phoneMaskFormat: utilities.phoneMaskFormat,
    ssnMaskFormat: utilities.ssnMaskFormat,
    moneyMaskFormat: utilities.moneyMaskFormat,
    numeric: utilities.numeric,
    handleChecks() {
      let answered = false;
      let workSchedule = "";
      [
        this.form.ws_Sunday,
        this.form.ws_Monday,
        this.form.ws_Tuesday,
        this.form.ws_Wednesday,
        this.form.ws_Thursday,
        this.form.ws_Friday,
        this.form.ws_Saturday,
      ].forEach((field) => {
        if (field.value === true) {
          answered = true;
          if (field.name === "Sunday") {
            workSchedule += field.name;
          } else {
            workSchedule += "," + field.name;
          }
        }
      });
      this.form.workSchedule.value = workSchedule;
      this.form.weekDaysAnswered.value = answered;
      this.form.weekDaysAnswered.error = !answered;
    },
    async continueClaim() {
      const valid = utilities.validateFields(this.form);
      if (valid) {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await saveFROIClaimant(this.form, this);
          const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not save the claimant information.");
        } else {
          await showSuccessAlert(this, "Claimant information saved successsfully.");

        await saveDefaultClaimState();
      
          this.$router.push({
            name: "FinishClaim",
            params: {},
          });
        }
        
        },
      });        
      } else {
        utilities.showFailureAlert(this, "Check the errors and try again.", "Missing Required Information!");
      }
    },
    async saveInformation() {
      await saveToken({MNWCClaimantInfoScreenInfo: this.form}, this);
    },
    async handlePrevious() {
      await this.saveInformation();
      this.$router.push({
            name: "WorkersCompLossDetails",
            params: {},
          });
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>

.checkbox-label {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.chkBox {
  margin-right: 10px;
  width: 20px;
}

.check-box {
  min-height: 60px;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.checkbox-group {
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 60px;
}

.check-box input {
  margin-right: 10px;
}

.checkboxes {
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.checkboxes div {
  /* margin-right: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

tr {
  border-bottom: 2px solid #e7e7e7;
  height: 40px;
}

th,
td {
  text-align: left;
  padding-left: 5px;
}

.ldBtns {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}

.ldBtns button {
  width: 175px;
}


.radio-group {
  flex-wrap: wrap;
}


.back {
  background: gray;
}
</style>