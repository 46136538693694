<template>
 <BaseLayout @selectedTermChanged="initPage()">
    <template #content>
      <article>
        <div class="policy-background">
          <div class="title">
            <h2 v-if="!loading" class="fade-in">HISTORY</h2>
          </div>
          <div class="policy-content">
            <div v-if="!loading" class="fade-in">
                  <div class="data-field sixth">
                    <label>Display Rows Per Page</label>
                    <select v-model="displayRows" v-on:change="handleDisplayRows()">
                      <option :value="10">10</option>
                      <option :value="25">25</option>
                      <option :value="50">50</option>
                      <option :value="100">100</option>
                      <option :value="300">300</option>
                      <option :value="1000000000">View All</option>
                    </select>
                  </div>
              <table>
              <thead>
                <tr>
                  <th style="width: 300px">Date/Time</th>
                  <th>Event</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="hist in data.history.slice((pageNumber * displayRows), (pageNumber * displayRows) + displayRows)" :key="hist.ID">
                  <td>{{hist.date_created}}</td>
                  <td>{{hist.event}}</td>
                </tr>
              </tbody>
            </table>
            <div class="no-data" v-if="!data.history || data.history.length === 0">
                <h3>No Data</h3>
              </div>
            <div class="pagination" v-if="data.history && data.history.length > 0">
              <p>Showing {{ ((pageNumber + 1) * displayRows) - (displayRows - 1) }} to {{getNumEntries(pageNumber, data.history.length)}} of {{data.history.length}} entries</p>
              <div class="pagination-buttons">
                <button v-on:click="handlePageNumber('-', data.history, 'pageNumber')" class="next-buttons">Previous</button>
                    <button
                      v-if="needPrevSeparator"
                      class="separator"
                    >
                      ...
                    </button>
                    <button v-on:click="() => { pageNumber = pageNumber-2}" v-if="button1" class="page-buttons" :class="{selected: pageNumber === pageNumber - 2}">{{pageNumber-1}}</button>
                    <button v-on:click="() => { pageNumber = pageNumber-2}" v-if="button2" class="page-buttons" :class="{selected: pageNumber === pageNumber - 2}">{{pageNumber-1}}</button>
                    <button v-on:click="() => { pageNumber = pageNumber-1}" v-if="button3" class="page-buttons" :class="{selected: pageNumber === pageNumber - 1}">{{pageNumber}}</button>
                    <button v-on:click="() => { pageNumber = pageNumber}" class="page-buttons" :class="{selected: pageNumber === pageNumber}">{{pageNumber+1}}</button>
                    <button v-on:click="() => { pageNumber = pageNumber+1}" v-if="button4" class="page-buttons" :class="{selected: pageNumber === pageNumber + 1}">{{pageNumber+2}}</button>
                    <button v-on:click="() => { pageNumber = pageNumber+2}" v-if="button5" class="page-buttons" :class="{selected: pageNumber === pageNumber + 2}">{{pageNumber+3}}</button>
                    <button
                      v-if="needNextSeparator"
                      class="separator"
                    >
                      ...
                    </button>
                <button v-on:click="handlePageNumber('+', data.history, 'pageNumber')" class="next-buttons">Next</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </article>
     </template>
  </BaseLayout>
</template>

<script>
import { getHistory } from "./../../Services/customerService";
import utilities from "../../Services/utilities"

export default {
  data() {
    const data = {
      history: []
    }
    return {
      data,
      pageNumber: 0,
      loading: true,
      displayRows: 10
    };
  },
  methods:{
     async initPage() {

       this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getHistory(this);
       const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not fetch the policy history.");
        } else {
          this.data = srvData;
          this.loading = false;
          this.$swal.close();
        }
        },
      });
     },
    getNumEntries(pageNum, length) {
       if (length < ((pageNum * this.displayRows) + this.displayRows)) {
         return (pageNum * this.displayRows) + (length - (pageNum * this.displayRows)) ;
       } else {
         return (pageNum * this.displayRows) + this.displayRows;
       }
     },
     handlePageNumber(x, arr, page) {
       if (x === "-") {
         if (this[page] > 0) {
           this[page] = this[page] - 1;
         }
       }
       if (x === "+") {
         if (arr) {
           if (((this[page] + 1) * this.displayRows) + this.displayRows - arr.length < this.displayRows) {
             this[page] = this[page] + 1;
           }
         }
       }
     },
     handleDisplayRows() {
       this.pageNumber = 0
     }
 },
  computed : {
    needPrevSeparator : function() {
      if(this.pageNumber >= 2 && Math.ceil(this.data.history.length/this.displayRows) - 1 >= 3) {
        return true
      }
      return false
    },
    needNextSeparator : function() {
      if(((this.pageNumber !== Math.ceil(this.data.history.length/this.displayRows) - 1) && (Math.ceil(this.data.history.length/this.displayRows) - 1 > 2) && (Math.ceil(this.data.history.length/this.displayRows) - 2 !== this.pageNumber))) {
        return true
      }
      return false
    },
    button1 : function() {
      if(this.pageNumber === Math.ceil(this.data.history.length/this.displayRows) - 1 && Math.ceil(this.data.history.length/this.displayRows) - 1 >= 3) {
        return true
      }
      return false
    },
    button2 : function() {
      if(this.pageNumber === 2 && Math.ceil(this.data.history.length/this.displayRows) - 1 === 2) {
        return true
      }
      return false
    },
    button3 : function() {
      if(this.pageNumber > 0) {
        return true
      }
      return false
    },
    button4 : function() {
      if(this.pageNumber < Math.ceil(this.data.history.length/this.displayRows) - 1) {
        return true
      }
      return false
    },
    button5 : function() {
      if(this.pageNumber === 0 && Math.ceil(this.data.history.length/this.displayRows) - 1 >= 2) {
        return true
      }
      return false
    }
  },
 created() {
   this.initPage();
 },
}
</script>

<style scoped>

tr {
  border-bottom: 2px solid lightgray;
  height: 40px;
}

th,
td {
  text-align: left;
  padding-left: 5px;
}

.selected {
  background-color: black;
}

.no-data {
  display: flex;
  width: 100%;
  height: 40px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  background-color: rgb(240, 240, 240);
}

</style>