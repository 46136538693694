<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 class="fade-in" v-if="!loading">File A Claim</h2>
        </div>
          <div class="content">
            <div class="fade-in" v-if="!loading">
              <div class="section-head">
              <h3>Accident/Injury Details</h3>
            </div>
            <div class="rows">
              <div class="row">
                <div class="data-field third">
                  <label
                    :class="form.claimType.error ? 'error-text' : ''"
                    ><span class="red">*</span>Claim Type</label
                  >
                  <select
                    v-model="form.claimType.value"
                    v-on:change="handleValueChange(form.claimType)"
                    :class="form.claimType.error ? 'error-element' : ''"
                  >
                    <option value=null disabled selected hidden>
                      Select Claim Type
                    </option>
                    <option
                      v-for="type in data.claimTypes"
                      :key="type.ID"
                      :value="type.ID"
                    >
                      {{ type.typeDescription }}
                    </option>
                  </select>
                </div>
                <!-- <div class="data-field fourth"/> -->
              <div class="check-box two-thirds">
                    <input
                      type="checkbox"
                      v-model="form.notifyPrimaryContact.value"
                      class="chkBox"
                    />
                    <label
                      ><strong
                        >Please check here to send confirmation email to the
                        Primary Claim Contact on this policy.</strong
                      ></label
                    >
                  </div>
              </div>              
              <div class="row">
              <div class="data-field fourth">
                <label :class="form.beganWorkTime.error ? 'error-text' : ''"><span class="red">*</span>Time employee began work</label>
                <input type="time" placeholder="" :value="form.beganWorkTime.display"  v-on:change="(e) => {
                  e.target.value = formatTime(e.target.value, form.beganWorkTime);
                  handleValueChange(form.beganWorkTime)
                }"
                    :class="form.beganWorkTime.error ? 'error-element' : ''" />
              </div>
              <div class="data-field fourth">
                <label :class="form.timeOfInjury.error ? 'error-text' : ''"><span class="red">*</span>Time of Injury</label>
                <input type="time" placeholder="" :value="form.timeOfInjury.display" v-on:change="(e) => {
                  e.target.value = formatTime(e.target.value, form.timeOfInjury);
                  handleValueChange(form.timeOfInjury)
                }"
                    :class="form.timeOfInjury.error ? 'error-element' : ''"/>
              </div>
              <div class="data-field fourth">
                <label :class="form.dateOfInjury.error ? 'error-text' : ''"><span class="red">*</span>Date of Injury</label>
                <input type="date" placeholder="" v-model="form.dateOfInjury.value" v-on:change="handleValueChange(form.dateOfInjury)"
                    :class="form.dateOfInjury.error ? 'error-element' : ''"/>
              </div>
              <div class="data-field fourth">
                <label :class="form.dateInjuryNotified.error ? 'error-text' : ''"><span class="red">*</span>Date Employer notified of Injury</label>
                <input type="date" placeholder="" v-model="form.dateInjuryNotified.value" v-on:change="handleValueChange(form.dateInjuryNotified)"
                    :class="form.dateInjuryNotified.error ? 'error-element' : ''"/>
              </div>
            </div>
            <div class="row">
              <div class="data-field fourth">
                <label :class="form.dateInjuryAdmNotified.error ? 'error-text' : ''"><span class="red">*</span>Date Claim Admin notified</label>
                <input type="date" placeholder="" v-model="form.dateInjuryAdmNotified.value" v-on:change="handleValueChange(form.dateInjuryAdmNotified)"
                    :class="form.dateInjuryAdmNotified.error ? 'error-element' : ''"/>
              </div>
              <div class="data-field fourth">
                <label :class="form.lastDateWorked.error ? 'error-text' : ''"><span class="red">*</span>Initial Date Last Day Worked</label>
                <input type="date" placeholder="" v-model="form.lastDateWorked.value" v-on:change="handleValueChange(form.lastDateWorked)"
                    :class="form.lastDateWorked.error ? 'error-element' : ''"/>
              </div>
              <div class="data-field fourth">
                <label>Initial Return to Work Date</label>
                <input type="date" placeholder="" v-model="form.dateWorkReturn.value" />
              </div>
              <div class="data-field fourth">
                <label>Employee Date of death (if applicable)</label>
                <input type="date" placeholder="" v-model="form.dateOfDeath.value" />
              </div>
            </div>
            <div class="row">
              <div class="data-field fourth">
                <label>Pre-Existing Disability Code</label>
                <select v-model="form.disabilityCodePE.value">
                  <option value=null disabled selected hidden>Select Code</option>
                  <option
                    v-for="code in data.preExistingDisabilityCodes"
                    :key="code.id"
                    :value="code.dccode"
                  >
                    {{ code.display }}
                  </option>
                </select>
              </div>
              <div class="data-field fourth">
                <label>Accident Premises Code</label>
                <select v-model="form.accidentPremCode.value">
                  <option value=null disabled selected hidden>Select Code</option>
                  <option
                    v-for="code in data.accidentPremisesCodes"
                    :key="code.id"
                    :value="code.dccode"
                  >
                    {{ code.display }}
                  </option>
                </select>
              </div>
              <div class="data-field fourth">
                <label :class="form.AccSiteOrgName.error ? 'error-text' : ''"><span class="red">*</span>Accident Site Organization Name</label>
                <input type="text" placeholder="Enter Organization Name" v-model="form.AccSiteOrgName.value" v-on:change="handleValueChange(form.AccSiteOrgName)"
                    :class="form.AccSiteOrgName.error ? 'error-element' : ''" :maxlength="form.AccSiteOrgName.maxlength"/>
              </div>
              <div class="data-field fourth">
                <label :class="form.accLocNarrative.error ? 'error-text' : ''"><span class="red">*</span>Accident Location Narrative</label>
                <input type="text" placeholder="Enter Code" v-model="form.accLocNarrative.value" v-on:change="handleValueChange(form.accLocNarrative)"
                    :class="form.accLocNarrative.error ? 'error-element' : ''" :maxlength="form.accLocNarrative.maxlength"/>
              </div>
            </div>
            <div class="row">
              <div class="data-field fifth">
                <label :class="form.accidentAddress1.error ? 'error-text' : ''"><span class="red">*</span>Accident Site Street Address</label>
                <input type="text" placeholder="Enter Address Line 1" v-model="form.accidentAddress1.value" v-on:change="handleValueChange(form.accidentAddress1)"
                    :class="form.accidentAddress1.error ? 'error-element' : ''" :maxlength="form.accidentAddress1.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label>Accident Site Address Line 2</label>
                <input type="text" placeholder="Apartment, suite, building, unit, floor, etc." v-model="form.accidentAddress2.value" :maxlength="form.accidentAddress2.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label :class="form.accidentCity.error ? 'error-text' : ''"><span class="red">*</span>Accident Site City</label>
                <input type="text" placeholder="Enter City" v-model="form.accidentCity.value" v-on:change="handleValueChange(form.accidentCity)"
                    :class="form.accidentCity.error ? 'error-element' : ''" :maxlength="form.accidentCity.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label :class="form.accidentState.error ? 'error-text' : ''"><span class="red">*</span>Accident Site State</label>
                <select v-model="form.accidentState.value" v-on:change="handleValueChange(form.accidentState)"
                    :class="form.accidentState.error ? 'error-element' : ''">
                  <option value=null disabled selected hidden>Select State</option>
                  <option
                    v-for="state in data.states"
                    :key="state.ID"
                    :value="state.stateCode"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
              </div>
              <div class="data-field fifth">
                <label :class="form.accidentZip.error ? 'error-text' : ''"><span class="red">*</span>ZIP</label>
                <input type="text" placeholder="Enter Zip Code" :value="form.accidentZip.display" v-on:change="handleValueChange(form.accidentZip)"
                    :class="form.accidentZip.error ? 'error-element' : ''" v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.accidentZip)
                    }"/>
              </div>
            </div>
            <div class="row">
              <div class="data-field half">
                <label :class="form.lossWhere.error ? 'error-text' : ''"
                  ><span class="red">*</span>Describe the nature of the injury</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.lossWhere.value"
                  :maxlength="form.lossWhere.maxlength"
                  v-on:change="handleValueChange(form.lossWhere)"
                    :class="form.lossWhere.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field half">
                <label :class="form.injuryDetails.error ? 'error-text' : ''"
                  ><span class="red">*</span>Part(s) of body directly affected by the injury or illness:</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.injuryDetails.value"
                  :maxlength="form.injuryDetails.maxlength"
                  v-on:change="handleValueChange(form.injuryDetails)"
                    :class="form.injuryDetails.error ? 'error-element' : ''"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field half">
                <label :class="form.lossHow.error ? 'error-text' : ''"
                  ><span class="red">*</span>Describe the events that caused the injury</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.lossHow.value"
                  :maxlength="form.lossHow.maxlength"
                  v-on:change="handleValueChange(form.lossHow)"
                    :class="form.lossHow.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field half">
                <label :class="form.injuryObjectsInvolved.error ? 'error-text' : ''"
                  ><span class="red">*</span>Name the object or substance that directly injured the employee</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.injuryObjectsInvolved.value"
                  :maxlength="form.injuryObjectsInvolved.maxlength"
                  v-on:change="handleValueChange(form.injuryObjectsInvolved)"
                    :class="form.injuryObjectsInvolved.error ? 'error-element' : ''"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field half">
                <label :class="form.activityWhenOccurred.error ? 'error-text' : ''"
                  ><span class="red">*</span>Specify activity the employee was engaged in when the event occurred.</label
                >
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.activityWhenOccurred.value"
                  :maxlength="form.activityWhenOccurred.maxlength"
                  v-on:change="handleValueChange(form.activityWhenOccurred)"
                    :class="form.activityWhenOccurred.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field fourth">
                <label>Witness Name</label>
                <input type="text" placeholder="Enter Witness Name" v-model="form.witnessName.value" :maxlength="form.witnessName.maxlength"/>
              </div>
              <div class="data-field fourth">
                <label>Witness Phone</label>
                <input type="text" :value="form.witnessPhone.display" placeholder="Enter Witness Phone" maxlength="14" v-on:input="
                    (e) => {
                      phoneMaskFormat(
                        e.target.value,
                        form.witnessPhone
                      );
                    }
                  "/>
              </div>
            </div>
            <div class="row">
                <h3 style="margin-left: 1%; margin-bottom: 0.2%;">Employee Assigned Address</h3>  
            </div>
            <div class="row">
              <div class="data-field fifth">
                <label :class="form.assignedAddress1.error ? 'error-text' : ''"><span class="red">*</span>Address 1</label>
                <input type="text" placeholder="Enter Address Line 1" v-model="form.assignedAddress1.value" v-on:change="handleValueChange(form.assignedAddress1)"
                    :class="form.assignedAddress1.error ? 'error-element' : ''" :maxlength="form.assignedAddress1.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label>Address 2</label>
                <input type="text" placeholder="Enter address 2" v-model="form.assignedAddress2.value" :maxlength="form.assignedAddress2.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label :class="form.assignedCity.error ? 'error-text' : ''"><span class="red">*</span>City</label>
                <input type="text" placeholder="Enter City" v-model="form.assignedCity.value" v-on:change="handleValueChange(form.assignedCity)"
                    :class="form.assignedCity.error ? 'error-element' : ''" :maxlength="form.assignedCity.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label :class="form.assignedState.error ? 'error-text' : ''"><span class="red">*</span>State</label>
                <select v-model="form.assignedState.value" v-on:change="handleValueChange(form.assignedState)"
                    :class="form.assignedState.error ? 'error-element' : ''">
                  <option value=null disabled selected hidden>Select State</option>
                  <option
                    v-for="state in data.states"
                    :key="state.ID"
                    :value="state.stateCode"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
              </div>
              <div class="data-field fifth">
                <label :class="form.assignedZip.error ? 'error-text' : ''"><span class="red">*</span>ZIP Code</label>
                <input type="text" placeholder="Enter Zip Code" :value="form.assignedZip.display" v-on:change="handleValueChange(form.assignedZip)"
                    :class="form.assignedZip.error ? 'error-element' : ''" v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.assignedZip)
                    }"/>
              </div>
            </div>
            </div>
            <div class="section-head">
              <h3>Medical Information</h3>
            </div>
            <div class="rows">              
            <div class="row removeMe">
              <div class="bold padded"><label>Initial Treatment Code:</label></div>  
              <div class="check-box">
                <input
                  type="checkbox"
                  v-model="form.initialNone.value"
                  class="chkBox"
                />
                <label>No medical treatment</label>
              </div>
              <div class="check-box">
                <input
                  type="checkbox"
                  v-model="form.initialOnSite.value"
                  class="chkBox"
                />
                <label>Minor/on-site treatment</label>
              </div>
              <div class="check-box">
                <input
                  type="checkbox"
                  v-model="form.initialMinor.value"
                  class="chkBox"
                />
                <label>Clinic/hospital visit</label>
              </div>
              <div class="check-box">
                <input
                  type="checkbox"
                  v-model="form.initialEmergency.value"
                  class="chkBox"
                />
                <label>Emergency care</label>
              </div>
              <div class="check-box">
                <input
                  type="checkbox"
                  v-model="form.initialHospitalized.value"
                  class="chkBox"
                />
                <label>Hospitalization more than 24 hours</label>
              </div>
              <div class="check-box">
                <input
                  type="checkbox"
                  v-model="form.initialFutureMajor.value"
                  class="chkBox"
                />
                <label>Future medical treatment anticipated</label>
              </div>
            </div>
            <div class="row">
              <div class="data-field third">
                <label :class="form.initProviderName.error ? 'error-text' : ''"><span class="red">*</span>Initial Medical Provider Name</label>
                <input type="text" placeholder="Enter Name" v-model="form.initProviderName.value" v-on:change="handleValueChange(form.initProviderName)"
                    :class="form.initProviderName.error ? 'error-element' : ''" :maxlength="form.initProviderName.maxlength"/>
              </div>
              <div class="data-field third">
                <label>Managed Care Organization Name or ID #</label>
                <input type="text" placeholder="Enter Name or ID" v-model="form.mcOrgNameID.value" :maxlength="form.mcOrgNameID.maxlength"/>
              </div>
              <div class="data-field third">
                <label>ICD Primary Diagnostic Code (if known)</label>
                <input type="text" placeholder="Enter Code" v-model="form.icdPrimDiagCode.value" :maxlength="form.icdPrimDiagCode.maxlength"/>
              </div>
            </div>
            <div class="row">
              <div class="data-field fifth">
                <label :class="form.medProviderAddress1.error ? 'error-text' : ''"><span class="red">*</span>Initial Medical Provider Address</label>
                <input type="text" placeholder="Enter Address Line 1" v-model="form.medProviderAddress1.value" v-on:change="handleValueChange(form.medProviderAddress1)"
                    :class="form.medProviderAddress1.error ? 'error-element' : ''" :maxlength="form.medProviderAddress1.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label>Medical Provider Address Line 2</label>
                <input type="text" placeholder="Apartment, suite, building, unit, floor, etc." v-model="form.medProviderAddress2.value" :maxlength="form.medProviderAddress2.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label :class="form.medProviderCity.error ? 'error-text' : ''"><span class="red">*</span>Provider City</label>
                <input type="text" placeholder="Enter City" v-model="form.medProviderCity.value" v-on:change="handleValueChange(form.medProviderCity)"
                    :class="form.medProviderCity.error ? 'error-element' : ''" :maxlength="form.medProviderCity.maxlength"/>
              </div>
              <div class="data-field fifth">
                <label :class="form.medProviderState.error ? 'error-text' : ''"><span class="red">*</span>Provider State</label>
                <select v-model="form.medProviderState.value" v-on:change="handleValueChange(form.medProviderState)"
                    :class="form.medProviderState.error ? 'error-element' : ''">
                  <option value=null disabled selected hidden>Select State</option>
                  <option
                    v-for="state in data.states"
                    :key="state.ID"
                    :value="state.stateCode"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
              </div>
              <div class="data-field fifth">
                <label :class="form.medProviderZip.error ? 'error-text' : ''"><span class="red">*</span>ZIP</label>
                <input type="text" placeholder="Enter Zip Code" :value="form.medProviderZip.display" v-on:change="handleValueChange(form.medProviderZip)"
                    :class="form.medProviderZip.error ? 'error-element' : ''" v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.medProviderZip)
                    }"/>
              </div>
            </div>
            </div>
            <div class="ldBtns">
              <button class="primary back" v-on:click="handlePrevious()">Back</button>
            <button v-on:click="continueClaim()" class="primary">              
              Continue
            </button>
            </div>
            </div>
          </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import { getLossDetailsData, saveLossDetailsFROI, getDecodedToken, saveToken } from "../../Services/claimsService";
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";
import utilities from "../../Services/utilities"

export default {
  data() {
    return {
      form: {
        claimType: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        notifyPrimaryContact: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        beganWorkTime: {
          required: true,
          type: "time",
          value: null,
          error: false,
        },
        timeOfInjury: {
          required: true,
          type: "time",
          value: null,
          error: false,
        },
        dateOfInjury: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        dateInjuryNotified: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        dateInjuryAdmNotified: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        lastDateWorked: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        dateWorkReturn: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        dateOfDeath: {
          required: false,
          type: "date",
          value: null,
          error: false,
        },
        disabilityCodePE: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        accidentPremCode: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        AccSiteOrgName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        accLocNarrative: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        accidentAddress1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        accidentAddress2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        accidentCity: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        accidentState: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        accidentZip: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
        lossWhere: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        injuryDetails: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        lossHow: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        injuryObjectsInvolved: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        activityWhenOccurred: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "2000"
        },
        witnessName: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "12"
        },
        witnessPhone: {
          required: false,
          type: "phone",
          value: null,
          error: false,
        },
        initialNone: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        assignedAddress1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        assignedAddress2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        assignedCity: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        assignedState: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        assignedZip: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
        initialOnSite: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        initialMinor: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        initialEmergency: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        initialHospitalized: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        initialFutureMajor: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        initProviderName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        mcOrgNameID: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        icdPrimDiagCode: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "12"
        },
        medProviderAddress1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        medProviderAddress2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        medProviderCity: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        medProviderState: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        medProviderZip: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
      },
      data: {
        states: [],
        accidentPremisesCodes: [],
        preExistingDisabilityCodes: []
      },
      loading: true
    };
  },
  components: { BaseClaimLayout },
  props: ["lineCode", "policyID"],
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getLossDetailsData(this);
          const data = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!data || !data.success) {
            utilities.showFailureAlert(this, "Could not fetch the loss details.");
      } else {
        const decoded = await getDecodedToken();
        const screenInfo = decoded.IAWCLossDetailsScreenInfo;
      if (screenInfo && screenInfo !== "null" && screenInfo !== "undefined") {
        this.form = screenInfo;
      } else {
        if (data.lossDetailsData && data.fnolData) {
          utilities.handleFormData(data.lossDetailsData, this.form);
          utilities.handleFormData(data.fnolData, this.form);
        }
      }

        this.data = data;
        this.loading = false;
        this.$swal.close();
      }

        },
      });          
    },
    handleValueChange: utilities.handleValueChange,
    formatTime: utilities.formatTime,
    phoneMaskFormat: utilities.phoneMaskFormat,
    numeric: utilities.numeric,
    async saveInformation() {
      await saveToken({IAWCLossDetailsScreenInfo: this.form}, this)
    },
     async handlePrevious() {
      await this.saveInformation();
      this.$router.push({
            name: "MakeClaim",
            params: {},
          });
    },
    async continueClaim() {
      const valid = utilities.validateFields(this.form);
      if (valid) {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await saveLossDetailsFROI(this.form, "IA", this);
          const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not save the loss details.");
        } else {
          await saveToken({IAWCLossDetailsScreenInfo: null}, this);
          await utilities.showSuccessAlert(this, "Loss details saved successsfully.");
        this.$router.push({ name: "IowaWorkersCompClaimantInformation", params: {} });
        }

        },
      });        
      } else {
        utilities.showFailureAlert(this, "Check the errors and try again.", "Missing Required Information!");
      }
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
.removeMe {
  height: 60px;
  display: flex;
  align-items: center;
}

.ldBtns {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}

.ldBtns button {
  width: 175px;
}

.back {
  background: gray;
}
</style>