<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 class="fade-in" v-if="!loading">File A Claim</h2>
        </div>
        <div class="content">
          <div class="fade-in" v-if="!loading">
            <div class="section-head">
            <h3>Claimant Personal Information</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field sixth">
                <label :class="form.firstName.error ? 'error-text' : ''"
                  ><span class="red">*</span>Employee First Name</label
                >
                <input
                  type="text"
                  placeholder="Enter First Name"
                  v-model="form.firstName.value"
                  :class="form.firstName.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.firstName)"
                  :maxlength="form.firstName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label>Employee Middle Name</label>
                <input
                  type="text"
                  placeholder="Enter Middle Name"
                  v-model="form.middleName.value"
                  :maxlength="form.middleName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.lastName.error ? 'error-text' : ''"
                  ><span class="red">*</span>Employee Last Name</label
                >
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  v-model="form.lastName.value"
                  :class="form.lastName.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.lastName)"
                  :maxlength="form.lastName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.dateOfBirth.error ? 'error-text' : ''"
                  ><span class="red">*</span>Date of Birth</label
                >
                <input
                  type="date"
                  placeholder=""
                  v-model="form.dateOfBirth.value"
                  :class="form.dateOfBirth.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.dateOfBirth)"
                />
              </div>
              <div class="data-field sixth">
                <label>SSN</label>
                <input
                  type="text"
                  placeholder="Enter SSN"
                  :value="form.ssn.display"
                  v-on:input="
                    (e) => {
                      form.ssn.display = ssnMaskFormat(e.target.value, form.ssn);
                      e.target.value = ssnMaskFormat(e.target.value, form.ssn);
                    }
                  "
                />
              </div>
              <div class="radio-group sixth">
                <label
                  class="bold whole"
                  :class="form.gender.error ? 'error-text' : ''"
                  ><span class="red">*</span>Gender:</label
                >
                <div class="flex-row whole">
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.gender.value"
                      name="gender"
                      :value="'M'"
                      :class="form.gender.error ? 'error-element' : ''"
                    />
                    <label>Male</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.gender.value"
                      name="gender"
                      :value="'F'"
                      :class="form.gender.error ? 'error-element' : ''"
                    />
                    <label>Female</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="data-field sixth">
                <label :class="form.address1.error ? 'error-text' : ''"
                  ><span class="red">*</span>Address Line 1</label
                >
                <input
                  type="text"
                  placeholder="Enter Address Line 1"
                  v-model="form.address1.value"
                  :class="form.address1.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.address1)"
                  :maxlength="form.address1.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label>Address Line 2</label>
                <input
                  type="text"
                  placeholder="Apartment, suite, building, unit, floor, etc."
                  v-model="form.address2.value"
                  :maxlength="form.address2.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.city.error ? 'error-text' : ''"
                  ><span class="red">*</span>City</label
                >
                <input
                  type="text"
                  placeholder="Enter City"
                  v-model="form.city.value"
                  :class="form.city.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.city)"
                  :maxlength="form.city.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.state.error ? 'error-text' : ''"
                  ><span class="red">*</span>State</label
                >
                <select
                  v-model="form.state.value"
                  :class="form.state.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.state)"
                >
                  <option value=null disabled selected hidden>Select State</option>
                  <option
                    v-for="state in data.states"
                    :key="state.ID"
                    :value="state.stateCode"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
              </div>
              <div class="data-field sixth">
                <label :class="form.zip.error ? 'error-text' : ''"
                  ><span class="red">*</span>ZIP</label
                >
                <input
                  type="number"
                  placeholder="Enter ZIP"
                  :class="form.zip.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.zip)"
                  :value="form.zip.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.zip)
                    }"
                />
              </div>
              <div class="data-field sixth"/>
            </div>
            <div class="row">              
              <div class="data-field third">
                <label :class="form.occupation.error ? 'error-text' : ''"
                  ><span class="red">*</span>Occupation</label
                >
                <input
                  type="text"
                  placeholder="Enter Occupation"
                  v-model="form.occupation.value"
                  :class="form.occupation.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.occupation)"
                  :maxlength="form.occupation.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.dateHired.error ? 'error-text' : ''"><span class="red">*</span>Date of Hire</label>
                <input
                  type="date"
                  placeholder=""
                  v-model="form.dateHired.value"
                  :class="form.dateHired.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.dateHired)"
                />
              </div>
              <div class="data-field sixth">
                <label
                  :class="form.accidentOccurCounty.error ? 'error-text' : ''"
                  ><span class="red">*</span>County Accident Occurred</label
                >
                <input
                  type="text"
                  placeholder="Enter County"
                  v-model="form.accidentOccurCounty.value"
                  :class="form.accidentOccurCounty.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.accidentOccurCounty)"
                  :maxlength="form.accidentOccurCounty.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label
                  :class="form.accidentOccurState.error ? 'error-text' : ''"
                  ><span class="red">*</span>State Accident Occurred In</label
                >
                <select
                  v-model="form.accidentOccurState.value"
                  :class="form.accidentOccurState.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.accidentOccurState)"
                >
                  <option value=null disabled selected hidden>Select State</option>
                  <option
                    v-for="state in data.states"
                    :key="state.ID"
                    :value="state.stateCode"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
              </div>
              <div class="data-field sixth"/>
            </div>
          </div>
          <div class="section-head">
            <h3>Wage Information</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field fifth">
                <label :class="form.wageAtInjury.error ? 'error-text' : ''"
                  ><span class="red">*</span>Wage at the Time of Injury</label
                >
                <input
                  type="text"
                  placeholder="Enter Amount"
                  :class="form.wageAtInjury.error ? 'error-element' : ''"
                  :value="form.wageAtInjury.display"
                  v-on:input="
                    (e) => {
                      e.target.value = moneyMaskFormat(e.target.value, form.wageAtInjury);
                      form.wageAtInjury.display = moneyMaskFormat(e.target.value, form.wageAtInjury);
                      handleValueChange(form.wageAtInjury);
                    }
                  "
                />
              </div>
              <div class="data-field fifth">
                <label :class="form.wageInterval.error ? 'error-text' : ''"
                  ><span class="red">*</span>Specify Payment Interval</label
                >
                <select
                  v-model="form.wageInterval.value"
                  :class="form.wageInterval.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.wageInterval)"
                >
                  <option value=null disabled selected hidden>Select Wage Interval</option>
                  <option
                    v-for="interval in data.wageIntervals"
                    :key="interval.id"
                    :value="interval.dccode"
                  >
                    {{ interval.display }}
                  </option>
                </select>
              </div>
              <div class="three-fifths">
                <label class="bold"
                  >In Addition to Wages, Check Box(es) if Employee
                  Received:</label
                >
                <div class="flex-row">
                  <div class="check-box">
                      <input
                        type="checkbox"
                        v-model="form.wageMeals.value"
                        class="chkBox"
                        :maxlength="form.wageMeals.maxlength"
                      />
                      <label>Meals</label>                  
                  </div>
                  <input
                        class="chk-input"
                        type="number"
                        placeholder="Meals #"
                        :value="form.wageMealsNumber.display"
                        v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.wageMealsNumber)
                    }"
                      />
                  <div class="check-box">
                    <input
                        type="checkbox"
                        v-model="form.wageRooms.value"
                        class="chkBox"
                      />
                      <label>Room</label>                    
                  </div>
                  <input
                    class="chk-input"
                        type="number"
                        placeholder="Days #"
                        :value="form.wageRoomsPerWeek.display"
                        v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.wageRoomsPerWeek)
                    }"
                      />
                  <div class="check-box">
                    <input
                        type="checkbox"
                        v-model="form.wageTips.value"
                        class="chkBox"
                      />
                      <label>Tips</label>                    
                  </div>
                  <input
                    class="chk-input"
                        type="text"
                        placeholder="Amount"
                        :value="form.wageTipsAmountWeek.display"
                        v-on:input="
                    (e) => {
                      e.target.value = moneyMaskFormat(e.target.value, form.wageTipsAmountWeek);
                      form.wageTipsAmountWeek.display = moneyMaskFormat(e.target.value, form.wageTipsAmountWeek);
                    }
                  "
                      />                 
                </div>
              </div>
            </div>
            <div class="row">
              <div class="data-field fifth">
                    <label class="bold"
                      >Worker Paid for Overtime?</label
                    >
                    <div class="flex-row half">
                      <div class="radio-label">
                        <input
                          type="radio"
                          name="paidOvertime"
                          v-model="form.paidOvertime.value"
                          :value="true"
                        />
                        <label>Yes</label>
                      </div>
                      <div class="radio-label">
                        <input
                          type="radio"
                          name="paidOvertime"
                          v-model="form.paidOvertime.value"
                          :value="false"
                        />
                        <label>No</label>
                      </div>
                    </div>
                  </div>
                  <div class="data-field fifth" :class="!form.paidOvertime.value ? 'invisible' : ''">
                <label>If Yes, After How Many Hours?</label>
                <input
                  type="number"
                  placeholder="Enter hours before overtime..."
                  :value="form.hoursBeforeOvertime.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.hoursBeforeOvertime)
                    }"
                />
              </div>
              <div class="three-fifths"/>
            </div>
            <div class="row">
              <p class="data-field fifth bold">
              For 52 Week Period Prior to Week of Injury, Report Below the No.
              of Weeks Worked, Total Wages, Salary, Bonus or Premium Earned:
            </p>
              <div class="data-field fifth">
                <label># of Weeks Worked</label>
                <input
                  type="text"
                  placeholder="Enter Number"
                  :value="form.weeksWorkedBefore.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.weeksWorkedBefore)
                    }"
                />
              </div>
              <div class="data-field fifth">
                <label>Gross Amount w/o Tips</label>
                <input
                  type="text"
                  placeholder="Enter Amount"
                  :value="form.grossAmountBefore.display"
                  v-on:input="
                    (e) => {
                      e.target.value = moneyMaskFormat(e.target.value, form.grossAmountBefore);
                      form.grossAmountBefore.display = moneyMaskFormat(e.target.value, form.grossAmountBefore);
                    }
                  "
                />
              </div>
              <div class="data-field fifth">
                <label>If Piece-Work, hours Excl Overtime</label>
                <input
                  type="number"
                  placeholder="Enter Hours"
                  :value="form.pwHoursExclOvertime.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.pwHoursExclOvertime)
                    }"
                />
              </div>
              <div class="data-field fifth">
                <label>Employee Usual Start Time</label>
                <input
                  type="time"
                  placeholder=""
                  :value="form.emplStartTime.display"
                  v-on:change="(e) => {
                    e.target.value = formatTime(e.target.value, form.emplStartTime);
                  }"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field half bold centered">
                <p>
                Employee's Usual Work Schedule When Injured:
              </p>
              </div>              
              <div class="data-field sixth">
                <label
                  :class="form.emplWorkSchedPerDay.error ? 'error-text' : ''"
                  ><span class="red">*</span>Hours Per Day</label
                >
                <input
                  type="number"
                  placeholder="Enter Hours"
                  :class="form.emplWorkSchedPerDay.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.emplWorkSchedPerDay)"
                  :value="form.emplWorkSchedPerDay.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.emplWorkSchedPerDay)
                    }"
                />
              </div>
              <div class="data-field sixth">
                <label
                  :class="form.emplWorkSchedPerWeek.error ? 'error-text' : ''"
                  ><span class="red">*</span>Hours per Week</label
                >
                <input
                  type="number"
                  placeholder="Enter Hours"
                  :class="
                    form.emplWorkSchedPerWeek.error ? 'error-element' : ''
                  "
                  v-on:change="handleValueChange(form.emplWorkSchedPerWeek)"
                  :value="form.emplWorkSchedPerWeek.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.emplWorkSchedPerWeek)
                    }"
                />
              </div>
              <div class="data-field sixth">
                <label
                  :class="form.emplWorkSchedDaysPW.error ? 'error-text' : ''"
                  ><span class="red">*</span>Days per Week</label
                >
                <input
                  type="text"
                  placeholder="Enter Days"
                  :class="form.emplWorkSchedDaysPW.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.emplWorkSchedDaysPW)"
                  :value="form.emplWorkSchedDaysPW.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.emplWorkSchedDaysPW)
                    }"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field half centered">
                <p class="bold">
                Employer's Usual Full-Time Schedule for This Type of Work at the
                time of Employee's Injury:
              </p>
              </div>
              <div class="data-field sixth">
                <label>Hours Per Day</label>
                <input
                  type="text"
                  placeholder="Enter Hours"
                  :value="form.empWorkSchedPerDay.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.empWorkSchedPerDay)
                    }"
                />
              </div>
              <div class="data-field sixth">
                <label>Hours per Week</label>
                <input
                  type="text"
                  placeholder="Enter Hours"
                  :value="form.empWorkSchedPerWeek.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.empWorkSchedPerWeek)
                    }"
                />
              </div>
              <div class="data-field sixth">
                <label>Days per Week</label>
                <input
                  type="text"
                  placeholder="Enter Days"
                  :value="form.empWorkSchedDaysPW.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.empWorkSchedDaysPW)
                    }"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field fifth">
                <label>Part-Time Employment Information</label>
                <input
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.partTimeEmplInfo.value"
                  :maxlength="form.partTimeEmplInfo.maxlength"
                />
              </div>
              <div class="radio-group two-fifths">
                <label class="bold"
                  >Other Part-Time Employees Doing Same Work with Same
                  Schedule?</label
                >
                <div class="flex-row whole">
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.otherPTEmployees.value"
                      name="otherPTEmployees"
                      :value="true"
                      :class="form.otherPTEmployees.error ? 'error-element' : ''"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.otherPTEmployees.value"
                      name="otherPTEmployees"
                      :value="false"
                      :class="form.otherPTEmployees.error ? 'error-element' : ''"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
              <div class="data-field fifth">
                <label v-if="form.otherPTEmployees.value === true">How Many?</label>
                <input
                v-if="form.otherPTEmployees.value === true"
                  type="number"
                  placeholder="Enter Number"
                  :value="form.otherPTEmployeesNum.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.otherPTEmployeesNum)
                    }"
                />
              </div>
              <div class="data-field fifth">
                <label>FTEs Doing Same Type of Work</label>
                <input
                  type="text"
                  placeholder="Enter Number"
                  :value="form.fullTimeEmployeesNum.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.fullTimeEmployeesNum)
                    }"
                />
              </div>
            </div>
          </div>
          <Attachments @save-information="saveInformation"/>
          <div class="ldBtns">
              <button class="primary back" v-on:click="handlePrevious()">Back</button>
          <button class="primary" v-on:click="continueClaim()">Submit Claim</button>
          </div>
          </div>
        </div>
        <dashboard-modal
          :uppy="uppy"
          :open="open"
          :props="{
            onRequestCloseModal: handleUploader,
          }"
        />
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import {
  saveFROIClaimant,
  getFROIClaimantData,
  getDecodedToken,
  saveToken
} from "../../Services/claimsService";
import { saveDefaultClaimState } from "../../Services/apiService";
import utilities from "../../Services/utilities"
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";
import Attachments from "../Partials/Attachments.vue"

export default {
  data() {
    return {
      form: {
        firstName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        middleName: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        lastName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        dateOfBirth: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        ssn: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        gender: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        address1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        address2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        city: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        state: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        zip: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
        occupation: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "120"
        },
        dateHired: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        accidentOccurCounty: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        accidentOccurState: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        wageAtInjury: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxvalue: 9999999.99
        },
        wageInterval: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        wageMeals: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        wageMealsNumber: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        wageRooms: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        wageRoomsPerWeek: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        wageTips: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        wageTipsAmountWeek: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxvalue: 9999.99
        },
        paidOvertime: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        hoursBeforeOvertime: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        weeksWorkedBefore: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        grossAmountBefore: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxvalue: 9999.99
        },
        pwHoursExclOvertime: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        emplStartTime: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        emplWorkSchedPerDay: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 24
        },
        emplWorkSchedPerWeek: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 168
        },
        emplWorkSchedDaysPW: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 7
        },
        empWorkSchedPerDay: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 24
        },
        empWorkSchedPerWeek: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 168
        },
        empWorkSchedDaysPW: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 7
        },
        partTimeEmplInfo: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: 150
        },
        otherPTEmployees: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        otherPTEmployeesNum: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        fullTimeEmployeesNum: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
      },
      data: {
        states: [],
        wageIntervals: [],
      },
      loading: true
    };
  },
  components: { BaseClaimLayout, Attachments },
  props: ["lineCode", "policyID"],
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getFROIClaimantData();
      const data = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!data || !data.success) {
            utilities.showFailureAlert(this, "Could not save the claimant information.");
      } else {
        this.data = data;

        const decoded = await getDecodedToken();

        const screenInfo = decoded.WIWCClaimantInfoScreenInfo;

        if (screenInfo && screenInfo !== "null" && screenInfo !== "undefined") {
        this.form = screenInfo;
      }

      this.loading = false;
      this.$swal.close();
      }

        },
      });      
    },
    async continueClaim() {

      const valid = utilities.validateFields(this.form);
      if (valid) {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await saveFROIClaimant(this.form, this);
        const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not save the claimant information.");
        } else {
          await utilities.showSuccessAlert(this, "Claimant information saved successsfully.");        

      await saveDefaultClaimState();

          this.$router.push({
            name: "FinishClaim",
            params: {},
          });
        }
        },
      });        
      } else {
        utilities.showFailureAlert(this, "Check the errors and try again.", "Missing Required Information!");
      }
    },
    handleValueChange: utilities.handleValueChange,
    ssnMaskFormat: utilities.ssnMaskFormat,
    moneyMaskFormat: utilities.moneyMaskFormat,
    formatTime: utilities.formatTime,
    numeric: utilities.numeric,
    async saveInformation() {
      await saveToken({WIWCClaimantInfoScreenInfo: this.form}, this);
    },
    async handlePrevious() {
      await this.saveInformation();
      this.$router.push({
            name: "WisconsinWorkersCompLossDetails",
            params: {},
          });
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
.centered {
  /* align-items: center;
  text-align: center; */
  justify-content: center;
}

.radio-group {
 flex-direction: column;
}

.ldBtns {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}

.ldBtns button {
  width: 175px;
}

.back {
  background: gray;
}
</style>