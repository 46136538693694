<template>
  <BaseAccountLayout>
    <form @submit.prevent="loginUser" class="fade-in">
      <div id="welcome-container">
        <div id="field-container">
        <h3>Welcome</h3>
        <router-link to="/login">
        <button type="submit" class="btn-link">Login</button>
        </router-link>
        <h3>Report a Claim</h3>
        <a class="a-link" v-on:click="makeClaims(`NIT`)"
          >Nonprofit Insurance Trust</a
        >
        <a class="a-link" v-on:click="makeClaims(`NPIG`)"
          >NP Insurance Group</a
        >
      </div>
      <div id="logo-container">
        <img
          src="./../../assets/company-logo.png"
        />
      </div>
      </div>
    </form>
  </BaseAccountLayout>
</template>

<script>
import BaseAccountLayout from "./../../Layouts/BaseAccountLayout.vue";

export default {
  components: { BaseAccountLayout },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async initPage() {
      let token = (this.$route.params.token) ? this.$route.params.token : '';
      if (token !== '') {
        this.$router.push({ path: `/forgottenPassword/${token}` });
      }
    },
    makeClaims(entityCode) {
      localStorage.setItem("entityCode", entityCode);
      this.$router.push({
        name: "MakeClaim",
        params: {},
      });
    }
  },
  created() {
    this.initPage();
  }
};
</script>

<style lang="less" scoped>
form {
  text-align: center;
  width: 100%;

  h3 {
    font-size: 35px;
    margin-bottom: 10px;
  }

  #welcome-container {
    display: flex;
    flex-direction: row;
    height: 300px;
  }

  #logo-container {
    margin-top: 100px;
  }

  #field-container {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-left: 25%;
    margin-right: 5%;
    margin-top: 50px;

    input {
      height: 40px;
    }

    button {
      height: 40px;
      // width: 150px;
      width: 60%;
      // margin-bottom: 20px;
    }

    img {
      width: 20%;
      margin-left: 5%;
      height: 250px;
    }

  }
}

a,
a:link,
a:visited,
a:active {
  padding: 5px 10px;
  font-size: 16px;
}
</style>
