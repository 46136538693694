import { createRouter, createWebHistory } from "vue-router";
import routes from "./Views/routes";
import { api, saveDefaultState, saveDefaultClaimState } from "./Services/apiService";
import { getDecodedToken } from "./Services/claimsService";
import { userHasRight, getDecodedAuthToken } from "./Services/authService"

const router = createRouter({
  history: createWebHistory("/company/customer-portal/"),
  // linkActiveClass: "active-section",
  // linkExactActiveClass: "active-page",
  routes,
});

async function handlePolicyRoute(next, to) {
  const decoded = await getDecodedAuthToken();
  const policyID = decoded?.policyID;
  const requiresPolicyID = to.matched.some((record) => record.meta.requiresPolicyID);
  const rights = to.matched[0].meta.rights;

  let hasAuth = true;
  let hasPolicyID = true;
  let hasScreenRights = true;

  if (!decoded || JSON.stringify(decoded) === "{}") {
    hasAuth = false;
  }

  if (requiresPolicyID && (!policyID || policyID === "" || policyID === 0 || policyID === "0")) {
    hasPolicyID = false;
  }

  if (rights) {
    const hasRights = await userHasRight(rights);
    hasScreenRights = hasRights;
  }

  if (hasAuth && (!hasPolicyID || !hasScreenRights)) {
    next("/policySelect");
  }
  if (!hasAuth) {
    await saveDefaultState();
    next("/");
  }
  if (hasAuth && hasPolicyID && hasScreenRights) {
    next();
  }
};

async function handleClaimRoute(next, to) {
  const decoded = await getDecodedToken();
  const fnolID = decoded?.fnolID;
  const rights = to.matched[0].meta.rights;

  let hasAuth = true;
  let hasFnolID = true;
  let hasScreenRights = true;

  if (!decoded) {
    hasAuth = false;
  }

  if (!fnolID || fnolID === "" || fnolID === 0 || fnolID === "0") {
    hasFnolID = false;
  }

  if (rights) {
    const sessionRights = decoded?.rights || [];

    for (const right of rights) {
      if (!sessionRights.includes(right)) {
        hasScreenRights = false;
      }
    }
  }

  if (hasAuth && (!hasFnolID || !hasScreenRights)) {
    next("/claims");
  }
  if (!hasAuth) {
    await saveDefaultClaimState();
    next("/");
  }
  if (hasAuth && hasFnolID && hasScreenRights) {
    next();
  }
};

router.beforeEach(async (to, _from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresClaimSession = to.matched.some((record) => record.meta.requiresClaimSession);

  if (requiresAuth) {
    await handlePolicyRoute(next, to);
  }

  else if (requiresClaimSession) {
    await handleClaimRoute(next, to);
  }

  else {
    next();
  }
});

export default router;
