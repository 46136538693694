import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);

import store from "./Services/Store/store";
app.use(store);

import router from "./router";
app.use(router);

import BaseLayout from "./Layouts/BaseLayout.vue";
app.component("BaseLayout", BaseLayout);

import VueSweetalert2 from 'vue-sweetalert2';
app.use(VueSweetalert2)

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faUserSecret, faWallet, faCreditCard, faFolderOpen, faClockRotateLeft, faComment, faMagnifyingGlass, faEnvelopeOpen, faArrowUpFromBracket, faCrosshairs, faInfoCircle, faPenToSquare, faTrash, faFileImage, faCircleXmark, faChevronLeft, faChevronRight, faCaretDown, faCaretUp, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faUserSecret, faWallet, faCreditCard, faFolderOpen, faClockRotateLeft, faComment, faMagnifyingGlass, faEnvelopeOpen, faArrowUpFromBracket, faCrosshairs, faInfoCircle, faPenToSquare, faTrash, faFileImage, faCircleXmark, faChevronLeft, faChevronRight, faCaretDown, faCaretUp, faArrowLeft);

/* add font awesome icon component */
app.component('font-awesome-icon', FontAwesomeIcon)
// app.use(FontAwesomeIcon)

// TODO: Wire in jquery
// import jQuery from "jquery";
// window.$ = window.jQuery = jQuery;
// global.$ = jQuery;
// app.use({
//   install: function (Vue, options) {
//     Vue.prototype.$jQuery = jQuery; // you'll have this.$jQuery anywhere in your vue project
//   },
// });

router.isReady().then(() => app.mount("#app"));
