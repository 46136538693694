// Account Pages
import Welcome from "./Account/Welcome.vue";
import LoginPage from "./Account/LoginPage.vue";
import ForgotPasswordPage from "./Account/ForgotPasswordPage.vue";
import ResetPassword from "./Account/ResetPassword.vue";

// PolicyInfo Pages
import SummaryPage from "./PolicyInfo/SummaryPage.vue";
import BillingDetails from "./PolicyInfo/BillingDetails.vue";
import PolicyDocuments from "./PolicyInfo/PolicyDocuments.vue"
import EndorsementDocuments from "./PolicyInfo/EndorsementDocuments.vue"
import Certificates from "./PolicyInfo/Certificates.vue"
import CreateCertificate from "./PolicyInfo/CreateCertificate.vue";
import HistoryList from "./PolicyInfo/HistoryList.vue";
import PolicySelect from "./PolicyInfo/PolicySelect.vue";
import MakeClaim from "./Claims/MakeClaim.vue";
import FinishClaim from "./Claims/FinishClaim.vue"
import LossDetails from "./Claims/LossDetails.vue";
import WorkersCompLossDetails from "./Claims/WorkersCompLossDetails.vue";
import WisconsinWorkersCompLossDetails from "./Claims/WisconsinWorkersCompLossDetails.vue";
import IowaWorkersCompLossDetails from "./Claims/IowaWorkersCompLossDetails.vue";
import WorkersCompClaimantInformation from "./Claims/WorkersCompClaimantInformation.vue";
import WisconsinWorkersCompClaimantInformation from "./Claims/WisconsinWorkersCompClaimantInformation.vue";
import IowaWorkersCompClaimantInformation from "./Claims/IowaWorkersCompClaimantInformation.vue";
import ClaimList from "./Claims/ClaimList.vue";
import EditAttachment from "./Claims/EditAttachment.vue"

const routes = [
  { path: "/", name: "WelcomePage", component: Welcome },
  { path: "/login", name: "LoginPage", component: LoginPage },
  {
    path: "/forgotPassword",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { requiresAuth: true, rights: ["allowPasswordReset"] },
  },
  {
    path: "/forgottenPassword/:token?",
    name: "ResetPassword",
    component: ResetPassword,
    params: true
  },
  { path: "/fp/:token?", name: "FP", component: Welcome, params: true },
  { path: "/vc/:token?", name: "vc", component: LoginPage, params: true },
  {
    path: "/summary",
    name: "SummaryPage",
    component: SummaryPage,
    meta: { requiresAuth: true, requiresPolicyID: true },
    params: true
  },
  {
    path: "/billing",
    name: "BillingDetails",
    component: BillingDetails,
    meta: { requiresAuth: true, rights: ["allowBillingAccess"], requiresPolicyID: true },
    params: true
  },
  {
    path: "/policyDocuments",
    name: "PolicyDocuments",
    component: PolicyDocuments,
    meta: { requiresAuth: true, rights: ["allowDocumentsAccess"], requiresPolicyID: true },
    params: true
  },
  {
    path: "/endorsementDocuments",
    name: "EndorsementDocuments",
    component: EndorsementDocuments,
    meta: { requiresAuth: true, rights: ["allowDocumentsAccess"], requiresPolicyID: true },
    params: true
  },
  {
    path: "/certificates",
    name: "Certificates",
    component: Certificates,
    meta: { requiresAuth: true, rights: ["allowDocumentsAccess"], requiresPolicyID: true },
    params: true
  },
  {
    path: "/audits",
    name: "Audits",
    component: EndorsementDocuments,
    meta: { requiresAuth: true, rights: ["allowDocumentsAccess"], requiresPolicyID: true },
    params: true
  },
  {
    path: "/history",
    name: "HistoryList",
    component: HistoryList,
    meta: { requiresAuth: true, requiresPolicyID: true },
    params: true
  },
  {
    path: "/claims",
    name: "MakeClaim",
    component: MakeClaim,
    props: true
  },
  {
    path: "/finishclaim",
    name: "FinishClaim",
    component: FinishClaim,
    props: true
  },
  {
    path: "/lossdetails",
    name: "LossDetails",
    component: LossDetails,
    meta: { rights: ["ALLOW_FNOL"], requiresClaimSession: true },
    props: true
  },
  {
    path: "/workerscomplossdetails",
    name: "WorkersCompLossDetails",
    component: WorkersCompLossDetails,
    meta: { rights: ["ALLOW_FROI_MN_LD"], requiresClaimSession: true },
    props: true
  },
  {
    path: "/wisconsinworkerscomplossdetails",
    name: "WisconsinWorkersCompLossDetails",
    component: WisconsinWorkersCompLossDetails,
    meta: { rights: ["ALLOW_FROI_WI_LD"], requiresClaimSession: true },
    props: true
  },
  {
    path: "/iowaworkerscomplossdetails",
    name: "IowaWorkersCompLossDetails",
    component: IowaWorkersCompLossDetails,
    meta: { rights: ["ALLOW_FROI_IA_LD"], requiresClaimSession: true },
    props: true
  },
  {
    path: "/workerscompclaimantinformation",
    name: "WorkersCompClaimantInformation",
    component: WorkersCompClaimantInformation,
    meta: { rights: ["ALLOW_FROI_MN_CI"], requiresClaimSession: true },
    props: true
  },
  {
    path: "/wisconsinworkerscompclaimantinformation",
    name: "WisconsinWorkersCompClaimantInformation",
    component: WisconsinWorkersCompClaimantInformation,
    meta: { rights: ["ALLOW_FROI_WI_CI"], requiresClaimSession: true },
    props: true
  },
  {
    path: "/iowaworkerscompclaimantinformation",
    name: "IowaWorkersCompClaimantInformation",
    component: IowaWorkersCompClaimantInformation,
    meta: { rights: ["ALLOW_FROI_IA_CI"], requiresClaimSession: true },
    props: true
  },
  {
    path: "/editattachment",
    name: "EditAttachment",
    component: EditAttachment,
    meta: { requiresClaimSession: true },
    props: true,
    params: true,
  },
  {
    path: "/claimList",
    name: "ClaimList",
    component: ClaimList,
    meta: { requiresAuth: true, rights: ["allowClaimsAccess"] },
    params: true
  },

  {
    path: "/policySelect",
    name: "PolicySelect",
    component: PolicySelect,
    meta: { requiresAuth: true },
    params: true
  },
  {
    path: "/createcertificate",
    name: "CreateCertificate",
    component: CreateCertificate,
    meta: { requiresAuth: true, requiresPolicyID: true},
    params: true
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
  },
];

export default routes;
