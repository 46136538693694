import Vuex from "vuex";
import authModule from "./authModule";

const store = new Vuex.Store({
  modules: {
    auth: authModule,
  },
});

export default store;
