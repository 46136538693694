const authModule = {
  namespaced: true,
  state: () => ({
    token: null,
    authToken: null,
    session: null,
    policyID: null,
    userID: null,
    policies: null,
    organizationName: "",
    employeeName: "",
    userRights: null,
    fnolLineCode: null,
    fnolID: null,
    lobCode: null,
    entityCode: null,
    isImported: false,
    loading: false,
    screenInfo: null,
    makeClaimScreenInfo: null,
    lossDetailsScreenInfo: null,
    MNWCLossDetailsScreenInfo: null,
    IAWCLossDetailsScreenInfo: null,
    WIWCLossDetailsScreenInfo: null,
    MNWCClaimantInfoScreenInfo: null,
    IAWCClaimantInfoScreenInfo: null,
    WIWCClaimantInfoScreenInfo: null,
    collapsed: false,
    showSublist: false
  }),
  mutations: {
    saveAuthToken(state, authToken) {
      state.authToken = authToken;
    },
    saveToken(state, token) {
      state.token = token;
    },
    savePolicyID(state, policyID) {
      state.policyID = policyID;
    },
    saveSession(state, session) {
      state.session = session;
    },
    saveUserRights(state, userRights) {
      state.userRights = userRights;
    },
    savePolicies(state, policies) {
      state.policies = policies;
    },
    saveOrganizationName(state, organizationName) {
      state.organizationName = organizationName;
    },
    saveEmployeeName(state, employeeName) {
      state.employeeName = employeeName;
    },
    saveFnolLineCode(state, fnolLineCode) {
      state.fnolLineCode = fnolLineCode;
    },
    saveFnolID(state, fnolID) {
      state.fnolID = fnolID;
    },
    saveUserID(state, userID) {
      state.userID = userID;
    },
    saveLobCode(state, lobCode) {
      state.lobCode = lobCode;
    },
    saveEntityCode(state, entityCode) {
      state.entityCode = entityCode;
    },
    saveIsImported(state, isImported) {
      state.isImported = isImported;
    },
    saveScreenInfo(state, screenInfo) {
      state.screenInfo = screenInfo;
    },
    saveMakeClaimScreenInfo(state, makeClaimScreenInfo) {
      state.makeClaimScreenInfo = makeClaimScreenInfo;
    },
    saveLossDetailsScreenInfo(state, lossDetailsScreenInfo) {
      state.lossDetailsScreenInfo = lossDetailsScreenInfo;
    },
    saveMNWCLossDetailsScreenInfo(state, MNWCLossDetailsScreenInfo) {
      state.MNWCLossDetailsScreenInfo = MNWCLossDetailsScreenInfo;
    },
    saveIAWCLossDetailsScreenInfo(state, IAWCLossDetailsScreenInfo) {
      state.IAWCLossDetailsScreenInfo = IAWCLossDetailsScreenInfo;
    },
    saveWIWCLossDetailsScreenInfo(state, WIWCLossDetailsScreenInfo) {
      state.WIWCLossDetailsScreenInfo = WIWCLossDetailsScreenInfo;
    },
    saveMNWCClaimantInfoScreenInfo(state, MNWCClaimantInfoScreenInfo) {
      state.MNWCClaimantInfoScreenInfo = MNWCClaimantInfoScreenInfo;
    },
    saveIAWCClaimantInfoScreenInfo(state, IAWCClaimantInfoScreenInfo) {
      state.IAWCClaimantInfoScreenInfo = IAWCClaimantInfoScreenInfo;
    },
    saveWIWCClaimantInfoScreenInfo(state, WIWCClaimantInfoScreenInfo) {
      state.WIWCClaimantInfoScreenInfo = WIWCClaimantInfoScreenInfo;
    },
    saveCollapsed(state, collapsed) {
      state.collapsed = collapsed;
    },
    saveShowSublist(state, showSublist) {
      state.showSublist = showSublist;
    },
  },
  getters: {
    getAuthToken(state) {
      return state.authToken;
    },
    getToken(state) {
      return state.token;
    },
    getSession(state) {
      return state.session;
    },
    getPolicyID(state) {
      return state.policyID;
    },
    getUserRights(state) {
      return state.userRights;
    },
    getPolicies(state) {
      return state.policies;
    },
    getHeaderData(state) {
      return state.headerData;
    },
    getFnolLineCode(state) {
      return state.fnolLineCode;
    },
    getLobCode(state) {
      return state.lobCode;
    },
    getFnolID(state) {
      return state.fnolID;
    },
    getUserID(state) {
      return state.userID;
    },
    getIsImported(state) {
      return state.isImported;
    },
    getScreenInfo(state) {
      return state.screenInfo;
    },
    getCollapsed(state) {
      return state.collapsed;
    },
    getShowSublist(state) {
      return state.showSublist;
    },
    getOrganizationName(state) {
      return state.organizationName;
    },
    getEmployeeName(state) {
      return state.employeeName;
    },
  },
};

export default authModule;
