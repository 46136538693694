<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title"/>
        <div class="content">
          <div class="fade-in">
            <div class="center">
            <h3>Thank you! Your claim has been successfully submitted.</h3>
          </div>
          <hr />
          <div class="center">
            <button class="primary" v-on:click="backToHome()">Back to Home Page</button>
          </div>
          </div>
        </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import { saveDefaultClaimState } from "../../Services/apiService";
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";

export default {
  data() {
    return {
    };
  },
  components: { BaseClaimLayout },
  methods: {
    async initPage() {
      await saveDefaultClaimState();
    },
    async backToHome() {
      await saveDefaultClaimState();
      this.$router.push({
                name: "WelcomePage",
                params: {},
              });
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
hr {
  margin: 25px 0;
}

.center {
  text-align: center;
  width: 100%;
}

</style>