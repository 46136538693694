<template>
  <BaseLayout @selectedTermChanged="initPage()">
    <template #content>
      <article>        
        <div v-if="pdf" class="pdf-viewer">
          <PDFViewer :pdf="pdf" v-if="pdf !== null"/>
          <div class="whole">
            <button v-on:click="closeViewer()" class="primary iframe-btn">Close Viewer</button>
            </div>    
        </div>
        <div class="policy-background">
          <div class="title">
            <h2 class="fade-in" v-if="!loading">DOCUMENTS</h2>
          </div>
          <div class="policy-content">
            <div v-if="!loading" class="container fade-in">
              <div class="section-head">
                <h3>Policy Documents</h3>
              </div>
              <div class="rows">
                <table id="policyDocumentsTable" class="striped-table">
                  <thead>
                    <tr>
                      <th class="checkbox-column center">
                        <input
                          type="checkbox"
                          v-on:change="checkAll()"
                          id="checkAllID"
                        />
                      </th>
                      <th>Document</th>
                      <th class="page-count-column center">Page Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="data.mergedAutoIDs">
                      <td class="checkbox-column">
                        <input
                          type="checkbox"
                          id="autoIDsCheckAll"
                          :data-documentFile="data.mergedAutoIDs.documentFile"
                        />
                      </td>
                      <td>
                        <a
                          v-on:click="
                            printMergedReport(data.mergedAutoIDs.documentFile)
                          "
                          class="a-link"
                          >Automobile ID Cards</a
                        >
                      </td>
                      <td class="page-count-column">
                        {{ data.mergedAutoIDs.pageCount || "N/A" }}
                      </td>
                    </tr>
                    <tr v-if="data.mergedPolicyDocuments">
                      <td class="checkbox-column">
                        <input
                          type="checkbox"
                          id="policyDocsCheckAll"
                          :data-documentFile="
                            data.mergedPolicyDocuments.documentFile
                          "
                        />
                      </td>
                      <td>
                        <a
                          v-on:click="
                            printMergedReport(
                              data.mergedPolicyDocuments.documentFile
                            )
                          "
                          class="a-link"
                          >Policy Documents</a
                        >
                      </td>
                      <td class="page-count-column">
                        {{ data.mergedPolicyDocuments.pageCount || "N/A" }}
                      </td>
                    </tr>
                    <tr v-if="data.mergedAllocationReports">
                      <td class="checkbox-column">
                        <input
                          type="checkbox"
                          id="allocRepsCheckAll"
                          :data-documentFile="
                            data.mergedAllocationReports.documentFile
                          "
                        />
                      </td>
                      <td>
                        <a
                          v-on:click="
                            printMergedReport(
                              data.mergedAllocationReports.documentFile
                            )
                          "
                          class="a-link"
                          >Allocation Reports</a
                        >
                      </td>
                      <td class="page-count-column">
                        {{ data.mergedAllocationReports.pageCount || "N/A" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="striped-no-data" v-if="!data.mergedAutoIDs && !data.mergedPolicyDocuments && !data.mergedAllocationReports">
                  <h3>No Data</h3>
                </div>
                <div class="pagination" v-if="data.mergedAutoIDs || data.mergedPolicyDocuments || data.mergedAllocationReports">
                  <button
                    class="primary"
                    v-on:click="printSelectedPolicyDocs()"
                  >
                    Print Selected Documents
                  </button>
                  <div class="pagination-buttons">
                    <button
                      v-on:click="handlePageNumber('-')"
                      class="next-buttons"
                    >
                      Previous
                    </button>
                    <button class="page-buttons selected">1</button>
                    <button
                      v-on:click="handlePageNumber('+')"
                      class="next-buttons"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </BaseLayout>
</template>

<script>
import { policyDocuments, download } from "./../../Services/customerService";
import PDFViewer from "../Partials/PDFViewer.vue";
import utilities from "../../Services/utilities"

export default {
  data() {
    const data = {
      mergedAutoIDs: {},
      mergedPolicyDocuments: {},
      mergedAllocationReports: {},
    };
    return {
      data,
      pdf: null,
      loading: true
    };
  },
  components: { PDFViewer },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await policyDocuments(this);
      const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not fetch the policy documents.");
        } else {
          this.data = srvData;
          this.loading = false;
          this.$swal.close();
        }

        },
      });
    },
    checkAll() {
      const chkAll = document.getElementById("checkAllID");
      const polDocs = document.getElementById("policyDocsCheckAll");
      const autodocs = document.getElementById("autoIDsCheckAll");
      const allocReps = document.getElementById("allocRepsCheckAll");
      if (chkAll.checked) {
        if (polDocs) polDocs.checked = true;
        if (autodocs) autodocs.checked = true;
        if (allocReps) allocReps.checked = true;
      } else {
        if (polDocs) polDocs.checked = false;
        if (autodocs) autodocs.checked = false;
        if (allocReps) allocReps.checked = false;
      }
    },
    async printMergedReport(path, print = false) {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await download("policybill", [path], print);
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!res.success) {
            utilities.showFailureAlert(this, "Could not generate the document.");
      } else {

        if (print) {
          res.link.click();
        } else {
          this.pdf = res.path;
        }
        
        this.$swal.close();
      }

        },
      });
    },
    printSelectedPolicyDocs() {
      const polDocs = document.getElementById("policyDocsCheckAll");
      const autodocs = document.getElementById("autoIDsCheckAll");
      const allocReps = document.getElementById("allocRepsCheckAll");

      if (!polDocs.checked && !autodocs.checked && !allocReps.checked) {
        utilities.showInfoAlert(this, "Please select at least one document.")
      } else {
        if (polDocs && polDocs.checked) {
          this.printMergedReport(polDocs.getAttribute("data-documentFile"), true);
        }
        if (autodocs && autodocs.checked) {
          this.printMergedReport(autodocs.getAttribute("data-documentFile"), true);
        }
        if (allocReps && allocReps.checked) {
          this.printMergedReport(allocReps.getAttribute("data-documentFile"), true);
        }
      }
    },
    closeViewer() {
      this.pdf = null;
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
.buttons-container {
  width: 100%;
  text-align: center;
  height: auto;
}

.buttons-container button {
  width: 175px;
}

.primary {
  box-shadow: none;
}

.a-link {
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}

.selected {
  background-color: black;
}
</style>