<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
@import "./assets/index.css";
@import "./assets/master.css";
</style>