<template>
  <BaseAccountLayout>
    <form @submit.prevent="loginUser" class="fade-in">
      <div id="login-container">
        <div id="field-container">
          <div class="flex-row">
            <div style="width: 100px; text-align: left">
              <router-link class="a-link" :to="{ name: 'WelcomePage' }"><span class="icon">
                <font-awesome-icon icon="arrow-left"/>
              </span></router-link>
            </div>
            <h3 style="color: #0b5394">Login</h3>
            <div style="width: 100px; height: 10px"></div>
          </div>
          <input type="text" placeholder="Username" class="form-control" v-model="username" />
          <input type="password" placeholder="Password" class="form-control" v-model="password"
            autocomplete="current-password" />
          <button type="submit" class="btn-link">Login</button>
          <br />
          <router-link class="a-link" :to="{ name: 'ForgotPasswordPage' }">Forgot Password?</router-link>
        </div>
        <div id="logo-container">
          <img src="./../../assets/company-logo.png" />
        </div>
      </div>
    </form>
  </BaseAccountLayout>
</template>

<script>
import BaseAccountLayout from "./../../Layouts/BaseAccountLayout.vue";
import { login, verifyEmailMFA} from "./../../Services/authService";
import { mapState } from "vuex";
import utilities from "../../Services/utilities"

export default {
  components: { BaseAccountLayout },
  data() {
    return {
      username: "",
      password: "",
      redirect: false,
      passwordIsExpired: false,
      tokenMFA: "",
    };
  },
  computed: {
    ...mapState({
      loading: (state) => {
        return state.auth.loading
      }
    })
  },
  methods: {
    async initPage() {
      let token = (this.$route.params.token) ? this.$route.params.token : '';
      if (token){
        try{
          this.$swal({
          title: "Loading...",
          // timer: 2000,
          timerProgressBar: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          didOpen: async () => {
            let time = new Date().getTime();
            this.$swal.showLoading();
            time = new Date().getTime() - time;

            const res = await verifyEmailMFA({
              veryCode: token
            });

            if (time < 1000) {
              await utilities.wait(1000 - time);
            }

            if (!res.success) {
              utilities.showFailureAlert(this, res.message, "Error!", 3000);
              this.$router.push({ path: "/" });
            }
            else {
              this.username = res.data.email;
              this.tokenMFA = token;
              utilities.showSuccessAlert(this, "The account has been successfully verified", "Success!", 2000, this.loginUser);
            }

          },
        });
        }
        catch(error){
          console.log({ error });
        }
      }
    },
    async loginUser() {
      try {
        this.$swal({
          title: "Loading...",
          // timer: 2000,
          timerProgressBar: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          didOpen: async () => {
            let time = new Date().getTime();
            this.$swal.showLoading();
            const res = await login({
              username: this.username,
              password: this.password,
              token: this.tokenMFA
            });

            time = new Date().getTime() - time;

            if (time < 1000) {
              await utilities.wait(1000 - time);
            }
            if (!res.success) {
              utilities.showFailureAlert(this, res.message, "Error!", 2500);
            }else if(res.code === "VRNEED") {
              utilities.showInfoAlert(this, res.message);
              this.$router.push({ path: "/" });
            }
            else {
              if (res.passwordIsExpired || res.resetPassword) {
                this.$router.push({ path: `/forgottenPassword/${res.token}` });
              } else {
                this.$router.push({ path: "/policySelect" });
              }
            }

          },
        });

      } catch (error) {
        console.log({ error });
      }
    },
  },
  created() {
    this.initPage();
  }
};
</script>

<style lang="less" scoped>
.icon {
  font-size: 40px;
}

.flex-row {
  justify-content: space-between;
}

form {
  text-align: center;
  width: 100%;

  h3 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  #login-container {
    display: flex;
    flex-direction: row;
    height: 300px;
  }

  #logo-container {
    margin-top: 50px;
  }

  #field-container {
    width: 20%;
    margin-left: 25%;
    margin-right: 5%;

    input {
      height: 40px;
    }

    button {
      height: 40px;
      width: 150px;
    }

    img {
      width: 20%;
      margin-left: 5%;
      height: 250px;
    }

  }
}

a,
a:link,
a:visited,
a:active {
  padding: 5px 10px;
  font-size: 16px;
}
</style>
