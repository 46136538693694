<template>
  <BaseClaimLayout>
    <article>
      <div class="section-head">
        <h3>Attachments</h3>
      </div>
      <div class="attachments">
        <button class="uploader" v-on:click="handleUploader(true)">
          <font-awesome-icon icon="arrow-up-from-bracket" /> Upload New
          Attachment
        </button>
        <table class="attachments-table">
          <thead>
            <tr>
              <th class="icon left"></th>
              <th>Document</th>
              <th>Date</th>
              <th>Description</th>
              <th class="icon"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="doc in data.attachments" :key="doc.id">
              <td class="icon left">
                <span v-on:click="handleEditAttachment('EditAttachment', doc.id, doc.description)"
                  ><font-awesome-icon icon="pen-to-square"
                /></span>
              </td>
              <td>
                <span v-on:click="downloadAttachment(doc.file)"
                  ><font-awesome-icon icon="file-image"
                /></span>
              </td>
              <td>{{ qsxformatDate(doc.captured, "MM/DD/YYYY") }}</td>
              <td>{{ doc.description }}</td>
              <td class="icon">
                <span v-on:click="handleDeleteAttachment(doc.id)"
                  ><font-awesome-icon icon="trash"
                /></span>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="no-data"
          v-if="!data.attachments || data.attachments.length === 0"
        >
          <p>No Data</p>
        </div>
        <div class="dashboard">
          <dashboard-modal
            :uppy="uppy"
            :open="open"
            :props="{
              onRequestCloseModal: handleUploader,
            }"
          />
        </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import { DashboardModal } from "@uppy/vue";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import {
  getFNOLAttachments,
  deleteAttachment,
  downloadAttachment,
} from "../../Services/claimsService";
import { getToken  } from "../../Services/apiService";
import { qsxformatDate } from "../../Services/utilities";
import config from "../../config";

export default {
  data() {
    return {
      open: false,
      data: {
        attachments: [],
      },
    };
  },
  components: { DashboardModal },
  computed: {
    uppy: function () {
      const data = this.data;
      return new Uppy()
        .use(XHRUpload, {
          endpoint: `${
            config.apiUrl
          }claims?action=uploadAttachment&token=${getToken()}`,
          fieldName: "photo",
          formData: true,
        })
        .on("complete", async function () {
          const attachments = await getFNOLAttachments(this);
          data.attachments = attachments.data.attachments;
        });
    },
  },
  methods: {
    async initPage() {
      const attachments = await getFNOLAttachments(this);
      this.data.attachments = attachments.data.attachments;
    },
    handleUploader(open = false) {
      this.open = open;
    },
    handleEditAttachment(name, imagingID, description) {
      this.$emit('saveInformation');    
      this.$router.push({ name, params: { imagingID: imagingID, description: description } });
    },
    redirect(name, imagingID) {
      this.$router.push({ name, params: { imagingID: imagingID, description: description } });
    },
    async handleDeleteAttachment(imagingID) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2b8cbe",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await deleteAttachment(imagingID);
            this.$swal.fire({
              icon: "success",
              title: "Success!",
              text: "Your file has been deleted.",
              showConfirmButton: false,
              timer: 1500,
            });
            this.initPage();
          }
        });
    },
    qsxformatDate,
    downloadAttachment,
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
.no-data {
  width: 100%;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: whitesmoke;
}

.attachments button {
  margin-bottom: 20px;
  border-radius: 0;
  box-shadow: none;
  border: none;
  text-decoration: underline;
}
</style>