<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 v-if="!loading" class="fade-in">File A Claim</h2>
        </div>
        <div class="content">
          <div v-if="!loading" class="fade-in">
            <div class="section-head">
              <h3>{{ this.entCode }} Member Information</h3>
            </div>
            <div class="rows">
              <div class="row">
                <div class="data-field third">
                  <label
                    :class="form.organizationName.error ? 'error-text' : ''"
                    ><span class="red">*</span>Member Name</label
                  >
                  <input
                    type="text"
                    placeholder="Enter member name..."
                    v-model="form.organizationName.value"
                    :class="form.organizationName.error ? 'error-element' : ''"
                    v-on:change="handleValueChange(form.organizationName)"
                    :maxlength="form.organizationName.maxlength"
                  />
                </div>
                <div class="data-field third">
                  <label>Member Number (if known)</label>
                  <input
                    type="text"
                    placeholder="Enter member number..."
                    :value="form.organizationNumber.display"
                    v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.organizationNumber)
                    }"
                  />
                </div>
                <div class="data-field third">
                  <label
                    :class="form.reportPersonName.error ? 'error-text' : ''"
                    ><span class="red">*</span>Name of Person Reporting</label
                  >
                  <input
                    type="text"
                    placeholder="Enter name..."
                    v-model="form.reportPersonName.value"
                    :class="form.reportPersonName.error ? 'error-element' : ''"
                    v-on:change="handleValueChange(form.reportPersonName)"
                    :maxlength="form.reportPersonName.maxlength"
                  />
                </div>
              </div>
              <div class="row">
                <div class="data-field third">
                  <label
                    :class="form.reportPersonPhone.error ? 'error-text' : ''"
                    ><span class="red">*</span>Reporter Phone</label
                  >
                  <input
                    type="text"
                    placeholder="Enter phone..."
                    :value="form.reportPersonPhone.display"
                    maxlength="14"
                    :class="form.reportPersonPhone.error ? 'error-element' : ''"
                    v-on:input="
                      (e) => {
                        phoneMaskFormat(
                          e.target.value,
                          form.reportPersonPhone
                        );
                        handleValueChange(form.reportPersonPhone);
                      }
                    "
                  />
                </div>
                <div class="data-field third">
                  <label
                    :class="form.reportPersonEmail.error ? 'error-text' : ''"
                    ><span class="red">*</span>Reporter Email</label
                  >
                  <input
                    type="text"
                    placeholder="Enter email..."
                    v-model="form.reportPersonEmail.value"
                    :class="form.reportPersonEmail.error ? 'error-element' : ''"
                    v-on:change="handleValueChange(form.reportPersonEmail)"
                    :maxlength="form.reportPersonEmail.maxlength"
                  />
                </div>
                <div class="data-field third" />
              </div>
            </div>
            <div v-if="data.coverages.length > 0">
              <div class="section-head">
                <h3>Coverage Selection</h3>
              </div>
              <div class="rows" style="min-height: 375px">
                <div class="three-grid-container">
                  <div
                    v-for="coverage in data.coverages"
                    :key="coverage.ID"
                    class="radio-buttons"
                  >
                    <input
                      type="radio"
                      name="coverage"
                      v-model="form.selectedCoverage.value"
                      :value="coverage.lineCode"
                    />
                    <label>{{ coverage.lineDisplay }}</label>
                  </div>
                </div>
                <div class="three-grid-container">
                  <div class="data-field" />
                  <div class="data-field" />
                  <div
                    class="data-field"
                    v-if="
                      entCode === 'NPIG' && form.selectedCoverage.value === 'WC'
                    "
                    style="width: 100%"
                  >
                    <label><span class="red">*</span>State</label>
                    <select v-model="form.rateStateID.value">
                      <option value="null" disabled selected hidden>
                        Select claim state...
                      </option>
                      <option
                        v-for="state in data.states"
                        :key="state.stateid"
                        :value="state.stateid"
                      >
                        {{ state.stateName }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="buttons-container">
              <button class="primary back" v-on:click="saveInformation()">Back</button>
              <button
                class="primary"
                v-on:click="continueClaim()"
                type="button"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import { getFileAClaimData, createNewReport, getDecodedToken, saveToken } from "../../Services/claimsService";
import { getEntityCode } from "../../Services/apiService";
import { cancelCurrentClaim } from "../../Services/claimsService";
import utilities from "../../Services/utilities";
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";
import store from "../../Services/Store/store";

export default {
  data() {
    return {
      form: {
        organizationName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "150"
        },
        organizationNumber: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 9999
        },
        reportPersonName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "150"
        },
        reportPersonPhone: {
          required: true,
          type: "phone",
          value: null,
          error: false,
        },
        reportPersonEmail: {
          required: true,
          type: "email",
          value: null,
          error: false,
          maxlength: "75"
        },
        organizationState: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        rateStateID: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        selectedCoverage: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
      },
      data: {
        states: [],
        coverages: [],
      },
      entCode: getEntityCode() || "NIT",
      loading: true,
    };
  },
  components: { BaseClaimLayout },
  props: ["entityCode"],
  methods: {
    async initPage() {

      const decodedToken = await getDecodedToken();

      const entityCode = this.entCode;

      if (!entityCode) {
        this.$router.push({ name: "WelcomePage" });
      }

      localStorage.setItem("entityCode", entityCode);
      store.commit("auth/saveEntityCode", entityCode);

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();
          const dataRes = await getFileAClaimData(entityCode, this);
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (dataRes && dataRes.success) {

            const screenInfo = decodedToken?.makeClaimScreenInfo;

          if (screenInfo && screenInfo !== null && screenInfo !== "null") {
          this.form = screenInfo;
          } else {
            utilities.handleFormData(dataRes.fnolData, this.form);
          }

            this.data = dataRes;
            this.loading = false;
            this.$swal.close();
          } else {
            utilities.showFailureAlert(this, "Could not fetch the coverage list. Please try again later.");
          }
        },
      });
    },
    handleValueChange: utilities.handleValueChange,
    phoneMaskFormat: utilities.phoneMaskFormat,
    numeric: utilities.numeric,
    async saveInformation() {
      await saveToken({makeClaimScreenInfo: this.form}, this);
      
      await cancelCurrentClaim();

      this.$router.push({
            name: "WelcomePage",
            params: {},
          });
    },
    async continueClaim() {
      if (
        this.entCode === "NPIG" &&
        this.form.selectedCoverage.value === "WC"
      ) {
        // this.form.organizationState.required = true;
        this.form.rateStateID.required = true;
      }

      if (this.entCode === "NIT") {
        // Default to MN
        this.form.organizationState.value = "25"
        this.form.rateStateID.value = "25"
      }

      const valid = utilities.validateFields(this.form);
      if (valid) {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await createNewReport(this.form, this.entCode, this);
          const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (srvData && srvData.success) {

          let lobCode = "";
          if (this.entCode === "NPIG") {
            lobCode = "NP";
          } else {
            if (this.form.selectedCoverage.value === "WC") {
              lobCode = "WC";
            } else {
              lobCode = "PL";
            }
          }

          await saveToken({ lobCode, fnolID: srvData.fnolID, makeClaimScreenInfo: null, fnolLineCode: this.form.selectedCoverage.value}, this)

          await utilities.showSuccessAlert(this, "Member information saved successsfully.");

          if (lobCode === "WC") {
            this.$router.push({
              name: "WorkersCompLossDetails"
            });
          }

          if (lobCode === "PL" || (lobCode === "NP" && this.form.selectedCoverage.value !== "WC")) {
            this.$router.push({ name: "LossDetails" });
          }

          if (lobCode === "NP" && this.form.selectedCoverage.value === "WC") {

            const state = this.data.states.filter((state) => {
              
              return state.stateid === this.form.rateStateID.value;
            })[0];

            if (state.statecode === "IA") {
              this.$router.push({
              name: "IowaWorkersCompLossDetails",
            });
            } else if (state.statecode === "WI") {
              this.$router.push({
              name: "WisconsinWorkersCompLossDetails",
            });
            } else {
              this.$router.push({
              name: "WorkersCompLossDetails"
            });
            }
          }
        } else {
          utilities.showFailureAlert(this, "There was an error while submitting the member information. Please try again later");
        }
        
        },
      });
      } else {
        utilities.showFailureAlert(this, "Check the errors and try again.", "Missing Required Information!");
      }
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
.check-box {
  display: flex;
  flex-direction: row;
  width: 30%;
  align-items: center;
}

.check-box input {
  width: 20px;
  height: 20px;
  justify-content: space-between;
}

.buttons-container {
  width: 100%;
  /* text-align: center; */
  display: flex;
  justify-content: space-between;
}

.buttons-container button {
  width: 175px;
  font-size: 20px;
}

h2 {
  font-size: 35px;
}

hr {
  margin: 25px 0;
}

input,
select {
  border: 1px solid black;
  height: 40px;
  box-shadow: none;
}

/* label {
  font-weight: bold;
} */

button {
  height: 40px;
  width: 175px;
  font-size: 20px;
}

.data-field label {
  color: black;
}

.data-field input,
select {
  border: 1px solid black;
}

.back {
  background: gray;
}

</style>