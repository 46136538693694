import { api, getAuthToken, saveDefaultState } from "./apiService";
import store from "./Store/store";

export const login = async (params) => {
  try {
    
    await saveDefaultState();
    
    const res = await api.post("auth/?action=login", params, '', true);
    const data = res.data;
    if (!data.success || (res.code || res.code === "VRNEED")) return data;
    if (data?.authToken) {
      if (data.passwordIsExpired) {
        store.commit("auth/saveUserRights", ["allowPasswordReset"]);
        return {
          passwordIsExpired: data.passwordIsExpired,
          authToken: data.authToken,
          token: data.token,
          success: true
        }
      }
      localStorage.setItem("authToken", data.authToken);
      store.commit("auth/saveAuthToken", data.authToken);
      localStorage.setItem("employeeName", data.employeeName);
      store.commit("auth/saveEmployeeName", data.employeeName);
    }
    return data;
  } catch (err) {
    return {
      success: false,
      message: "Error while logging in. Please try again later."
    };
  }
};

export const logout = async () => {
  try {
    await saveDefaultState();
    return { message: "Success" };
  } catch (error) {
    console.log({ error });
  }
};

export const getDecodedAuthToken = async () => {
  try {
    const token = getAuthToken();
    if (token) {
      const res = await api.post(`auth?action=getDecodedToken`, { token });
      const srvData = res?.data;
      return srvData;
    } else {
      return null;
    }
  } catch (error) {
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}
export const userHasRight = async (rights) => {
  try {
    const token = getAuthToken();
    const res = await api.post("auth/?action=userHasRight", { rights, token });
    const srvData = res.data;

    if (srvData) {
      return srvData.hasRight;
    } else {
      return false;
    }
  } catch (error) {
    console.log({error});
    return false;
  }
}

export const getMembers = async () => {
  try {
    const res = await api.post("auth/?action=getMembers", {});
    return res.data;
  } catch (error) {
    console.log({error});
    return {
      success: false,
      message: "Error while getting the members"
    };
  }
}

export const recoverPassword = async (email) => {
  try {
    const mode = import.meta.env.MODE;

    let linkUrl = "http://localhost:3000/company/customer-portal";

    if (mode === "qa") linkUrl = "https://npiaqacp.quicksilversystems.com/company/customer-portal";
    if (mode === "prod") linkUrl = "https://www.npiacustomerportal.com/company/customer-portal";

    const res = await api.post("auth/?action=recoverPassword", { email, linkUrl });
    return res.data;
  } catch(error) {
    console.log({error});
    return {
      success: false,
      message: "Error while sending the email."
    }
  }
};

export const resetPassword = async (params) => {
 try {
  const passwordResetToken =   params.passwordResetToken ? params.passwordResetToken : localStorage.getItem("passwordResetToken");
  await saveDefaultState();
  const res = await api.post("auth/?action=resetPassword", params);
  return res.data;
 } catch(error) {
   console.log({error});
   return {
     success: false,
     message: "Error resetting the password."
   }
 }
};

export const checkResetToken = async (token) => {
 try {
  const res = await api.post("auth/?action=checkResetToken", { token });
  return res.data;
 } catch(error) {
   console.log({error});
   return {
     success: false,
     message: "Error resetting the password."
   }
 }
};

export const saveToken = async (data) => {
  try {
    const oldToken = getAuthToken()
    const res = await api.post(`auth?action=saveToken`, { data, token: oldToken });
    const authToken = res?.data?.token;
    localStorage.setItem("authToken", authToken);
      store.commit("auth/saveAuthToken", authToken);
    return { data: res.data };
  } catch (error) {
    return {
      success: false,
      message: "Error getting claim data."
    }
  }
}

export const verifyEmailMFA = async (params) => {
  try {
    const res = await api.post(`auth?action=verifyEmailMFA`, params);
    if (!res.data.success){
      return {success: false, message: res.data.message} 
    }
    else{
      return {success: true, data: res.data};
    }
  }
  catch{
    return {
      success: false,
      message: "An error occurred while checking mail"
    }
  }
}


