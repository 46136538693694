<template>
  <BaseLayout @selectedTermChanged="initPage()">
    <template #content>
      <article>
        <div v-if="pdf" class="pdf-viewer">
          <PDFViewer :pdf="pdf"/>
          <div class="whole">
            <button v-on:click="closeViewer()" class="primary iframe-btn">Close Viewer</button>
            </div>    
        </div>
        <div class="policy-background">          
          <div class="title">
            <h2 v-if="!loading" class="fade-in">BILLING DETAILS</h2>
            </div>
          <div class="policy-content">
            <div v-if="!loading" class="fade-in">
              <div>
              <div class="section-head">
                <h3>Summary</h3>
              </div>
              <div class="rows">
                <p style="margin-bottom: 10px">
                <strong class="green">{{ formatMoney(data.currentDue) }}</strong> - Due On {{ data.currentDueDate }}
              </p>
              </div>
            </div>
            <div>
              <div class="section-head">
                <h3>Schedule</h3>
              </div>
              <div class="rows">
                <table>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Invoice Sent</th>
                    <th>Amount Due</th>
                    <th>Due Date</th>
                    <th>Date Paid</th>
                    <th>Amount Paid</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="bill in data.billData" :key="bill.billNumber">
                    <td>Installment {{bill.billNumber}}</td>
                    <td></td>
                    <td>{{ bill.printDate }}</td>
                    <td class="green">{{ formatMoney(bill.due) }}</td>
                    <td>{{ bill.dueDate }}</td>
                    <td>{{ bill.paidDate }}</td>
                    <td class="green">{{ formatMoney(bill.amountPaid)}}</td>
                    <td>
                      <a v-if="bill.isPrinted" v-on:click="printInvoice(bill.billImageFile)" class="a-link">Invoice</a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total Due for Term</th>
                    <td class="green"><strong>{{ formatMoney(data.policyBalance) }}</strong></td>
                    <th>Total Paid for Term</th>
                    <td class="green"><strong>{{ formatMoney(data.totalPaid) }}</strong></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
              </div>
            </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </BaseLayout>
</template>

<script>
import PDFViewer from "../Partials/PDFViewer.vue"
import { getBilling, download } from "./../../Services/customerService";
import utilities from "../../Services/utilities";

export default {
  data() {
    const data = {};
    return {
      pdf: null,
      data,
      loading: true
    };
  },
  components: { PDFViewer },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

      const res = await getBilling(this);
      const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not fetch billing information.");
        } else {
          this.data = srvData;
          this.loading = false;
          this.$swal.close();
        }
        },
      });        
    },
    formatMoney(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(amount);
    },
    async printInvoice(billImageFile) {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await download("policybill", [billImageFile]);
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!res.success) {
            utilities.showFailureAlert(this, "Could not generate the invoice.");
      } else {
        this.pdf = res.path;
        this.$swal.close();      
      }
        },
      });      
    },
    closeViewer() {      
      this.pdf = null;
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>

tr {
  border-bottom: 2px solid #e7e7e7;
  height: 40px;
}

th {
  font-size: 18px;
}

th, td {
  text-align: left;
  padding-left: 5px;
}

tfoot {
  background-color: #f3f3f3;
  width: 100%;
}

.rows {
  padding: 15px;
}

.center {
  text-align: center;
}
</style>