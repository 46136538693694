<template>
  <BaseLayout>
    <template #content>
      <article>
        <div class="policy-background">
          <div class="title">
            <h2 class="fade-in" v-if="!loading">CREATE CERTIFICATE</h2>
          </div>
          <div class="policy-content">
            <div v-if="!loading" class="fade-in">
              <form @submit.prevent="createCert">
                <div class="col">
                  <label :class="form.partyName.error ? 'error-text' : ''"
                    ><span class="red">*</span>Name of Certificate Holder</label
                  >
                  <input
                    type="text"
                    placeholder="Enter name..."
                    class="form-control"
                    v-model="form.partyName.value"
                    v-on:change="handleValueChange(form.partyName)"
                    :class="form.partyName.error ? 'error-element' : ''"
                    :maxlength="form.partyName.maxlength"
                  />
                </div>
                <div class="col">
                  <label :class="form.address1.error ? 'error-text' : ''"><span class="red">*</span>Address</label>
                  <input
                    type="text"
                    placeholder="Enter street address..."
                    class="form-control"
                    v-model="form.address1.value"
                    v-on:change="handleValueChange(form.address1)"
                    :class="form.address1.error ? 'error-element' : ''"
                    :maxlength="form.address1.maxlength"
                  />
                </div>
                <div class="col">
                  <label :class="form.address2.error ? 'error-text' : ''">Address 2</label>
                  <input
                    type="text"
                    placeholder="Enter alternative address..."
                    class="form-control"
                    v-model="form.address2.value"
                    v-on:change="handleValueChange(form.address2)"
                    :class="form.address2.error ? 'error-element' : ''"
                    :maxlength="form.address2.maxlength"
                  />
                </div>
                <div class="row">
                  <div class="col">
                    <label :class="form.city.error ? 'error-text' : ''"><span class="red">*</span>City</label>
                    <input
                      type="text"
                      placeholder="Enter city..."
                      class="form-control"
                      v-model="form.city.value"
                      v-on:change="handleValueChange(form.city)"
                      :class="form.city.error ? 'error-element' : ''"
                      :maxlength="form.city.maxlength"
                    />
                  </div>
                  <div class="col">
                    <label :class="form.city.error ? 'error-text' : ''"><span class="red">*</span>State</label>
                    <select class="form-control" v-model="form.state.value" v-on:change="handleValueChange(form.state)" :class="form.state.error ? 'error-element' : ''">
                      <option value="null" disabled selected hidden>
                        State...
                      </option>
                      <option
                        v-for="state in states"
                        :key="state.stateCode"
                        :value="state.stateCode"
                      >
                        {{ state.stateName }}
                      </option>
                    </select>
                  </div>
                  <div class="col">
                    <label :class="form.zip.error ? 'error-text' : ''"><span class="red">*</span>Zip Code</label>
                    <input
                      type="number"
                      placeholder="Enter ZIP Code..."
                      class="form-control"
                      v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.zip)
                    }"
                      v-on:change="handleValueChange(form.zip)"
                      :class="form.zip.error ? 'error-element' : ''"
                    />
                  </div>
                </div>
              </form>
              <hr />
              <div class="buttons-container">
                <button class="primary" v-on:click="createCert()">
                  Create / Print
                </button>
                <router-link to="/certificates">
                  <button class="primary gray">Back</button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </BaseLayout>
</template>

<script>
import {
  getStates,
  createCertificate,
} from "./../../Services/customerService";
import utilities from "../../Services/utilities";

export default {
  data() {
    return {
      form: {
        partyName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "200"
        },
        address1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        address2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "50"
        },
        city: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "50"
        },
        state: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        zip: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxvalue: 99999
        },
      },
      states: [],
      loading: true
    };
  },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getStates();
          const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Page not found. Please try again later");
          } else {
            this.states = srvData.states;
            this.loading = false;
            this.$swal.close();
          }

        },
      });

      
    },
    handleValueChange: utilities.handleValueChange,
    numeric: utilities.numeric,
    async createCert() {

      const valid = utilities.validateFields(this.form);

      if (valid) {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const data = {
        partyName: this.form.partyName.value,
        address1: this.form.address1.value,
        address2: this.form.address2.value,
        city: this.form.city.value,
        state: this.form.state.value,
        zip: this.form.zip.value
      };
      const res = await createCertificate(JSON.stringify(data), this);
      
      if (res.success) {
        res.link.click();
        await utilities.showSuccessAlert(this, "Certificate holder saved successsfully.");
        this.$router.push({ path: "/certificates" });
      } else {
        utilities.showFailureAlert(this, "Could not create the certificate holder.");
      }
        },
      });

      } else {
        utilities.showFailureAlert(this, "Check the errors and try again.", "Missing Required Information!");
      }
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
.col {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-bottom: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
  /* margin-bottom: 20px; */
}

.row div {
  width: 30%;
}

.gray {
  background-color: gray;
}

.buttons-container {
  width: 40%;
  justify-content: center;
  text-align: center;
}

hr {
  width: 40%;
  float: left;
  margin-bottom: 30px;
}

input {
  border: 1px solid black;
  height: 40px;
}

select {
  border: 1px solid black;
  box-shadow: none;
  height: 40px;
}

button {
  height: 40px;
  width: 175px;
  font-size: 20px;
}

label {
  font-weight: bold;
}
</style>
