<template>
  <div id="container">
    <div id="wrapper">
      <header>
        <div style="width: 33.33%"/>
        <div class="logo">
          <img src="./../assets/company-logo.png" />
        </div>
        <div style="width: 33.33%">
            <button v-if="!disableBtn" id="cancelClaimBtn" class="primary" style="width: 175px" v-on:click="cancelClaim()">Cancel</button>
        </div>
      </header>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { cancelCurrentClaim } from "../Services/claimsService";
import utilities from "../Services/utilities";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { PulseLoader },
  data() {
    return {
      disableBtn: false
    };
  },
  methods: {
    initPage() {
      const currentRoute =  this.$route.name;
      this.disableBtn = currentRoute === "FinishClaim"
    },
    cancelClaim() {

      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2b8cbe",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, cancel it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          await cancelCurrentClaim();

          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          await utilities.showSuccessAlert(this, "Claim cancelled.");

          this.$router.push({
              name: "WelcomePage"
            });

        },
      }); 
          }
        });
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style lang="less" scoped>
h1 {
  font-size: 40px;
}

#cancelClaimBtn {
  float: right;
  margin-right: 20px;
}

#container {
  background-color: rgb(243, 243, 243);
  width: 100%;
  height: 100%;
  position: absolute;
  // overflow: hidden;
  #wrapper {
    // top: 20%;
    width: 100%;
    position: relative;
  }
}

header {
  background-color: white;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin-left: 10%;
  width: 100%;
  box-shadow: 0 0 10px 2.5px #666666;
  position: fixed;
  border-bottom: 1px solid #666666;;
  top: 0;
  left: 0;
}

.spinner {
  // -webkit-transform: translate(200%, 300%);
  width: 300px;
  height: 100px;
  // background-color: white;
  // border: 1px solid black;
  // border-radius: 5px;
  text-align: center;
  align-items: center;
  padding: 25px;
  position: absolute;
  // top: 0%;
  // left: 50%;
}

.faded {
  opacity: 0.5;
  transition: opacity 0.25s ease-out;
}

.visible {
  opacity: 1;
  transition: opacity 0.25s ease-in;
}

.hidden {
  opacity: 0;
  transition: opacity 0.25s ease-out;
}

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.5s;
}

.fade-in-out {
  animation: fadeinout 0.5s linear 1 forwards;
}

.logo {
  // height: 100px;
  width: 200px
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
</style>