<template>
  <main :key="organizationName">
    <header id="topNav" v-if="isAuth">
      <div class="logo-container flex-row" :class="collapsed ? 'collapsed' : ''">
        <img
        v-if="!collapsed"
          src="./../assets/company-logo.png"
        />
        <img
        v-if="collapsed"
          src="./../assets/cropped-company-logo.png"
        />
        <div class="arrows" v-if="!collapsed" v-on:click="toggleCollapsed(true)">
          <font-awesome-icon icon="chevron-left" />
          <font-awesome-icon icon="chevron-left" />
        </div>
        <div class="arrows" v-if="collapsed" v-on:click="toggleCollapsed(false)">
          <font-awesome-icon icon="chevron-right" />
          <font-awesome-icon icon="chevron-right" />
        </div>
      </div>
      <div :class="collapsed ? 'collapsed-employeeName' : 'employeeName'">
        <h1>Welcome, {{employeeName}}!</h1>    
      </div>
      <div class="organizationName">
        <h1 class="fade-in">{{organizationName}}</h1>
        </div>        
        <nav class="logOut">
          <!-- <router-link class="btn green" to="makeapayment">Pay</router-link> -->
          <button class="primary" style="width: 175px" @click.prevent="logoutUser">Logout</button>
        </nav>
      </header>    
    <SideNav v-if="isAuth" :collapsed="collapsed"/>
      <section :class="collapsed ? 'expanded' : ''">
          <slot name="content"/>
      </section>
  </main>
</template>

<script>
import SideNav from "./SideNav.vue";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { logout } from "./../Services/authService";
import { getEmployeeInfo } from "./../Services/customerService";
import { getCollapsed, getEmployeeName, getOrganizationName } from "../Services/apiService";

export default {
  components: { SideNav, PulseLoader },
  data() {
    return {
      isAuth: true,
      collapsed: getCollapsed(),
      organizationName: getOrganizationName(),
      employeeName: getEmployeeName(),
    };
  },
  methods: {
    async initPage() {

      if (!this.employeeName && this.employeeName !== "") {
        const res = await getEmployeeInfo();
        const srvData = res.data;

        if (srvData && srvData.employeeName) {
          localStorage.setItem("employeeName", srvData.employeeName);
          await this.$store.commit("auth/saveEmployeeName", srvData.employeeName);
          this.employeeName = srvData.employeeName;
        }
      }
    },
    async logoutUser() {
      try {
        const res = await logout();
        if (res.message === "Success") {
          this.$emit("logout");
          this.$router.push({ path: "/" });
        }
      } catch (error) {
        console.log({ error });
      }
    },
    async toggleCollapsed(bool) {
      this.collapsed = bool;
      localStorage.setItem("collapsed", bool);
      await this.$store.commit("auth/saveCollapsed", bool);
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style lang="less" scoped>
.spinner {
  -webkit-transform: translate(200%, 300%);
  width: 300px;
  height: 100px;
  // background-color: white;
  // border: 1px solid black;
  // border-radius: 5px;
  text-align: center;
  align-items: center;
  padding: 25px;
  position: fixed;
  top: 50vh;
  left: 50%;
}

.faded {
  opacity: .5;
  transition: opacity .25s ease-out;
}

.visible {
  opacity: 1;
  transition: opacity .25s ease-in;
}

.hidden {
  opacity: 0;
  transition: opacity .25s ease-out;
}

@keyframes fadeinout {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

// .fade-out {
//   animation-name: fadeOutOpacity;
// 	animation-iteration-count: 1;
// 	animation-timing-function: ease-out;
// 	animation-duration: .5s;
// }

// .fade-in-out {
//   animation: fadeinout .5s linear 1 forwards;
// }

// main {
//   // display: grid;
//   // grid-template-columns: minmax(15%, 8%) minmax(50%, 88%);
//   width: 100%;
// }
header#topNav {
  // border-bottom: 1px solid #ccc;
  padding: 0 12px 0 0;
  height: 12vh;
  align-items: center;
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  box-shadow: 0 0 10px 2.5px #666666;
  z-index: 999;
}

.logo-container {
    width: 15%;
    height: 12vh;
    background-color: #2b8cbe;
    color: white;
    // border-bottom: 1px solid black;
  }

  section {
    margin-left: 15%;
  }

  .expanded {
    margin-left: 5%;
  }

  .orgName {
    width: 30%;
    height: 12vh;
  }

  .orgName h1 {
    height: 12vh;
    z-index: 99999;
  }

  img {
      height: 10vh;
      background-color: white;
      width: 75%;
      margin-left: 12.5%;
    }

    .arrows {
    width: 12.5%;    
  }

  .arrows:hover {
    color: black;
      cursor: pointer;
  }

    .icon {
      width: 50px;
    }

    .collapsed {
    width: 5%;
  }

  .collapsed img {
    width: 50%;
    height: 5vh;
    margin-left: 5%;
  }

  .collapsed .arrows {
    width: 45%;
  }

  .organizationName {
    width: 40%;
  }

  .employeeName {
    width: 35%;
    font-family: 'Calibri';
    padding-left: 2.5%;
  }

  .collapsed-employeeName {
    width: 45%;
    font-family: 'Calibri';
    padding-left: 2.5%;
  }

  .logOut {
    display: flex;
    width: 10%;
    justify-content: right;
  }

</style>
