import axios from "axios";
import config from "../config";
import store from "./Store/store";

export const getAuthToken = () => {
  let authToken = store.getters["auth/getAuthToken"] || null;
  if (authToken) return authToken;
  else if (!authToken) {
    authToken = localStorage.getItem("authToken") || null;
    if (authToken) {
      store.commit("auth/saveAuthToken", authToken);
      return authToken;
    }
  }
  return null;
};

export const getOrganizationName = () => {
  let organizationName = store.getters["auth/getOrganizationName"] || "";
  if (organizationName) return organizationName;
  else if (!organizationName) {
    organizationName = localStorage.getItem("organizationName") || "";
    if (organizationName) {
      store.commit("auth/saveOrganizationName", organizationName);
      return organizationName;
    }
  }
  return null;
};

export const getEmployeeName = () => {
  let employeeName = store.getters["auth/getEmployeeName"] || "";
  if (employeeName) return employeeName;
  else if (!employeeName) {
    employeeName = localStorage.getItem("employeeName") || "";
    if (employeeName) {
      store.commit("auth/saveEmployeeName", employeeName);
      return employeeName;
    }
  }
  return null;
};

export const getEntityCode = () => {
  let entityCode = store.getters["auth/getEntityCode"] || "";
  if (entityCode) return entityCode;
  else if (!entityCode) {
    entityCode = localStorage.getItem("entityCode") || "";
    if (entityCode) {
      store.commit("auth/saveEntityCode", entityCode);
      return entityCode;
    }
  }
  return null;
};

export const getToken = () => {
  let token = store.getters["auth/getToken"] || "";
  if (token) return token;
  else if (!token) {
    token = localStorage.getItem("token") || "";
    if (token) {
      store.commit("auth/saveToken", token);
      return token;
    }
  }
  return null;
};

export const getCollapsed = () => {
  let collapsed = store.getters["auth/getCollapsed"] || null;
  if (collapsed === true || collapsed === false) return collapsed;
  else if (collapsed === null) {
    collapsed = localStorage.getItem("collapsed") || null;
    if (collapsed === true || collapsed === false) {
      store.commit("auth/saveCollapsed", collapsed);
      return collapsed;
    }
  }
  return false;
};

export const getShowSublist = () => {
  let showSublist = store.getters["auth/getShowSublist"] || null;
  if (showSublist === true || showSublist === false) return showSublist;
  else if (showSublist === null) {
    showSublist = localStorage.getItem("showSublist") || null;
    if (showSublist === true || showSublist === false) {
      store.commit("auth/saveShowSublist", showSublist);
      return showSublist;
    }
  }
  return false;
};

export const authHeader = () => {
  const authToken = getAuthToken();
  // const policyID = getPolicyID();
  if (authToken) {
    return { "x-access-token": authToken };
  }
  return {};
}

const AxiosInstance = axios.create({
  baseURL: config.apiUrl,
});

export const api = {
  get: async (url) => {
    try {
      const res = await AxiosInstance.get(url);
      return res;
    } catch (err) {
      return {
        isFailed: err.response.status,
        message: err.response.data.message,
      };
    }
  },
  post: async (url, payload, responseType = "") => {
    try {
      const headers = authHeader();
      const res = await AxiosInstance.post(url, payload, { headers,responseType: responseType });
      return res;
    } catch (err) {
      return {
        isFailed: err.response.status,
        message: err.response.data.message,
      };
    }
  },
  verifyToken: async () => {
    try {
      const headers = authHeader();
      if (headers?.["x-access-token"]) {
        const res = await api.post("auth/?action=verifyToken", {});
        return res.data;
      }
      return false;
    } catch (err) {
      console.log({ err });
      return {
        isFailed: err,
        message: err,
        // isFailed: err.response.status,
        // message: err.response.data.message,
      };
    }
  },
};

export const saveDefaultState = async () => {
  store.commit("auth/saveAuthToken", "");
  store.commit("auth/saveOrganizationName", "");
  store.commit("auth/saveEmployeeName", "");
  store.commit("auth/saveCollapsed", null);
  store.commit("auth/saveShowSublist", null);

  localStorage.setItem("authToken", "");
  localStorage.setItem("organizationName", "");
  localStorage.setItem("employeeName", "");
  localStorage.setItem("saveCollapsed", null);
  localStorage.setItem("showSublist", null);
};

export const saveDefaultClaimState = async () => {
  store.commit("auth/saveToken", "");
  store.commit("auth/saveEntityCode", null);

  localStorage.setItem("token", "");
  localStorage.setItem("entityCode", null);
  localStorage.setItem("saveCollapsed", null);
};
