const mode = import.meta.env.MODE;

let apiUrl = "http://localhost:1337/api/v1/";

if (mode === "qa") apiUrl = "https://npiaqa.quicksilversystems.com/api/v1/";
if (mode === "prod") apiUrl = "https://npialive.quicksilversystems.com/api/v1/";

const config = {
  apiUrl,
};
export default config;