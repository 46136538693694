<template>
  <div id="container">
    <div id="wrapper">
      <slot></slot>
    </div>
    <div
        class="spinner fade-in"
        :class="loading ? 'visible' : 'hidden'"
        v-if="loading"
      >
        <PulseLoader :size="'50px'" :color="'black'"></PulseLoader>
      </div>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { mapState } from "vuex";

export default {
  components: { PulseLoader },
  computed: {
    ...mapState({
      loading: (state) => {
        // console.log("state.auth.loading: ", state.auth.loading)
        return state.auth.loading
      }
    })
  },
};
</script>

<style lang="less" scoped>
#container {
  background-color: rgb(243, 243, 243);
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  #wrapper {
    top: 20%;
    width: 100%;
    position: relative;
  }

  .spinner {
    -webkit-transform: translate(200%, 300%);
    width: 300px;
    height: 100px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    padding: 25px;
    position: absolute;
    top: 0%;
    left: 10%;
  }

  .faded {
    opacity: 0.5;
    transition: opacity 0.25s ease-out;
  }

  .visible {
    opacity: 1;
    transition: opacity 0.25s ease-in;
  }

  .hidden {
    opacity: 0;
    transition: opacity 0.25s ease-out;
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .fade-out {
    animation-name: fadeOutOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
  }

  .fade-in-out {
    animation: fadeinout 0.5s linear 1 forwards;
  }
}
</style>