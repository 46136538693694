<template>
  <BaseLayout @selectedTermChanged="initPage()">
    <template #content>
      <article>
        <div v-if="pdf" class="pdf-viewer">
          <PDFViewer :pdf="pdf" v-if="pdf !== null"/>
          <div class="whole">
            <button v-on:click="closeViewer()" class="primary iframe-btn">Close Viewer</button>
            </div>
        </div>
        <div class="policy-background">
          <div class="title">
            <h2 class="fade-in" v-if="!loading">DOCUMENTS</h2>
          </div>
          <div class="policy-content">
            <div v-if="!loading" class="container fade-in">
              <div class="section-head">
                  <h3>Endorsement Documents</h3>
                </div>
                <div class="rows">
                  <div class="data-field sixth">
                    <label>Display Rows Per Page</label>
                    <select v-model="displayRows" v-on:change="handleDisplayRows()">
                      <option :value="10">10</option>
                      <option :value="25">25</option>
                      <option :value="50">50</option>
                      <option :value="100">100</option>
                      <option :value="300">300</option>
                      <option :value="1000000000">View All</option>
                    </select>
                  </div>
                  <table id="endorsementDocumentsTable" class="striped-table">
                  <thead>
                    <tr>
                      <th class="checkbox-column center">
                        <input
                          type="checkbox"
                          v-on:change="checkAll()"
                          id="checkAllID"
                        />
                      </th>
                      <th>Document</th>
                      <th class="page-count-column center">Page Count</th>
                    </tr>
                  </thead>
                  <tbody v-if="data.endorsementDocuments.length > 0">
                    <tr v-for="(doc, index) in data.endorsementDocuments.slice((endorsementsPage * displayRows), (endorsementsPage * displayRows) + displayRows)" :key="doc.id">
                      <td class="checkbox-column">
                        <input
                          type="checkbox"
                          v-on:change="checkRow(doc.id)"
                          class="allToPrint"
                          :doc-id="doc.id"
                          :checked="checkedRows.includes(doc.id)"
                        />
                      </td>
                      <td>
                        <a class="a-link" v-on:click="printDocuments([doc.id])"
                          >{{ index+1 }} - {{ doc.docCode }} - {{ doc.effectiveDate }}</a
                        >
                      </td>
                      <td class="page-count-column">
                        {{ doc.pageCount || "N/A" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="striped-no-data" v-if="!data.endorsementDocuments || data.endorsementDocuments.length === 0">
                  <h3>No Data</h3>
                </div>
                <div class="pagination" v-if="data.endorsementDocuments && data.endorsementDocuments.length > 0">
                    <div class="flex-row">
                      <button
                      class="primary"
                      v-on:click="printSelectedEndorsementDocs()"
                    >
                      Print Selected Documents
                    </button>
                    <p>Showing {{ ((endorsementsPage + 1) * displayRows) - (displayRows - 1) }} to {{getNumEntries(endorsementsPage, data.endorsementDocuments.length)}} of {{data.endorsementDocuments.length}} entries</p>
                    </div>
                    <div class="pagination-buttons">
                    <button
                      v-on:click="handlePageNumber('-', data.endorsementDocuments, 'endorsementsPage')"
                      class="next-buttons"
                    >
                      Previous
                    </button>
                    <button
                      v-if="needPrevSeparator"
                      class="separator"
                    >
                      ...
                    </button>
                    <button v-on:click="() => { endorsementsPage = endorsementsPage-2}" v-if="button1" class="page-buttons" :class="{selected: endorsementsPage === endorsementsPage - 2}">{{endorsementsPage-1}}</button>
                    <button v-on:click="() => { endorsementsPage = endorsementsPage-2}" v-if="button2" class="page-buttons" :class="{selected: endorsementsPage === endorsementsPage - 2}">{{endorsementsPage-1}}</button>
                    <button v-on:click="() => { endorsementsPage = endorsementsPage-1}" v-if="button3" class="page-buttons" :class="{selected: endorsementsPage === endorsementsPage - 1}">{{endorsementsPage}}</button>
                    <button v-on:click="() => { endorsementsPage = endorsementsPage}" class="page-buttons" :class="{selected: endorsementsPage === endorsementsPage}">{{endorsementsPage+1}}</button>
                    <button v-on:click="() => { endorsementsPage = endorsementsPage+1}" v-if="button4" class="page-buttons" :class="{selected: endorsementsPage === endorsementsPage + 1}">{{endorsementsPage+2}}</button>
                    <button v-on:click="() => { endorsementsPage = endorsementsPage+2}" v-if="button5" class="page-buttons" :class="{selected: endorsementsPage === endorsementsPage + 2}">{{endorsementsPage+3}}</button>
                    <button
                      v-if="needNextSeparator"
                      class="separator"
                    >
                      ...
                    </button>
                    <button
                      v-on:click="handlePageNumber('+', data.endorsementDocuments, 'endorsementsPage')"
                      class="next-buttons"
                    >
                      Next
                    </button>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </BaseLayout>
</template>

<script>
import {
  endorsementDocuments,
  download,
} from "./../../Services/customerService";
import PDFViewer from "../Partials/PDFViewer.vue";
import utilities from "../../Services/utilities";

export default {
  data() {
    const data = {
      endorsementDocuments: []
    };
    return {
      data,
      checkedRows: [],
      endorsementsPage: 0,
      pdf: null,
      loading: true,
      displayRows: 10
    };
  },
  components: { PDFViewer },
  props:['policyID'],
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();
  
      const res = await endorsementDocuments(this);
      const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not fetch the endorsement documents.");
        } else {
          this.data = srvData;
          this.loading = false;
          this.$swal.close();
        }

        },
      });
    },
    checkAll() {
      const chkAll = document.getElementById("checkAllID");
      // const table = document.getElementById("endorsementDocumentsTable");
      const allIds = this.data.endorsementDocuments.map((doc) => {
        return doc.id
      });
      if (chkAll.checked) {
        // const rows = table.querySelectorAll(".allToPrint");
        this.checkedRows = allIds;
      } else {
        this.checkedRows = [];
      }
    },
    checkRow(id) {
      if (this.checkedRows.includes(id)) {
        this.checkedRows.splice(this.checkedRows.indexOf(id), 1);
      } else {
        this.checkedRows.push(id);
      }
    },
    printSelectedEndorsementDocs() {
      if (this.checkedRows.length > 0) {
        this.printDocuments(this.checkedRows, true);
      } else { 
        utilities.showInfoAlert(this, "Please select at least one document.")
      }
    },
    async printDocuments(arr, print = false) {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();
          const res = await download("policydoc", arr, print);
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!res.success) {
            utilities.showFailureAlert(this, "Could not generate the document.")
      } else {
        
        if (print) {
          res.link.click();
        } else {
          this.pdf = res.path;
        }
        this.$swal.close();
      }
        },
      }); 
    },
    getNumEntries(pageNum, length) {
       if (length < ((pageNum * this.displayRows) + this.displayRows)) {
         return (pageNum * this.displayRows) + (length - (pageNum * this.displayRows)) ;
       } else {
         return (pageNum * this.displayRows) + this.displayRows;
       }
     },
     handlePageNumber(x, arr, page) {
       if (x === "-") {
         if (this[page] > 0) {
           this[page] = this[page] - 1;
         }
       }
       if (x === "+") {
         if (arr) {
           if (((this[page] + 1) * this.displayRows) + this.displayRows - arr.length < this.displayRows) {
             this[page] = this[page] + 1;
           }
         }
       }
     },
     handleDisplayRows() {
       this.endorsementsPage = 0
     },
     closeViewer() {
      this.pdf = null;
    }
  },
  computed : {
    needPrevSeparator : function() {
      if(this.endorsementsPage >= 2 && Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 1 >= 3) {
        return true
      }
      return false
    },
    needNextSeparator : function() {
      if(((this.endorsementsPage !== Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 1) && (Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 1 > 2) && (Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 2 !== this.endorsementsPage))) {
        return true
      }
      return false
    },
    button1 : function() {
      if(this.endorsementsPage === Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 1 && Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 1 >= 3) {
        return true
      }
      return false
    },
    button2 : function() {
      if(this.endorsementsPage === 2 && Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 1 === 2) {
        return true
      }
      return false
    },
    button3 : function() {
      if(this.endorsementsPage > 0) {
        return true
      }
      return false
    },
    button4 : function() {
      if(this.endorsementsPage < Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 1) {
        return true
      }
      return false
    },
    button5 : function() {
      if(this.endorsementsPage === 0 && Math.ceil(this.data.endorsementDocuments.length/this.displayRows) - 1 >= 2) {
        return true
      }
      return false
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>

.buttons-container {
  width: 100%;
  text-align: center;
  height: auto;
}

.buttons-container button {
  width: 175px;
}

.primary {
  box-shadow: none;
}

.a-link {
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}

.selected {
  background-color: black;
}
</style>