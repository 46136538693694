<template>
  <BaseAccountLayout>
    <main class="fade-in" v-if="!loading">
      <div id="field-container">
        <h3>Reset Password</h3>
        <div>
          <input type="password" placeholder="Enter new password" className="form-control pw" v-model="password" />
            <small class="password-tooltip">Password should contain
              <span :class="has_char_lenth ? 'has_required' : ''">8-20 characters</span>,
              <span :class="has_lowercase ? 'has_required' : ''">one lowercase letter</span>,
              <span :class="has_uppercase ? 'has_required' : ''">one uppercase letter</span>,
              <span :class="has_number ? 'has_required' : ''">one number</span>, and
              <span :class="has_special ? 'has_required' : ''">one special character</span>.
            </small>
          <input type="password" placeholder="Confirm new password" className="form-control"
            v-model="confirmPassword" />
          <button type="button" className="rightColumn btn-link" @click="resetUserPassword">
            Continue to Login
          </button>
        </div>
      </div>
      <div id="logo-container">
        <img src="./../../assets/company-logo.png" />
      </div>
    </main>
  </BaseAccountLayout>
</template>

<script>
import BaseAccountLayout from "./../../Layouts/BaseAccountLayout.vue";
import { resetPassword, checkResetToken } from "../../Services/authService";
import utilities from "../../Services/utilities";

export default {
  components: { BaseAccountLayout },
  data() {
    return {
      password: "",
      confirmPassword: "",
      loading: true
    };
  },
  watch:{
    password(){
      this.has_char_lenth = (this.password.length >= 8 && this.password.length <= 20);
      this.has_number = /\d/.test(this.password);
      this.has_lowercase = /[a-z]/.test(this.password);
      this.has_uppercase = /[A-Z]/.test(this.password);
      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
    }
  },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          let token = "";
          if (this.$route.params.token) token = this.$route.params.token;

          const res = await checkResetToken(token);

          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!res || !res.success) {
            await utilities.showFailureAlert(this, "Invalid or outdated reset token.")
            this.$router.push({ path: "/" });
          } else {
            this.$swal.close();
          }

          this.loading = false;

        },
      });
    },
    async resetUserPassword() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          let token = "";
          if (this.$route.params.token) token = this.$route.params.token.split("&").join(".")
          const res = await resetPassword({
            password: this.password,
            confirmPassword: this.confirmPassword,
            passwordResetToken: token
          });

          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (res && res.success) {
            await utilities.showSuccessAlert(this, "Password was reset successfully!")
            this.$router.push({ path: "/" });
          } else {
            utilities.showInfoAlert(this, res.message);
          }

        },
      });
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style lang="less" scoped>
main {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#field-container {
  width: 20%;
  margin-left: 25%;
  margin-right: 5%;
  text-align: center;
}

#logo-container {
  margin-top: 50px;
}

input {
  height: 40px;
}

button {
  height: 40px;
  width: 150px;
}

h3 {
  font-size: 35px;
  margin-bottom: 20px;
}

button {
  width: 200px;
}

a,
a:link,
a:visited,
a:active {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 16px;
}

.has_required{
  text-decoration: line-through;
  color:#689868;
}

.password-tooltip {
  display: block;
  visibility: hidden;
  overflow: hidden;
  box-sizing: border-box;
  height: 0;
  margin-bottom: -8px;
  cursor: help;
  animation-name: fold;
  animation-duration: 500ms;
  animation-timing-function: ease-in;
  animation-direction: reverse;
  :focus + & {
    margin-bottom: 0;
    height: auto;
    visibility: visible;
    animation: fold ease-in 500ms;
    animation-direction: normal;
  }
  padding: 4px 16px;
  background: #bbb;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.form-control.pw:focus {
  margin-bottom: 0;
}
</style>