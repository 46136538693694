<template>
  <BaseLayout @selectedTermChanged="initPage()">
    <template #content>
      <article>
        <div class="policy-background">
          <div class="title"><h2 class="fade-in" v-if="!loading">POLICY SELECT</h2></div>
          <div class="member-content">
            <div v-if="!loading" class="fade-in">
              <div
              id="wcPolicyTable"
              class="table-container"
              v-if="data.members.length > 0"
              >
                <div class="section-head">
                  <h3>Members</h3>
                </div>
                <div class="rows">
                  <table class="striped-table">
                    <thead>
                      <tr>
                        <th class="w_07">Select</th>
                        <th class="w_10">Member #</th>
                        <th>Member Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="member in data.members">
                        <td>
                          <input v-on:change="(e) => { showPolicies(member.lobCode,member.organizationNumber)  }" type="radio" name="memberNum" :value="member.lobCode" :checked="data.members.length === 1">
                        </td>
                        <td>{{ member.organizationNumber }}</td>
                        <td>{{ member.organizationName }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!loading && (data.members.length === 1 || data.reloaded)" class="policy-content">
            <div class="fade-in">
              <div
              id="wcPolicyTable"
              ref="wcPolicyTable"
              class="table-container"
              v-if="data.workersComp.length > 0"
            >
              <div class="section-head">
                <h3>Nonprofit Insurance Trust Workers' Compensation Policies</h3>
              </div>
              <div class="rows">
                <table class="striped-table">
                <thead>
                  <tr>
                    <th>Policy</th>
                    <th :key="workersCompPolicy.organizationNumber">Member #</th>
                    <th>Member Name</th>
                    <th>Pool</th>
                    <th>Policy Term</th>
                    <th style="width: 10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select v-on:change="(e) => { changeTerm(JSON.parse(e.target.value), 'workersCompPolicy')  }">
                        <option v-for="policy in data.workersComp" :key="policy.id" :value="JSON.stringify(policy)" :selected="String(selectedPolicy) === String(policy.id)">{{policy.displayPolicyCode}}</option>
                      </select>
                    </td>
                    <td>{{ workersCompPolicy.organizationNumber }}</td>
                    <td>{{ workersCompPolicy.organizationName }}</td>
                    <td>{{ workersCompPolicy.lobName }}</td>
                    <td>{{ workersCompPolicy.policyPeriod }}</td>
                    <td><button class="primary" v-on:click="changeSelectedPolicy(workersCompPolicy.id, workersCompPolicy.organizationName,workersCompPolicy.organizationNumber/* , workersCompPolicy.imported */)">Select</button></td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <div
              id="plPolicyTable"
              class="table-container"
              v-if="data.propertyAndLiability.length > 0"
            >
              <div class="section-head">
                <h3>Nonprofit Insurance Trust Property & Liability Policies</h3>
              </div>
              <div class="rows">
                <table class="striped-table">
                <thead>
                  <tr>
                    <th>Policy</th>
                    <th>Member #</th>
                    <th>Member Name</th>
                    <th>Pool</th>
                    <th>Policy Term</th>
                    <th style="width: 10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select v-on:change="(e) => { changeTerm(JSON.parse(e.target.value), 'propertyAndLiabilityPolicy')  }">
                        <option v-for="policy in data.propertyAndLiability" :key="policy.id" :value="JSON.stringify(policy)" :selected="String(selectedPolicy) === String(policy.id)">{{policy.displayPolicyCode}}</option>
                      </select>
                    </td>
                    <td>{{ propertyAndLiabilityPolicy.organizationNumber }}</td>
                    <td>{{ propertyAndLiabilityPolicy.organizationName }}</td>
                    <td>{{ propertyAndLiabilityPolicy.lobName }}</td>
                    <td>{{ propertyAndLiabilityPolicy.policyPeriod }}</td>
                    <td><button class="primary" v-on:click="changeSelectedPolicy(propertyAndLiabilityPolicy.id, propertyAndLiabilityPolicy.organizationName,propertyAndLiabilityPolicy.organizationNumber, propertyAndLiabilityPolicy.imported)">Select</button></td>
                  </tr>
                </tbody>
              </table>
              </div>
            </div>
            <div
              id="npPolicyTable"
              class="table-container"
              v-if="data.commercialPackage.length > 0"
            >
              <div class="section-head">
                <h3>NP Insurance Group, LLC Commercial Package Policies</h3>
              </div>
              <table class="striped-table">
                <thead>
                  <tr>
                    <th>Policy</th>
                    <th>Member #</th>
                    <th>Member Name</th>
                    <th>Program</th>
                    <th>Policy Term</th>
                    <th style="width: 10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select v-on:change="(e) => { changeTerm(JSON.parse(e.target.value), 'commercialPackagePolicy')  }">
                        <option v-for="policy in data.commercialPackage" :key="policy.id" :value="JSON.stringify(policy)" :selected="String(selectedPolicy) === String(policy.id)">{{policy.displayPolicyCode}}</option>
                      </select>
                    </td>
                    <td>{{ commercialPackagePolicy.organizationNumber }}</td>
                    <td>{{ commercialPackagePolicy.organizationName }}</td>
                    <td>{{ commercialPackagePolicy.lobName }}</td>
                    <td>{{ commercialPackagePolicy.policyPeriod }}</td>
                    <td><button class="primary" v-on:click="changeSelectedPolicy(commercialPackagePolicy.id, commercialPackagePolicy.organizationName,commercialPackagePolicy.organizationNumber, commercialPackagePolicy.imported)">Select</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </BaseLayout>
</template>

<script>
import { getTerms, getTermsByMember } from "./../../Services/customerService";
import { getDecodedAuthToken, saveToken } from "../../Services/authService"
import store from "../../Services/Store/store";
import utilities from "../../Services/utilities"

export default {
  data() {
    const data = {
      commercialPackage: [],
      propertyAndLiability: [],
      workersComp: [],
    };
    return {
      data,
      selectedPolicy: null,
      commercialPackagePolicy: {},
      propertyAndLiabilityPolicy: {},
      workersCompPolicy: {},
      loading: true
    };
  },
  methods: {
    async initPage() {
      
      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const decoded = await getDecodedAuthToken() || null;
          this.selectedPolicy = decoded?.policyID;

          const res = await getTerms(this);
          const data = res.data;
          this.defaultTerm(res.data);
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (data && data.success) {
         this.data = data;
         this.loading = false;
         this.$swal.close();
       } else {
         utilities.showFailureAlert(this, "Could not fetch the user's policies.")
       }
        },
      });
    },
    showPolicies(lobCode, organizationNumber) {
      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const decoded = await getDecodedAuthToken() || null;
          localStorage.setItem("lobCode", lobCode);
          localStorage.setItem("organizationNumber", organizationNumber);
          this.lobCode = lobCode;
          this.organizationNumber = organizationNumber;

          const res = await getTermsByMember(this);
          const data = res.data;
          this.defaultTerm(res.data);

          time = new Date().getTime() - time;
          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (data && data.success) {
            this.data = data;
            this.loading = false;
            this.$swal.close();
            this.$forceUpdate();
          } else {
            utilities.showFailureAlert(this, "Could not fetch the member's policies.")
          }
        },
      });
    },
    changeTerm(val, field) {
      this[field] = val;
    },
    defaultTerm(data) {
      if (data.commercialPackage && data.commercialPackage.length > 0) {
        const selectedPolicy = data.commercialPackage.find((p) => { return String(p.id) === String(this.selectedPolicy) });
        if (selectedPolicy) {
          this.commercialPackagePolicy = selectedPolicy;
        } else {
          this.commercialPackagePolicy = data.commercialPackage[0];
        }        
      };
      if (data.propertyAndLiability && data.propertyAndLiability.length > 0) {
        const selectedPolicy = data.propertyAndLiability.find((p) => { return String(p.id) === String(this.selectedPolicy) });
        if (selectedPolicy) {
          this.propertyAndLiabilityPolicy = selectedPolicy;
        } else {
          this.propertyAndLiabilityPolicy = data.propertyAndLiability[0];
        } 
      }
      if (data.workersComp && data.workersComp.length > 0) {
        const selectedPolicy = data.workersComp.find((p) => { 
          return String(p.id) === String(this.policyID)
         });
        if (selectedPolicy) {
          this.workersCompPolicy = selectedPolicy;
        } else {
          this.workersCompPolicy = data.workersComp[0];
        } 
      }
    },
    async changeSelectedPolicy(policyID, organizationName,organizationNumber) {
      await saveToken({policyID});
      localStorage.setItem("organizationName", organizationName);
      localStorage.setItem("organizationNumber", organizationNumber);
      store.commit("auth/saveOrganizationName", organizationName);
      this.$router.push({
            name: "SummaryPage",
            params: {},
          });
          
      // this.$swal(`New Policy Selected`);
      // if (justLoggedIn) {
      //   this.$router.push({
      //       name: "SummaryPage",
      //       params: {},
      //     });
      // }
    },
    getNumEntries(pageNum, length) {
       if (length < ((pageNum * 10) + 10)) {
         return (pageNum * 10) + (length - (pageNum * 10)) ;
       } else {
         return (pageNum * 10) + 10;
       }
     },
     handlePageNumber(x, arr, page) {
       if (x === "-") {
         if (this[page] > 0) {
           this[page] = this[page] - 1;
         }
       }
       if (x === "+") {
         if (arr) {
           if (((this[page] + 1) * 10) + 10 - arr.length < 10) {
             this[page] = this[page] + 1;
           }
         }
       }
     }
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>

.coverages-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: 1%;
}

.radio {
  height: 15px;
  width: 15px;
}

.page-buttons:hover {
  background-color: black;
}

.selected {
  background-color: black;
}

td {
  word-break: break-word;
}

.w_07 {
  width: 7%;
}

.w_10 {
  width: 10%;
}

</style>