<template>
  <BaseLayout @selectedTermChanged="initPage()">
    <template #content>
      <article>
        <div class="policy-background">
          <div class="title">
          <h2 v-if="!loading" class="fade-in">VIEW CLAIMS</h2>
          </div>
          <div class="content">
            <div v-if="!loading" class="fade-in">
              <div class="data-field sixth">
                <label>Display Rows Per Page</label>
                <select v-model="displayRows" v-on:change="handleDisplayRows()">
                  <option :value="10">10</option>
                  <option :value="25">25</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                  <option :value="300">300</option>
                  <option :value="1000000000">View All</option>
                </select>
              </div>
              <table v-if="lobCode == 'PL'">
                <thead>
                  <tr>
                    <th>Claim Number</th>
                    <th>Adjuster Name</th>
                    <th>Adjuster Phone</th>
                    <th>Incident Reported</th>
                    <th>Last Updated</th>
                    <th>Total Incurred</th>
                    <th>Incident</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="claim in list.slice((page * displayRows), (page * displayRows) + displayRows)" :key="claim.id">
                    <td>{{ claim.claimCode }}</td>
                    <td>{{ claim.adjusterName }}</td>
                    <td>{{ formatPhone(claim.adjusterPhone) }}</td>
                    <td>{{ qsxformatDate(claim.reportingDate) }}</td>
                    <td>{{ qsxformatDate(claim.date_modified) }}</td>
                    <td>{{ formatMoney(claim.totalIncurred) }}</td>
                    <td>{{ claim.lossDescription }}</td>
                    <td v-if="claim.totalNotes > 0">
                      <a  v-on:click="viewNotes(claim.id,claim.claimCode)" class="a-link">View</a>
                    </td>
                    <td v-if="claim.totalNotes == 0">
                      N/A
                    </td>
                  </tr>
                </tbody>
              </table>
              <table v-if="lobCode !== 'PL'">
                <thead>
                  <tr>
                    <th>Claim Number</th>
                    <th class="w-45" v-if="lobCode !== 'NP'">FROI</th>
                    <th>Employee Name</th>
                    <th>Adjuster Name</th>
                    <th>Adjuster Phone</th>
                    <th>Incident Reported</th>
                    <th>Last Updated</th>
                    <th>Total Incurred</th>
                    <th>Incident</th>
                    <th class="w-55">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="claim in list.slice((page * displayRows), (page * displayRows) + displayRows)" :key="claim.id">
                    <td>{{ claim.claimCode }}</td>
                    <td v-if="lobCode !== 'NP'">
                      <td v-if="claim.FROI_file !== ''">
                        <a v-on:click="downloadAttachment(claim.FROI_file)" class="a-link">View</a>                      
                      </td>
                      <td v-else>N/A</td>
                    </td>              
                    <td>{{ claim.employeeName }}</td>
                    <td>{{ claim.adjusterName }}</td>
                    <td>{{ formatPhone(claim.adjusterPhone) }}</td>
                    <td>{{ qsxformatDate(claim.reportingDate) }}</td>
                    <td>{{ qsxformatDate(claim.date_modified) }}</td>
                    <td>{{ formatMoney(claim.totalIncurred) }}</td>
                    <td>{{ claim.lossDescription }}</td>
                    <td v-if="claim.totalNotes > 0">
                      <a  v-on:click="viewNotes(claim.id,claim.claimCode)" class="a-link">View</a>
                    </td>
                    <td v-if="claim.totalNotes == 0">
                      N/A
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="no-data" v-if="!list || list.length === 0">
                <h3>No Data</h3>
              </div>
              <div class="pagination" v-if="list && list.length > 0">
                    <p>Showing {{ ((page + 1) * displayRows) - (displayRows - 1) }} to {{getNumEntries(page, list.length)}} of {{list.length}} entries</p>
                    <div class="pagination-buttons">
                    <button
                      v-on:click="handlePageNumber('-', list, 'page')"
                      class="next-buttons"
                    >
                      Previous
                    </button>
                    <button
                      v-if="needPrevSeparator"
                      class="separator"
                    >
                      ...
                    </button>
                    <button v-on:click="() => { page = page-2}" v-if="button1" class="page-buttons" :class="{selected: page === page - 2}">{{page-1}}</button>
                    <button v-on:click="() => { page = page-2}" v-if="button2" class="page-buttons" :class="{selected: page === page - 2}">{{page-1}}</button>
                    <button v-on:click="() => { page = page-1}" v-if="button3" class="page-buttons" :class="{selected: page === page - 1}">{{page}}</button>
                    <button v-on:click="() => { page = page}" class="page-buttons" :class="{selected: page === page}">{{page+1}}</button>
                    <button v-on:click="() => { page = page+1}" v-if="button4" class="page-buttons" :class="{selected: page === page + 1}">{{page+2}}</button>
                    <button v-on:click="() => { page = page+2}" v-if="button5" class="page-buttons" :class="{selected: page === page + 2}">{{page+3}}</button>
                    <button
                      v-if="needNextSeparator"
                      class="separator"
                    >
                      ...
                    </button>
                    <button
                      v-on:click="handlePageNumber('+', list, 'page')"
                      class="next-buttons"
                    >
                      Next
                    </button>
                  </div>
                </div>
              <div class="flex-row">
                <button class="primary" v-on:click="handleReportDownload('DOWNLOAD_CLAIMS.XLS')" v-if="list && list.length > 0 && list[0].lineCode !== 'WC'">Download Claims</button>
                <button class="primary" v-on:click="handleReportDownload('DOWNLOAD_CLAIMS_WC.XLS')" v-if="list && list.length > 0 && list[0].lineCode === 'WC'">Download Claims</button>
                <button class="primary" v-on:click="handleReportDownload('fmsProgramList.XLS')" v-if="lobCode !== 'PL'">FMS Employer Report</button>
                <button class="primary" v-on:click="handleReportDownload('osha300.XLS')" v-if="lobCode !== 'PL'">OSHA 300 Log</button>
                <button class="primary" v-on:click="handleReportDownload('osha300A.XLS')" v-if="lobCode !== 'PL'">OSHA 300 A Log</button>
              </div>
            </div>
          </div>
          <div class="modal" v-if="showModal">
            <div class="exit-container">
              <span v-on:click="this.showModal = false" class="exitBtn"><font-awesome-icon icon="circle-xmark"/></span>
            </div>
            <div class="scroll-container">
              <div v-for="note in notes" :key="note.id">
                <div class="scroll-head">
                  <h3>{{note.subjectName}}</h3>
                </div>
                <div class="scroll-content">
                  <div class="row">
                  <label>Note:</label>
                  <p>{{note.notes}}</p>
                </div>
                <div class="row">
                  <label>Critical Note:</label>
                  <p>{{note.critical === 1 ? 'Yes' : "No"}}</p>
                </div>
                <div class="row">
                  <label>Personal Note:</label>
                  <p>{{note.isPersonal === 1 ? 'Yes' : "No"}}</p>
                </div>
                <div class="row">
                  <label>Note Created:</label>
                  <p>{{qsxformatDate(note.date_created)}}</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </BaseLayout>
</template>

<script>
import { getClaimsList, downloadReport, getNotes, handleLossRunReport } from "../../Services/customerService";
import utilities from "../../Services/utilities";
import {
  downloadAttachment,
} from "../../Services/claimsService";

export default {
  data() {
    return {
      list: [],
      notes: [],
      lobCode: '',
      showModal: false,
      loading: true,
      page: 0,
      displayRows: 10
    };
  },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getClaimsList(this);
          const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not fetch the claims list.");
        } else {
          this.list = srvData.list;
          this.lobCode = srvData.lobCode;
          this.loading = false;
          this.$swal.close();
        }

        },
      });

    },
    async handleReportDownload(report) {
      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await downloadReport(report);
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!res) {
            await utilities.showFailureAlert(this, "Sorry, we could not generate the report due to no information found.");
          }

          this.$swal.close();
        },
      });
    },
    async viewNotes(claimID,claimNumber) {
      const res = await getNotes(claimID, this);
      const srvData = res.data;
      this.notes = srvData.notes;
      if(this.notes?.length > 0){
        this.showModal = true;
      }else{
        this.showModal = false;
        this.$swal.fire({
          icon: "info",
          title: "Attention!",
          html: `There are no notes for the claim number <Strong>'${claimNumber}'</Strong> `,
          confirmButtonColor: "#2b8cbe",
        });
      }
    },
    getNumEntries(pageNum, length) {
       if (length < ((pageNum * this.displayRows) + this.displayRows)) {
         return (pageNum * this.displayRows) + (length - (pageNum * this.displayRows)) ;
       } else {
         return (pageNum * this.displayRows) + this.displayRows;
       }
     },
     handlePageNumber(x, arr, page) {
       if (x === "-") {
         if (this[page] > 0) {
           this[page] = this[page] - 1;
         }
       }
       if (x === "+") {
         if (arr) {
           if (((this[page] + 1) * this.displayRows) + this.displayRows - arr.length < this.displayRows) {
             this[page] = this[page] + 1;
           }
         }
       }
     },
     handleDisplayRows() {
       this.page = 0
     },
     comingSoon() {
      this.$swal.fire({
        icon: "info",
        title: "Coming Soon!",
        text: "This report is currently under construction",
        confirmButtonColor: "#2b8cbe",
      });
    },
    qsxformatDate: utilities.qsxformatDate,
    formatMoney: utilities.formatMoney,
    formatPhone: utilities.formatPhone,
    downloadAttachment,
  },
  computed : {
    needPrevSeparator : function() {
      if(this.page >= 2 && Math.ceil(this.list.length/this.displayRows) - 1 >= 3) {
        return true
      }
      return false
    },
    needNextSeparator : function() {
      if(((this.page !== Math.ceil(this.list.length/this.displayRows) - 1) && (Math.ceil(this.list.length/this.displayRows) - 1 > 2) && (Math.ceil(this.list.length/this.displayRows) - 2 !== this.page))) {
        return true
      }
      return false
    },
    button1 : function() {
      if(this.page === Math.ceil(this.list.length/this.displayRows) - 1 && Math.ceil(this.list.length/this.displayRows) - 1 >= 3) {
        return true
      }
      return false
    },
    button2 : function() {
      if(this.page === 2 && Math.ceil(this.list.length/this.displayRows) - 1 === 2) {
        return true
      }
      return false
    },
    button3 : function() {
      if(this.page > 0) {
        return true
      }
      return false
    },
    button4 : function() {
      if(this.page < Math.ceil(this.list.length/this.displayRows) - 1) {
        return true
      }
      return false
    },
    button5 : function() {
      if(this.page === 0 && Math.ceil(this.list.length/this.displayRows) - 1 >= 2) {
        return true
      }
      return false
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>

tr {
  border-bottom: 2px solid #e7e7e7;
  height: 40px;
  text-align: left;
}

.exitBtn {
  float: right;
}

.exit-container {
  height: 10%;
}

.scroll-container {
  overflow-y:auto;
  height: 90%;
}

.scroll-content {
  overflow-x:auto;
padding: 0 20px;
border-right: 1px solid black;
border-left: 1px solid black;
border-bottom: 1px solid black;
margin-bottom: 20px;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
}

.scroll-head {
  background-color: #cfe2f3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.no-data {
  display: flex;
  width: 100%;
  height: 40px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  background-color: rgb(240, 240, 240);
}

.selected {
  background-color: black;
}

.naNotes{
  color:red;
}

.w-45 {
  width: 45px;
}
.w-55 {
  width: 55px;
}

</style>
