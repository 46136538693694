<template>
  <BaseLayout @selectedTermChanged="initPage()">
    <template #content>
      <article>
        <div class="policy-background">
          <div class="title"><h2 class="fade-in" v-if="!loading">SUMMARY</h2></div>
          <div class="policy-content">
            <div v-if="!loading" class="fade-in">
              <div>
                <div class="section-head">
                  <h3>Member Information:</h3>
                  <div v-if="!memberInfoCollapsed" v-on:click="memberInfoCollapsed = !memberInfoCollapsed"><font-awesome-icon icon="caret-up"/></div>
                  <div v-if="memberInfoCollapsed" v-on:click="memberInfoCollapsed = !memberInfoCollapsed"><font-awesome-icon icon="caret-down"/></div>
                </div>
                <div class="rows" v-if="!memberInfoCollapsed">
                  <div class="grid-container">
                    <div class="text-field">
                      <label>Member Name:</label>
                      <p>{{ data.organizationName }}</p>
                    </div>
                    <div class="text-field">
                      <label>Address:</label>
                      <p>{{ data.address }}</p>
                    </div>
                    <div class="text-field">
                      <label>Member Number:</label>
                      <p>{{ data.organizationNumber }}</p>
                    </div>
                    <div class="text-field">
                      <label></label>
                      <p>{{ data.addressCSZ }}</p>
                    </div>
                    <div class="text-field">
                      <label>Phone:</label>
                      <p>{{ this.formatPhone(data.phone) || "N/A" }}</p>
                    </div>
                    <div class="text-field">
                      <label>Email:</label>
                      <p>{{ data.email || "N/A" }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="section-head">
                  <h3>Policy Information</h3>
                  <div v-if="!policyInfoCollapsed" v-on:click="policyInfoCollapsed = !policyInfoCollapsed"><font-awesome-icon icon="caret-up"/></div>
                  <div v-if="policyInfoCollapsed" v-on:click="policyInfoCollapsed = !policyInfoCollapsed"><font-awesome-icon icon="caret-down"/></div>
                </div>
                <div class="rows" v-if="!policyInfoCollapsed">
                  <div>
                    <div class="grid-container">
                      <div class="text-field">
                        <label>Policy Number:</label>
                        <p>{{ data.displayPolicyCode }}</p>
                      </div>
                      <div class="text-field">
                        <label>Status:</label>
                        <p>{{ data.status }}</p>
                      </div>
                      <div class="text-field">
                        <label>Effective Date:</label>
                        <p>{{ data.effectiveDate }}</p>
                      </div>
                      <div class="text-field">
                        <label>Expiration Date:</label>
                        <p>{{ data.expirationDate }}</p>
                      </div>
                    </div>
                    <hr v-if="!imported === 1" />
                    <div class="grid-container" v-if="!imported === 1">
                      <div class="text-field">
                        <label>Installments Left:</label>
                        <p>{{ data.installmentsLeft }}</p>
                      </div>
                      <div class="text-field">
                        <label></label>
                        <p></p>
                      </div>
                      <div class="text-field">
                        <label>Policy Balance:</label>
                        <p class="green">{{ data.policyBalance }}</p>
                      </div>
                      <div class="text-field">
                        <label>Last Invoice Sent:</label>
                        <p>{{ data.lastBillPrintDate }}</p>
                      </div>
                      <div class="text-field">
                        <label>Current Due:</label>
                        <p class="green">{{ data.currentDue }}</p>
                      </div>
                      <div class="text-field">
                        <label>Last Invoice Due:</label>
                        <p>{{ data.lastBillTotal }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="section-head">
                  <h3>Coverages</h3>
                  <div v-if="!coveragesCollapsed" v-on:click="coveragesCollapsed = !coveragesCollapsed"><font-awesome-icon icon="caret-up"/></div>
                  <div v-if="coveragesCollapsed" v-on:click="coveragesCollapsed = !coveragesCollapsed"><font-awesome-icon icon="caret-down"/></div>
                </div>
                <div class="rows" v-if="!coveragesCollapsed">
                  <div class="coverages-container">
                    <div
                      class="text-field"
                      v-for="coverage in data.coverages"
                      :key="coverage"
                    >
                      <p>{{ coverage }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </BaseLayout>
</template>

<script>
import { getSummary } from "./../../Services/customerService";
import utilities from "../../Services/utilities";

export default {
  data() {
    const data = {
      imported: 1,
    };
    return {
      data,
      loading: true,
      memberInfoCollapsed: false,
      policyInfoCollapsed: false,
      coveragesCollapsed: false,
    };
  },
  methods: {
    async initPage() {
      try {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getSummary(this);
          const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not fetch policy information.");
        } else {
          this.data = srvData;
          this.loading = false;
          this.$swal.close();
        }

        },
      });        
      } catch (error) {

      }
    },
    formatPhone: utilities.formatPhone,
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>

.section-head {
display: flex;
justify-content: space-between;

}

.section-head div {
  height: 50px;
  font-size: 40px;
  color: #2b8cbe;
}



</style>