import moment from "moment";

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

export const qsxformatDate = (entryDate, mask) => {
  if (!entryDate) {
    return null;
  }

  const msk = (mask === undefined) ? 'MM/DD/YYYY hh:mm A' : mask;
  const d = qsxGetValidDate(entryDate);
  //const d = new Date(entryDate).toUTCString();

  if (d === null) {
    return null;
  }
  const m = moment(d);

  return m.format(msk);
}

export const qsxGetValidDate = (entryDate, mask) => {
  let date = (entryDate) || new Date().toISOString();

  if (typeof entryDate === 'object') {
    date = new Date(Date.parse(date)).toString();
  } else {
    if (date.indexOf('GMT') > 0) {
      date = new Date(Date.parse(date)).toString();
    }
    if (date.indexOf(':') > 0) {
      date = new Date(date).toString();
    } else {
      date = new Date(date + ' 12:00:00:0.00Z').toString();
    }
  }
  const resDate = new Date(date);

  return resDate; //new Date(Date.parse(resDate));
}

export const formatMoney = (amount) => {
  return formatter.format(amount);
}

export const formatPhone = (number) => {
  if (!number) return "";
  return "(" + number.slice(0, 3) + ") " + number.slice(3, 6) + "-" + number.slice(-4);
  
}

export const getNumEntries = (pageNum, length) => {
  let num = "";
  if (length < ((pageNum * 10) + 10)) {
    num = (pageNum * 10) + (length - (pageNum * 10));
  } else {
    num = (pageNum * 10) + 10;
  }
  return `Showing ${((certsPage + 1) * 10) - 9} to ${num} of ${length} entries`
}

export const validateField = (field) => {
  let isValid = true;
  const isRequired = field.required;

  if (field.type === "email") {
    const validEmail = validateEmail(field.value);
    if (!validEmail) isValid = false;
  }

  if (field.type === "phone") {
    const validPhone = !isNaN(field.value) && field.value && field.value.length === 10 && field.value.match(/^[2-9][0-9]*$/) && !["0", "1"].includes(field.value[0]) && !["0", "1"].includes(field.value[3]);
    if (!validPhone) isValid = false;
  }

  if (field.type === "boolean") {
    const validBoolean =  field.value === true || field.value === false;
    if (!validBoolean) isValid = false;
  }

  if (field.type === "text") {
    const validText = field.value && field.value !== "";
    if (!validText) isValid = false;
  }

  if (field.type === "date") {
    const validText = field.value && field.value !== "";
    if (!validText) isValid = false;
  }

  if (field.type === "datetime-local") {
    const validText = field.value && field.value !== "";
    if (!validText) isValid = false;
  }

  if (field.type === "time") {
    const validText = field.value && field.value !== "";
    if (!validText) isValid = false;
  }

  if (field.type === "number") {
    const validNumber = field.value && field.value.match(/[0-9]*$/)
    if (!validNumber) isValid = false;
  }

  if (field.type === "group") {
    const validGroup = field.value && field.value === true;
    if (!validGroup) isValid = false;
  }

  if (field.maxlength && field.value) {
    const validLength = field.value.length <= field.maxlength;
    if (!validLength) isValid = false;
  }

  if (field.maxvalue && field.value) {
    const validValue = Number(field.value) <= field.maxvalue;
    if (!validValue) isValid = false;
  }

  if (isRequired) {
    return isValid;
  } else {
    if (!isValid) {
      field.value = null;
    }
    return true;
  }

}

export const validateFields = (form) => {
  let isValid = true;

  Object.keys(form).forEach((key) => {
    const field = form[key];
    const valid = validateField(field);
    if (!valid) isValid = valid;

    field.error = !valid;
  });

  return isValid;
}

export const validateEmail = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return false
  }
  if (!String(email).toLowerCase().match(regex)) {
    return false
  }
  return true
}

export const handleValueChange = (field) => {
  const valid = validateField(field);
  field.error = !valid;
}

export const phoneMaskFormat = (phone, field) => {
  let number = phone.replace(/\D+/g, "").slice(0, 10);

  if (number[0] === "1" || number[0] === "0") {
    number = number.substring(1);
  }

  if (number[3] === "0" || number[3] === "1") {
    number = number.substring(0, 3) + number.substring(4);
  }

  field.value = number;

  field.display =  number.replace(/\D/g, '')
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{3})(\d)/, '$1) $2')
    .replace(/(\d{3})(\d{1,5})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
}

export const ssnMaskFormat = (ssn, field) => {
  // Strip all non numbers
    var displayVal = ssn.replace(/[^0-9|\\*]/g, '');
    displayVal = displayVal.substr(0, 9);
    
    // Inject dashes
    if (displayVal.length >= 4) {
      displayVal = displayVal.slice(0, 3) + '-' + displayVal.slice(3);
    }
    
    if (displayVal.length >= 7) {
      displayVal = displayVal.slice(0, 6) + '-' + displayVal.slice(6);
    }

    field.value = displayVal.replace(/\D+/g, "").slice(0, 10);
        
    // Replace all numbers with astericks
    // displayVal = displayVal.replace(/[0-9]/g, '*');
    
    return displayVal;
}

export const moneyMaskFormat = (num, field, decimals = 2) => {
  

  const arr = num.split(".");
  let val = "";

  if (arr && arr.length > 1) {
    val = arr[0].replace(/[^0-9|\\]/g, '') + "." + arr[1].replace(/[^0-9|\\]/g, '').slice(0, decimals);
  } else {
    val = num.replace(/[^0-9|\\]/g, '');
  }

  if (field.maxvalue && Number(val) > field.maxvalue) {
    return "$" + field.value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  } else {
    field.value = val
  val = "$" + val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

  return val;
  }
}

export const numeric = (num, field, decimals = 0) => {
  const arr = num.split(".");
  let val = "";

  const exceedsMaxValue = Number(num.replace(/[^0-9|\\]/g, '')) > field.maxvalue;

  if (exceedsMaxValue) {
    return field.value
  }


  if (decimals > 0 && arr && arr.length > 1) {
    val = arr[0].replace(/[^0-9|\\]/g, '') + "." + arr[1].replace(/[^0-9|\\]/g, '').slice(0, decimals);
  } else {
    val = num.replace(/[^0-9|\\]/g, '');
  }

  field.value = val;
  field.display = val;

  return val;
}

export const formatTime = (val, field) => {
  const today = new Date();

  const withTime = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}T${val}`

  field.display = val;
  field.value = withTime;
  return  val;
}

export async function wait (timeToDelay) {
  return new Promise((resolve) => setTimeout(resolve, timeToDelay));
}

export async function showFailureAlert (app, message, title = "Error!", timer = 1500) {
  return new Promise((resolve) => {
    app.$swal.fire({
      icon: "error",
      title: title,
      text: message,
      showConfirmButton: false,
      timer: timer,
    }).then(() => {
      resolve();
    });
  });
}

export async function showSuccessAlert (app, message, title = "Success!", timer = 1500, afterClose = null) {
  return new Promise((resolve) => {
    app.$swal.fire({
      icon: "success",
      title:title,
      text: message,
      showConfirmButton: false,
      timer: timer,
      didClose: afterClose
    }).then(() => {
      resolve();
    });
  });
}

export async function showInfoAlert (app, message, title = "Attention!") {
  return new Promise((resolve) => {
    app.$swal.fire({
      icon: "info",
      title:title,
      text: message,
      showConfirmButton: true,
    }).then(() => {
      resolve();
    });
  });
}

export function handleFormData (data, form) {
  if (!data || !form) return
  Object.keys(form).forEach((field) => {    
    
    if (data[field] || (data[field] === true || data[field] === false)) {
      if (field === "injCauseDeath") {
      }

      if (form[field].type === "date") {
        const dte = new Date(data[field]);
        form[field].value = qsxformatDate(dte, "YYYY-MM-DD");
      } 
      else if (form[field].type === "datetime-local") {
        const dte = new Date(data[field]);
        form[field].value = `${qsxformatDate(dte, "YYYY-MM-DDTHH:MM")}`
      } 
      else if (form[field].type === "time") {
        const dte = new Date(data[field]);
        const hours = dte.getHours();
        const minutes = dte.getMinutes();
        formatTime(`${hours < 10 ? "0" : ""}${dte.getHours()}:${minutes < 10 ? "0" : ""}${dte.getMinutes()}`, form[field])
      } 
      else if (form[field].type === "phone") {
        phoneMaskFormat(data[field], form[field])
      } 
      else if (form[field].type === "number") {
        numeric(data[field], form[field]);
      } 
      else {
        
        form[field].value = data[field]
      }
    }    
  });
}

export default {
  qsxformatDate,
  qsxGetValidDate,
  formatMoney,
  formatPhone,
  getNumEntries,
  validateField,
  validateFields,
  validateEmail,
  handleValueChange,
  phoneMaskFormat,
  ssnMaskFormat,
  moneyMaskFormat,
  wait,
  showFailureAlert,
  showSuccessAlert,
  showInfoAlert,
  numeric,
  formatTime,
  handleFormData,
}