<template>
    <iframe
      type="application/pdf"
      :src="pdf"
    />
</template>

<script>

export default {
  props: ["pdf"],
  created() {
    
  }
};
</script>

<style scoped>
iframe {
  height: 100%;
  width: 100%;
  overflow: auto;
}
</style>