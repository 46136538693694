<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 class="fade-in" v-if="!loading">File A Claim</h2>
        </div>
        <div class="content">
          <div class="fade-in" v-if="!loading">
            <div class="section-head">
            <h3>Employee Information Info</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field sixth">
                <label :class="form.firstName.error ? 'error-text' : ''"
                  ><span class="red">*</span>Employee First Name</label
                >
                <input
                  type="text"
                  placeholder="Enter First Name"
                  v-model="form.firstName.value"
                  :class="form.firstName.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.firstName)"
                  :maxlength="form.firstName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label>Employee Middle Name</label>
                <input
                  type="text"
                  placeholder="Enter Middle Name"
                  v-model="form.middleName.value"
                  :maxlength="form.middleName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.lastName.error ? 'error-text' : ''"
                  ><span class="red">*</span>Employee Last Name</label
                >
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  v-model="form.lastName.value"
                  :class="form.lastName.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.lastName)"
                  :maxlength="form.lastName.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.dateOfBirth.error ? 'error-text' : ''"
                  ><span class="red">*</span>Date of Birth</label
                >
                <input
                  type="date"
                  placeholder="Enter DOB"
                  v-model="form.dateOfBirth.value"
                  :class="form.dateOfBirth.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.dateOfBirth)"
                />
              </div>
              <div class="radio-group sixth">
                <label
                  class="bold whole"
                  :class="form.gender.error ? 'error-text' : ''"
                  ><span class="red">*</span>Gender:</label
                >
                <div class="row half">
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.gender.value"
                      name="gender"
                      :value="'M'"
                      :class="form.gender.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.gender)"
                    />
                    <label>Male</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.gender.value"
                      name="gender"
                      :value="'F'"
                      :class="form.gender.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.gender)"
                    />
                    <label>Female</label>
                  </div>
                </div>
              </div>
              <div class="data-field sixth">
                <label>Marital Status</label>
                <select
                  v-model="form.maritalStatus.value"
                  :class="form.maritalStatus.error ? 'error-element' : ''"
                >
                  <option value=null disabled selected hidden>
                    Select Status
                  </option>
                  <option
                    v-for="status in data.maritalStatuses"
                    :key="status.id"
                    :value="status.dccode"
                  >
                    {{ status.display }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="data-field fourth">
                <label :class="form.address1.error ? 'error-text' : ''"
                  ><span class="red">*</span>Address Line 1</label
                >
                <input
                  type="text"
                  placeholder="Enter Address Line 1"
                  v-model="form.address1.value"
                  :class="form.address1.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.address1)"
                  :maxlength="form.address1.maxlength"
                />
              </div>
              <div class="data-field fourth">
                <label>Address Line 2</label>
                <input
                  type="text"
                  placeholder="Apartment, suite, building, unit, floor, etc."
                  v-model="form.address2.value"
                  :maxlength="form.address2.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.city.error ? 'error-text' : ''"
                  ><span class="red">*</span>City</label
                >
                <input
                  type="text"
                  placeholder="Enter City"
                  v-model="form.city.value"
                  :class="form.city.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.city)"
                  :maxlength="form.city.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.state.error ? 'error-text' : ''"
                  ><span class="red">*</span>State</label
                >
                <select
                  v-model="form.state.value"
                  :class="form.state.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.state)"
                >
                  <option value=null disabled selected hidden>
                    Select State
                  </option>
                  <option
                    v-for="state in data.states"
                    :key="state.ID"
                    :value="state.stateCode"
                  >
                    {{ state.stateName }}
                  </option>
                </select>
              </div>
              <div class="data-field sixth">
                <label :class="form.zip.error ? 'error-text' : ''"
                  ><span class="red">*</span>ZIP</label
                >
                <input
                  type="number"
                  placeholder="Enter Zip Code"
                  :class="form.zip.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.zip)"
                  :value="form.zip.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.zip)
                    }"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field sixth">
                <label :class="form.phoneNumber.error ? 'error-text' : ''"
                  ><span class="red">*</span>Home Phone</label
                >
                <input
                  type="text"
                  placeholder="Enter Phone #"
                  :value="form.phoneNumber.display"
                  maxlength="14"
                  v-on:input="
                    (e) => {
                      phoneMaskFormat(
                          e.target.value,
                          form.phoneNumber
                        );
                      handleValueChange(form.phoneNumber)
                    }
                  "
                  :class="form.phoneNumber.error ? 'error-element' : ''"
                />
              </div>
              <div class="data-field sixth">
                <label>Email Address</label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  v-model="form.email.value"
                  :maxlength="form.email.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label>Educational Level</label>
                <select v-model="form.educationLevel.value">
                  <option value=null disabled selected hidden>
                    Select Level
                  </option>
                  <option
                    v-for="level in data.educationLevels"
                    :key="level.ID"
                    :value="level.dccode"
                  >
                    {{ level.display }}
                  </option>
                </select>
              </div>
              <div class="data-field sixth">
                <label>Tax Filling Status</label>
                <select v-model="form.taxFillStatus.value">
                  <option value=null disabled selected hidden>
                    Select Status
                  </option>
                  <option
                    v-for="type in data.taxTypes"
                    :key="type.ID"
                    :value="type.dccode"
                  >
                    {{ type.display }}
                  </option>
                </select>
              </div>
              <div class="data-field sixth">
                <label :class="form.employmentStatus.error ? 'error-text' : ''"
                  ><span class="red">*</span>Employment Status</label
                >
                <select
                  v-model="form.employmentStatus.value"
                  :class="form.employmentStatus.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.employmentStatus)"
                >
                  <option value=null disabled selected hidden>
                    Select Employment Status
                  </option>
                  <option
                    v-for="status in data.employmentStatuses"
                    :key="status.ID"
                    :value="status.dccode"
                  >
                    {{ status.display }}
                  </option>
                </select>
              </div>
              <div class="data-field sixth">
                <label :class="form.dateHired.error ? 'error-text' : ''"
                  ><span class="red">*</span>Date of Hire</label
                >
                <input
                  type="date"
                  placeholder=""
                  v-model="form.dateHired.value"
                  :class="form.dateHired.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.dateHired)"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field third">
                <label :class="form.occupation.error ? 'error-text' : ''"
                  ><span class="red">*</span>Occupation Description</label
                >
                <input
                  type="text"
                  placeholder="Enter Description"
                  v-model="form.occupation.value"
                  :class="form.occupation.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.occupation)"
                  :maxlength="form.occupation.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label :class="form.regularDept.error ? 'error-text' : ''"
                  ><span class="red">*</span>Regular Department</label
                >
                <input
                  type="text"
                  placeholder="Enter Department"
                  v-model="form.regularDept.value"
                  :class="form.regularDept.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.regularDept)"
                  :maxlength="form.regularDept.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label>Manual Classification Code</label>
                <input
                  type="text"
                  placeholder="Enter Code"
                  v-model="form.classCode.value"
                  :maxlength="form.classCode.maxlength"
                />
              </div>
              <div class="data-field sixth">
                <label>Employee ID Type</label>
                <select v-model="form.emplIDType.value">
                  <option value=null disabled selected hidden>
                    Select Id Type
                  </option>
                  <option
                    v-for="type in data.employeeIdTypes"
                    :key="type.ID"
                    :value="type.dccode"
                  >
                    {{ type.display }}
                  </option>
                </select>
              </div>
              <div class="data-field sixth">
                <label>Employee ID Number</label>
                <input
                  type="text"
                  placeholder="Enter ID"
                  v-model="form.employeeID.value"
                  :maxlength="form.employeeID.maxlength"
                />
              </div>
            </div>
            <div class="row">
              <div class="radio-group fourth">
                <label class="bold whole"
                  >Employee Authorized Medical Record Release:</label
                >
                <div class="radio-buttons half">
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.authReleaseMedRec.value"
                      name="authReleaseMedRec"
                      :value="true"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.authReleaseMedRec.value"
                      name="authReleaseMedRec"
                      :value="false"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
              <div class="radio-group fourth">
                <label class="bold whole">Employee Authorized SSN Release:</label>
                <div class="radio-buttons half">
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.authReleaseSSNInfo.value"
                      name="authReleaseSSNInfo"
                      :value="true"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.authReleaseSSNInfo.value"
                      name="authReleaseSSNInfo"
                      :value="false"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
              <div class="data-field sixth">
                <label v-if="form.authReleaseSSNInfo.value === true">SSN</label>
                <input
                  v-if="form.authReleaseSSNInfo.value === true"
                  type="text"
                  placeholder="Enter SSN"
                  :value="form.ssn.display"
                  v-on:input="
                    (e) => {
                      form.ssn.display = ssnMaskFormat(e.target.value, form.ssn);
                      e.target.value = ssnMaskFormat(e.target.value, form.ssn);
                    }
                  "
                />
              </div>
              <div class="data-field sixth" />
              <div class="data-field sixth" />
            </div>
          </div>
          <div class="section-head">
            <h3>Wage Information</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field fifth">
                <label :class="form.avgWageAmount.error ? 'error-text' : ''"
                  ><span class="red">*</span>Average Wage Amount</label
                >
                <input
                  type="text"
                  placeholder="Enter Amount"
                  :class="form.avgWageAmount.error ? 'error-element' : ''"
                  :value="form.avgWageAmount.display"
                  v-on:input="
                    (e) => {
                      e.target.value = moneyMaskFormat(e.target.value, form.avgWageAmount);
                      form.avgWageAmount.display = moneyMaskFormat(e.target.value, form.avgWageAmount);
                      handleValueChange(form.avgWageAmount);
                    }
                  "
                />
              </div>
              <div class="data-field fifth">
                <label :class="form.wageInterval.error ? 'error-text' : ''"
                  ><span class="red">*</span>Wage Payment Interval</label
                >
                <select
                  v-model="form.wageInterval.value"
                  :class="form.wageInterval.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.wageInterval)"
                >
                  <option value=null disabled selected hidden>
                    Select Wage Interval
                  </option>
                  <option
                    v-for="interval in data.wageIntervals"
                    :key="interval.id"
                    :value="interval.dccode"
                  >
                    {{ interval.display }}
                  </option>
                </select>
              </div>
              <div class="data-field fifth">
                <label :class="form.daysPerWeek.error ? 'error-text' : ''"
                  ><span class="red">*</span>Days Worked Weekly</label
                >
                <input
                  type="number"
                  placeholder="Enter Days"
                  :class="form.daysPerWeek.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.daysPerWeek)"
                  :value="form.daysPerWeek.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.daysPerWeek)
                    }"
                />
              </div>
              <div class="data-field fifth">
                <label>Discontinued Fringe Benefits</label>
                <input
                  type="text"
                  placeholder="Enter Benefits"
                  v-model="form.discFringeBenefits.value"
                  :maxlength="form.discFringeBenefits.maxlength"
                />
              </div>
              <div class="data-field fifth">
                <label>Number of Dependents</label>
                <input
                  type="number"
                  placeholder="Enter Number"
                  :value="form.dependentsNum.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.dependentsNum)
                    }"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field fourth">
                <label>Number of Exemptions</label>
                <input
                  type="number"
                  placeholder="Enter Number"
                  :value="form.numOfExemptions.display"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.numOfExemptions)
                    }"
                />
              </div>
              <div class="data-field fourth">
                <label>Employee Exemption Type</label>
                <select v-model="form.exemptionType.value">
                  <option value=null disabled selected hidden>Select Type</option>
                  <option
                    v-for="type in data.exemptionTypes"
                    :key="type.extType"
                    :value="type.extType"
                  >
                    {{ type.display }}
                  </option>
                </select>
              </div>
              <div class="radio-group fourth">
                <label
                  class="bold whole"
                  :class="form.salaryLieuComp.error ? 'error-text' : ''"
                  ><span class="red">*</span>Salary Continued In Lieu of
                  Compensation</label
                >
                <div class="radio-buttons half">
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.salaryLieuComp.value"
                      name="salaryLieuComp"
                      :value="true"
                      :class="form.salaryLieuComp.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.salaryLieuComp)"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.salaryLieuComp.value"
                      name="salaryLieuComp"
                      :value="false"
                      :class="form.salaryLieuComp.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.salaryLieuComp)"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
              <div class="radio-group fourth">
                <label
                  class="bold whole"
                  :class="form.fullWagesPaid.error ? 'error-text' : ''"
                  ><span class="red">*</span>Full Wages Paid for Date of
                  Injury</label
                >
                <div class="radio-buttons half">
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.fullWagesPaid.value"
                      name="fullWagesPaid"
                      :value="true"
                      :class="form.fullWagesPaid.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.fullWagesPaid)"
                    />
                    <label>Yes</label>
                  </div>
                  <div class="radio-label">
                    <input
                      type="radio"
                      v-model="form.fullWagesPaid.value"
                      name="fullWagesPaid"
                      :value="false"
                      :class="form.fullWagesPaid.error ? 'error-element' : ''"
                      v-on:change="handleValueChange(form.fullWagesPaid)"
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Attachments @save-information="saveInformation"/>
          <div class="ldBtns">
              <button class="primary back" v-on:click="handlePrevious()">Back</button>
          <button class="primary" v-on:click="continueClaim()">Submit Claim</button>
          </div>
          </div>
        </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import {
  saveFROIClaimant,
  getFROIClaimantData,
  getDecodedToken,
  saveToken
} from "../../Services/claimsService";
import {  saveDefaultClaimState } from "../../Services/apiService";
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";
import Attachments from "../Partials/Attachments.vue"
import utilities from "../../Services/utilities"

export default {
  data() {
    return {
      form: {
        firstName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        middleName: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        lastName: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        dateOfBirth: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        gender: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        maritalStatus: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        address1: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        address2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "255"
        },
        city: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        state: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        zip: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
        phoneNumber: {
          required: true,
          type: "text",
          display: "",
          value: null,
          error: false,
        },
        email: {
          required: false,
          type: "email",
          value: null,
          error: false,
          maxlength: "75"
        },
        educationLevel: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        taxFillStatus: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        employmentStatus: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        dateHired: {
          required: true,
          type: "date",
          value: null,
          error: false,
        },
        occupation: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "120"
        },
        regularDept: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "150"
        },
        classCode: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "25"
        },
        emplIDType: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        employeeID: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "25"
        },
        authReleaseMedRec: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        authReleaseSSNInfo: {
          required: false,
          type: "boolean",
          value: null,
          error: false,
        },
        ssn: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        avgWageAmount: {
          required: true,
          type: "text",
          value: null,
          display: "",
          error: false,
          maxvalue: 9999999.99
        },
        wageInterval: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        daysPerWeek: {
          required: true,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        discFringeBenefits: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "120"
        },
        dependentsNum: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        numOfExemptions: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 999999999
        },
        exemptionType: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        salaryLieuComp: {
          required: true,
          type: "boolean",
          value: null,
          error: false,
        },
        fullWagesPaid: {
          required: true,
          type: "boolean",
          value: null,
          error: false,
        },
      },
      data: {
        states: [],
        employmentStatuses: [],
        maritalStatuses: [],
        taxTypes: [],
        educationLevels: [],
        employeeIdTypes: [],
        wageIntervals: [],
        exemptionTypes: [],
      },
      loading: true
    };
  },
  components: { BaseClaimLayout, Attachments },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await getFROIClaimantData();
          const data = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!data || !data.success) {
            utilities.showFailureAlert(this, "Could not fetch the claimant info.")
      } else {
        this.data = data;

        const decoded = await getDecodedToken();
        const screenInfo = decoded.IAWCClaimantInfoScreenInfo;

        if (screenInfo && screenInfo !== "null" && screenInfo !== "undefined") {
        this.form = screenInfo;
      }

      await saveToken({IAWCClaimantInfoScreenInfo: null}, this);

      this.loading = false;
      this.$swal.close();
      }
        },
      });
            
    },
    handleValueChange: utilities.handleValueChange,
    phoneMaskFormat: utilities.phoneMaskFormat,
    moneyMaskFormat: utilities.moneyMaskFormat,
    ssnMaskFormat: utilities.ssnMaskFormat,
    numeric: utilities.numeric,
    async handlePrevious() {
      await this.saveInformation();
      this.$router.push({
            name: "IowaWorkersCompLossDetails",
            params: {},
          });
    },
    async saveInformation() {
      await saveToken({IAWCClaimantInfoScreenInfo: this.form}, this);
    },
    async continueClaim() {
      const valid = utilities.validateFields(this.form);
      if (valid) {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();          

          const res = await saveFROIClaimant(this.form, this);
        const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not save the claimant info.");
        } else {
          await utilities.showSuccessAlert(this, "Claimant information saved successsfully.");

        await saveToken({IAWCClaimantInfoScreenInfo: null}, this);

        await saveDefaultClaimState();

          this.$router.push({
            name: "FinishClaim",
            params: {},
          });
        }

        },
      });                
      } else {
        utilities.showFailureAlert(this, "Missing Required Information!");
      }
    },
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>
.radio-group {
  flex-direction: column;
}

.radio-group label{
  /* width: 100%; */
}

.radio-buttons {
  width: 100%;
}

.checkbox-label {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.chkBox {
  margin-right: 10px;
  width: 20px;
}

.check-box {
  min-height: 60px;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.checkbox-group {
  margin-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 60px;
}

.check-box input {
  margin-right: 10px;
}

.checkboxes {
  display: flex;
}

.checkboxes div {
  margin-right: 10px;
}

.ldBtns {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}

.ldBtns button {
  width: 175px;
}

.back {
  background: gray;
}
</style>