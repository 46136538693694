<template>
  <BaseLayout @selectedTermChanged="initPage()">
    <template #content>
      <article>
        <div v-if="pdf" class="pdf-viewer">
          <PDFViewer :pdf="pdf" v-if="pdf !== null"/>
          <div class="whole">
            <button v-on:click="closeViewer()" class="primary iframe-btn">Close Viewer</button>
            </div>    
        </div>
        <div class="policy-background">
          <div class="title">
            <h2 class="fade-in" v-if="!loading">DOCUMENTS</h2>
          </div>
          <div class="policy-content">
            <div v-if="!loading" class="container fade-in">
              <div class="section-head">
                  <h3>Certificates of Insurance/Evidence of Property</h3>
                </div>
                <div class="rows">
                  <div class="data-field sixth">
                <label>Display Rows Per Page</label>
                <select v-model="displayRows" v-on:change="handleDisplayRows()">
                  <option :value="10">10</option>
                  <option :value="25">25</option>
                  <option :value="50">50</option>
                  <option :value="100">100</option>
                  <option :value="300">300</option>
                  <option :value="1000000000">View All</option>
                </select>
              </div>
                  <table id="certificateDocumentsTable" class="striped-table">
                  <thead>
                    <tr>
                      <th class="checkbox-column center">
                        <input
                          type="checkbox"
                          v-on:change="checkAll()"
                          id="checkAllID"
                        />
                      </th>
                      <th>Document</th>
                      <th>Certificate Type</th>
                      <th class="page-count-column center">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="doc in data.certificateDocuments.slice((certsPage * displayRows), (certsPage * displayRows) + displayRows)" :key="doc.id">
                      <td class="checkbox-column">
                        <input
                          type="checkbox"
                          v-on:change="checkCert(doc.id)"
                          class="allToPrint"
                          :doc-id="doc.id"
                          :originPolicyID="doc.originPolicyID"
                          :checked="checkedCerts.includes(doc.id)"
                        />
                      </td>
                      <td>
                        <a
                          class="a-link"
                          v-on:click="
                            printCerts([{ partyID: doc.id, originPolicyID: doc.originPolicyID}])"
                          >{{ doc.partyName }}</a
                        >
                      </td>
                      <td>{{ doc.addPartyTypeDisplay }}</td>
                      <td class="page-count-column">
                        <button
                          class="primary"
                          v-on:click="deleteCert(doc.id, data.certificateDocuments)"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="striped-no-data" v-if="!data.certificateDocuments || data.certificateDocuments.length === 0">
                  <h3>No Data</h3>
                </div>
                <div class="pagination">
                  <div class="flex-row">
                    <router-link to="/createcertificate">
                      <button class="primary" style="width: 225px">Create New Certificate</button>
                    </router-link>
                    <button
                      class="primary"
                      v-if="data.certificateDocuments.length > 0"
                      v-on:click="printSelectedCerts()"
                    >
                      Print Selected Documents
                    </button>
                    <p v-if="data.certificateDocuments && data.certificateDocuments.length > 0">Showing {{ ((certsPage + 1) * displayRows) - (displayRows - 1) }} to {{getNumEntries(certsPage, data.certificateDocuments.length)}} of {{data.certificateDocuments.length}} entries</p>
                  </div>
                  <div class="pagination-buttons" v-if="data.certificateDocuments && data.certificateDocuments.length > 0">
                    <button
                      v-on:click="handlePageNumber('-', data.certificateDocuments, 'certsPage')"
                      class="next-buttons"
                    >
                      Previous
                    </button>
                    <button
                      v-if="needPrevSeparator"
                      class="separator"
                    >
                      ...
                    </button>
                    <button v-on:click="() => { certsPage = certsPage-2}" v-if="button1" class="page-buttons" :class="{selected: certsPage === certsPage - 2}">{{certsPage-1}}</button>
                    <button v-on:click="() => { certsPage = certsPage-2}" v-if="button2" class="page-buttons" :class="{selected: certsPage === certsPage - 2}">{{certsPage-1}}</button>
                    <button v-on:click="() => { certsPage = certsPage-1}" v-if="button3" class="page-buttons" :class="{selected: certsPage === certsPage - 1}">{{certsPage}}</button>
                    <button v-on:click="() => { certsPage = certsPage}" class="page-buttons" :class="{selected: certsPage === certsPage}">{{certsPage+1}}</button>
                    <button v-on:click="() => { certsPage = certsPage+1}" v-if="button4" class="page-buttons" :class="{selected: certsPage === certsPage + 1}">{{certsPage+2}}</button>
                    <button v-on:click="() => { certsPage = certsPage+2}" v-if="button5" class="page-buttons" :class="{selected: certsPage === certsPage + 2}">{{certsPage+3}}</button>
                    <button
                      v-if="needNextSeparator"
                      class="separator"
                    >
                      ...
                    </button>
                    <button
                      v-on:click="handlePageNumber('+', data.certificateDocuments, 'certsPage')"
                      class="next-buttons"
                    >
                      Next
                    </button>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </article>
    </template>
  </BaseLayout>
</template>

<script>
import {
  certificates,
  printCertificates,
  deleteCertificate,
} from "./../../Services/customerService";
import PDFViewer from "../Partials/PDFViewer.vue";
import utilities from "../../Services/utilities"

export default {
  data() {
    const data = {
      certificateDocuments: [],
    };
    return {
      data,
      checkedCerts: [],
      certsPage: 0,
      pdf: null,
      loading: true,
      displayRows: 1000000000
    };
  },
  components: { PDFViewer },
  methods: {
    async initPage() {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await certificates(this);
      const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
            utilities.showFailureAlert(this, "Could not fetch the certificates.");
        } else {
          this.data = srvData;
          this.loading = false;
          this.$swal.close();
        }

        },
      });
    },
    checkAll() {
      const chkAll = document.getElementById("checkAllID");
      // const table = document.getElementById("endorsementDocumentsTable");
      const allIds = this.data.certificateDocuments.map((doc) => {
        return doc.id
      });
      if (chkAll.checked) {
        // const rows = table.querySelectorAll(".allToPrint");
        this.checkedCerts = allIds;
      } else {
        this.checkedCerts = [];
      }
    },
    async printSelectedCerts() {
      const selectedParties = [];
      this.checkedCerts.filter((curIns) => {
        return this.data.certificateDocuments.some((priorIns) => {
          if (curIns === priorIns.ID) {
            selectedParties.push({
              partyID: priorIns.ID,
              originPolicyID: priorIns.originPolicyID
            });
          }
        });
      });
      if (selectedParties.length > 0) {
        this.printCerts(selectedParties);
      } else { 
        utilities.showInfoAlert(this, "Please select at least one document.")
      }
    },
    async printCerts(arr) {

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

          const res = await printCertificates(arr, this);
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (res.success) {

        if (res.path) {
          this.pdf = res.path;
        }

        if (res.link) {
          res.link.click();
        }

        this.$swal.close();

      } else {
        utilities.showFailureAlert(this, "Could not generate the document.")
      }
        },
      });
    },
    async deleteCert(id, data) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "Please confirm if you want to delete the certificate!",
          // icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#2b8cbe",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: 'No, cancel!'
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const res = await deleteCertificate(id, this);
            if (res?.data?.success) {
              const index = data.findIndex(cert => cert.ID === id) // find the cert index 
              if (~index) // if the cert exists in array
              data.splice(index, 1) //delete the cert
              this.$swal.fire({
                icon: "success",
                title: "Success!",
                text: "The certificate has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }
        });
    },
    checkCert(id) {
      if (this.checkedCerts.includes(id)) {
        const index = this.checkedCerts.indexOf(id);
        if (index > -1) {
          this.checkedCerts.splice(index, 1);
        }
      } else {
        this.checkedCerts.push(id);
      }
    },
    checkAllCerts(e, tableID) {
      const chkRows = this.checkedCerts.slice();
      const table = document.getElementById(tableID);
      const rows = table.querySelectorAll(".allToPrint");
      if (e.target.checked) {
        rows.forEach((row) => {
          chkRows.push(row.getAttribute("doc-id"));
        });
        this.checkedCerts = chkRows;
      } else {
        rows.forEach((row) => {
          const index = chkRows.indexOf(row.getAttribute("doc-id"));
          chkRows.splice(index, 1);
        });
        this.checkedCerts = chkRows;
      }
    },
    getNumEntries(pageNum, length) {
       if (length < ((pageNum * this.displayRows) + this.displayRows)) {
         return (pageNum * this.displayRows) + (length - (pageNum * this.displayRows)) ;
       } else {
         return (pageNum * this.displayRows) + this.displayRows;
       }
     },
     handlePageNumber(x, arr, page) {
       if (x === "-") {
         if (this[page] > 0) {
           this[page] = this[page] - 1;
         }
       }
       if (x === "+") {
         if (arr) {
           if (((this[page] + 1) * this.displayRows) + this.displayRows - arr.length < this.displayRows) {
             this[page] = this[page] + 1;
           }
         }
       }
     },
     handleDisplayRows() {
       this.certsPage = 0
     },
     closeViewer() {
      this.pdf = null;
    }
  },
  computed : {
    needPrevSeparator : function() {
      if(this.certsPage >= 2 && Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 1 >= 3) {
        return true
      }
      return false
    },
    needNextSeparator : function() {
      if(((this.certsPage !== Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 1) && (Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 1 > 2) && (Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 2 !== this.certsPage))) {
        return true
      }
      return false
    },
    button1 : function() {
      if(this.certsPage === Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 1 && Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 1 >= 3) {
        return true
      }
      return false
    },
    button2 : function() {
      if(this.certsPage === 2 && Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 1 === 2) {
        return true
      }
      return false
    },
    button3 : function() {
      if(this.certsPage > 0) {
        return true
      }
      return false
    },
    button4 : function() {
      if(this.certsPage < Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 1) {
        return true
      }
      return false
    },
    button5 : function() {
      if(this.certsPage === 0 && Math.ceil(this.data.certificateDocuments.length/this.displayRows) - 1 >= 2) {
        return true
      }
      return false
    }
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>

.buttons-container {
  width: 100%;
  text-align: center;
  height: auto;
}

.buttons-container button {
  width: 175px;
}

.primary {
  box-shadow: none;
}

.a-link {
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
}

.selected {
  background-color: black;
}
table tbody td {
  word-break: break-word;
  vertical-align: top;
}
</style>