<template>
  <BaseClaimLayout>
    <article>
      <div class="background">
        <div class="title">
          <h2 v-if="!loading" class="fade-in">File A Claim</h2>
        </div>
        <div class="content">
          <div v-if="!loading" class="fade-in">
            <div class="section-head">
            <h3>Loss Details</h3>
          </div>
          <div class="rows">
            <div class="row">
              <div class="data-field fourth">
                  <label :class="form.dateOfLoss.error ? 'error-text' : ''"
                    ><span class="red">*</span>Date of Loss</label
                  >
                  <input
                    type="datetime-local"
                    placeholder=""
                    v-model="form.dateOfLoss.value"
                    :class="form.dateOfLoss.error ? 'error-element' : ''"
                    v-on:change="handleValueChange(form.dateOfLoss)"
                  />
                </div>
                <div class="data-field fourth">
                  <label :class="form.claimType.error ? 'error-text' : ''"
                    ><span class="red">*</span>Claim Type</label
                  >
                  <select
                    v-model="form.claimType.value"
                    v-on:change="handleValueChange(form.claimType)"
                    :class="form.claimType.error ? 'error-element' : ''"
                  >
                    <option value=null disabled selected hidden>
                      Select Claim Type
                    </option>
                    <option
                      v-for="type in data.claimTypes"
                      :key="type.ID"
                      :value="type.ID"
                    >
                      {{ type.typeDescription }}
                    </option>
                  </select>
                </div>
                <div class="data-field half"/>
            </div>
          <div class="row">
            <div class="data-field whole">
            <label :class="form.lossDetails.error ? 'error-text' : ''"><span class="red">*</span>Detailed Loss Description</label>
            <textarea
              type="text"
              placeholder="Enter Loss Details"
              v-model="form.lossDetails.value"
              :class="form.lossDetails.error ? 'error-element' : ''"
              v-on:change="handleValueChange(form.lossDetails)"
              :maxlength="form.lossDetails.maxlength"
            />
          </div>
          </div>
          </div>
          <div id="locationSelect" v-if="lineCode === 'PROP' || lineCode === 'GL'">
            <div class="section-head">
              <h3>Location</h3>
            </div>
            <div class="rows">
              <div class="row">
              <div class="data-field half">
                <label :class="form.address1.error ? 'error-text' : ''"><span class="red" v-if="form.address1.required">*</span>Address Line 1</label>
                <input
                  type="text"
                  placeholder="Enter Address Line 1"
                  v-model="form.address1.value"
                  :class="form.address1.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.address1)"
                  :maxlength="form.address1.maxlength"
                />
              </div>
              <div class="data-field half">
                <label>Address Line 2</label>
                <input
                  type="text"
                  placeholder="Apartment, suite, building, unit, floor, etc."
                  v-model="form.address2.value"
                  :maxlength="form.address2.maxlength"
                />
              </div>
            </div>
            <div class="row">
              <div class="data-field fourth">
                <label :class="form.city.error ? 'error-text' : ''"><span class="red" v-if="form.city.required">*</span>City</label>
                <input
                  type="text"
                  placeholder="Enter City"
                  v-model="form.city.value"
                  :class="form.city.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.city)"
                  :maxlength="form.city.maxlength"
                />
              </div>
              <div class="data-field fourth">
                <label :class="form.state.error ? 'error-text' : ''"><span class="red" v-if="form.state.required">*</span>State</label>
                <select
                  v-model="form.state.value"
                  :class="form.state.error ? 'error-element' : ''"
                  v-on:change="handleValueChange(form.state)"
                >
                  <option value=null disabled selected hidden>Select State</option>
                  <option
                  v-for="state in data.states"
                  :key="state.ID"
                  :value="state.stateCode"                  
                >
                  {{ state.stateName }}
                </option>
                </select>
              </div>
              <div class="data-field fourth">
                <label :class="form.zip.error ? 'error-text' : ''"><span class="red" v-if="form.zip.required">*</span>ZIP</label>
                <input
                  type="number"
                  placeholder=""
                  :class="form.zip.error ? 'error-element' : ''"
                  :value="form.zip.display"
                  v-on:change="handleValueChange(form.zip)"
                  v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.zip)
                    }"
                />
              </div>
              <div class="data-field fourth">
                <label>ZIP+4</label>
                      <input type="number" :value="form.zip4.display" placeholder="" v-on:input="(e) => {
                        const cleanValue = e.target.value;
                        if (cleanValue.length > 4) {
                          e.target.value = numeric(cleanValue.slice(0, 4), form.zip4);
                        } else {
                          e.target.value = numeric(cleanValue, form.zip4);
                        }
                    }"/>
              </div>
            </div>
            </div>
          </div>
          <div id="vehicleSelect" v-if="lineCode === 'AUTO'">
            <div class="section-head">
              <h3>Vehicle</h3>
            </div>
            <div class="rows">
              <div class="row">
                <div class="data-field half">
                <label>Year</label>
                <input type="number" :value="form.Year.display" placeholder="Vehicle Year" v-on:input="(e) => {
                      e.target.value = numeric(e.target.value, form.Year)
                    }"/>
              </div>
              <div class="data-field half">
                <label>Make</label>
                <input
                  type="text"
                  placeholder="Vehicle Manufacturer"
                  v-model="form.Make.value"
                  :maxlength="form.Make.maxlength"
                />
              </div>              
            </div>
            <div class="row">
              <div class="data-field half">
                <label>Model</label>
                <input
                  type="text"
                  placeholder="Vehicle Model"
                  v-model="form.Model.value"
                  :maxlength="form.Model.maxlength"
                />
              </div>
              <div class="data-field half">
                <label>Vehicle VIN</label>
                <input type="text" placeholder="VIN Number" v-model="form.VIN.value" :maxlength="form.VIN.maxlength"/>
              </div>
            </div>
            </div>
          </div>
          <Attachments @save-information="saveInformation"/>
          <div class="ldBtns">
              <button class="primary back" v-on:click="handlePrevious()">Back</button>
          <button class="primary" v-on:click="continueClaim()">
            Submit Claim
          </button>
          </div>
          </div>
        </div>
      </div>
    </article>
  </BaseClaimLayout>
</template>

<script>
import { getLossDetailsData, saveLossDetails, getDecodedToken, saveToken } from "../../Services/claimsService";
import { saveDefaultState } from "../../Services/apiService"
import utilities from "../../Services/utilities"
import BaseClaimLayout from "../../Layouts/BaseClaimLayout.vue";
import Attachments from "../Partials/Attachments.vue"

export default {
  data() {
    return {
      form: {
        dateOfLoss: {
          required: true,
          type: "datetime-local",
          value: null,
          error: false,
        },
        claimType: {
          required: true,
          type: "text",
          value: null,
          error: false,
        },
        lossDetails: {
          required: true,
          type: "text",
          value: null,
          error: false,
          maxlength: "5000"
        },
        address1: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        address2: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        city: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "75"
        },
        state: {
          required: false,
          type: "text",
          value: null,
          error: false,
        },
        zip: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 99999
        },
        zip4: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 9999
        },
        Year: {
          required: false,
          type: "number",
          value: null,
          error: false,
          maxvalue: 9999,
        },
        Make: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "20"
        },
        Model: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "200"
        },
        VIN: {
          required: false,
          type: "text",
          value: null,
          error: false,
          maxlength: "17"
        },
      },
      open: false,
      vehicles: [],
      locations: [],
      data: {
        states: [],
        claimTypes: [],
      },
      lineCode: null,
      loading: true
    };
  },
  components: { BaseClaimLayout, Attachments },
  methods: {
    async initPage() {

      const decoded = await getDecodedToken();
      this.lineCode = decoded.fnolLineCode;

      this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();
          
          time = new Date().getTime() - time;

          const res = await getLossDetailsData(this);
          const srvData = res.data;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
        utilities.showFailureAlert(this, "Could not fetch the loss information.")
      } else {
        this.loading = false;
        this.data = srvData;
      if (this.lineCode === "PROP") {
        this.form.address1.required = true;
        this.form.city.required = true;
        this.form.state.required = true;
        this.form.zip.required = true;
      }
      const screenInfo = decoded.lossDetailsScreenInfo;
      if (screenInfo && screenInfo !== "null" && screenInfo !== "undefined") {
        this.form = screenInfo;
      }

      this.$swal.close();
      
      }
        },
      });            
    },
    handleUploader(open = false) { this.open = open },
    async continueClaim() {
      const valid = utilities.validateFields(this.form);
      if (valid) {

        this.$swal({
        title: "Loading...",
        // timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        didOpen: async () => {
          let time = new Date().getTime();
          this.$swal.showLoading();

        const res = await saveLossDetails(this.form, this);
        const srvData = res.data;
          
          time = new Date().getTime() - time;

          if (time < 1000) {
            await utilities.wait(1000 - time);
          }

          if (!srvData || !srvData.success) {
          utilities.showFailureAlert(this, "Could not save the loss details.");
        } else {
          // await createWorkflowItem(fnolID);
          await utilities.showSuccessAlert(this, "Loss details saved successsfully.");

        await saveDefaultState();

          this.$router.push({
            name: "FinishClaim",
            params: {},
          });
        }

        },
      });        
      } else {
        utilities.showFailureAlert(this, "Check the errors and try again.", "Missing Required Information!");
      }
    },
    async saveInformation() {
      await saveToken({lossDetailsScreenInfo: this.form}, this);
    },
    async handlePrevious() {
      await this.saveInformation();
      this.$router.push({
            name: "MakeClaim",
            params: {},
          });
    },
    handleValueChange: utilities.handleValueChange,
    qsxformatDate: utilities.qsxformatDate,
    numeric: utilities.numeric,
  },
  created() {
    this.initPage();
  },
};
</script>

<style scoped>

.ldBtns {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;
}

.ldBtns button {
  width: 175px;
}

.back {
  background: gray;
}

</style>